import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import allActions from '../../../../redux';
import Schematic from './schematic';
import PieChart from '../../pieChart';
import PlacementsTreeView from './treeView'

const Root = styled(Paper)(({ theme }) => ({
    borderRadius: 2,
    backgroundColor: theme.palette.background.lighter,
    margin: 10,
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.dark}`,
    textAlign: 'right',
    padding: '8px 15px',
    color: '#fff',
    borderRadius: '2px 2px 0px 0px'
}));

const Title = styled(Typography)({
    display: 'inline',
    float: 'left',
    fontSize: 13,
    fontWeight: 700
});

const BackLink = styled(Typography)({
    display: 'inline',
    float: 'right',
    fontSize: 12,
    color: '#fff',
    textDecoration: 'underline',
    '&:hover': {
        opacity: 0.9
    }
});

const InnerMain = styled(Grid)({
    padding: '8px 15px',
});

const DetailText = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.text.main.normal,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left'
}));

const Subtitle = styled('span')({
    fontWeight: 700,
});

const VisualiseButton = styled(Button)(({ theme, tiparsVisible }) => ({
    width: '100%',
    height: '100%',
    lineHeight: 1.5,
    marginTop: -1,
    marginBottom: -1,
    fontSize: 13,
    fontWeight: 700,
    borderColor: tiparsVisible ? '#273f40' : theme.palette.background.main,
    backgroundColor: tiparsVisible ? '#273f40' : theme.palette.background.main,
    border: '1px solid',
    color: tiparsVisible ? '#b50505' : '#fff',
    '&:hover': {
        color: tiparsVisible ? '#b50505' : '#d6d6d6',
        borderColor: '#273f40',
        backgroundColor: '#273f40',
    }
}));

const VisualiseLabel = styled(Typography)({
    fontSize: 11,
    fontWeight: 900,
    textTransform: 'none',
    lineHeight: '15px',
    textAlign: 'start',
});

const Subsection = styled(Typography)({
    fontSize: 13,
    fontWeight: 700,
    textAlign: 'left',
    marginTop: 10,
});

const PlacementAmbiguityOptions = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.main.normal,
    textAlign: 'left'
}));

const TableCheckBox = styled(Checkbox)(({ theme }) => ({
    height: 0,
    width: 0,
    margin: 0,
    marginLeft: -3,
    marginRight: 8,
    color: theme.palette.background.main,
    '&.Mui-checked': {
        color: theme.palette.background.main,
    },
    '&.Mui-disabled': {
        color: '#484848',
        opacity: 0.3
    }
}));


function TiparsOutput() {
    const dispatch = useDispatch();

    const staticMeta = useSelector(state => state.staticReducer.meta);
    const { tiparsVisible, tiparsPlacementSelected, retrieveTiparsOutputData, tiparsAmbiguousQueriesVisible, tiparsUnambiguousQueriesVisible } = useSelector(state => state.tiparsReducer);
    console.log(retrieveTiparsOutputData)

    const boldText = (string) => {
        return <span style={{ fontWeight: 700 }}>{string}</span>;
    };

    const _onBack = () => {
        dispatch(allActions.tiparsActions.toggleTiparsVisibility(false));
        dispatch(allActions.tiparsActions.setTiparsOutputSubmissionID(null));
        dispatch(allActions.tiparsActions.toggleAllTiparsPlacementsSelection(true));
        dispatch(allActions.tiparsActions.setTiparsPlacementSelection(null));
    };

    const _onVisualise = () => {
        dispatch(allActions.tiparsActions.toggleTiparsVisibility());
    };

    const _onToggleAmbiguousQueries = () => {
        // check if selected placements is ambiguous
        if (tiparsAmbiguousQueriesVisible && tiparsPlacementSelected !== null && tiparsPlacementSelected.query.placements.length > 1) {
            dispatch(allActions.tiparsActions.setTiparsPlacementSelection(null));
        }
        dispatch(allActions.tiparsActions.toggleTiparsAmbiguousQueries());
    };

    const _onToggleUnambiguousQueries = () => {
        // check if selected placements is unambiguous
        if (tiparsUnambiguousQueriesVisible && tiparsPlacementSelected !== null && tiparsPlacementSelected.query.placements.length === 1) {
            dispatch(allActions.tiparsActions.setTiparsPlacementSelection(null));
        }
        dispatch(allActions.tiparsActions.toggleTiparsUnambiguousQueries());
    };

    var selectedPlacementsNum = 0;
    const subtreeDistDict = retrieveTiparsOutputData.results.reduce((accSubtrees, query) => {
        const subrees = query.placements.reduce((acc, placement) => {
            if (placement.selected) {
                selectedPlacementsNum += 1;
                var updatedAccSubtrees = { ...acc };
                if (placement.subtree in accSubtrees) {
                    updatedAccSubtrees[placement.subtree] = {
                        ...updatedAccSubtrees[placement.subtree],
                        value: updatedAccSubtrees[placement.subtree].value + 1,
                    };
                    return updatedAccSubtrees
                } else {
                    updatedAccSubtrees[placement.subtree] = {
                        id: placement.subtree,
                        label: placement.subtreeName,
                        value: 1,
                        color: staticMeta.maps.subtree[placement.subtree].color
                    };
                    return { ...accSubtrees, ...updatedAccSubtrees }
                }
            }
            return acc
        }, accSubtrees);
        return subrees
    }, []);
    const subtreeDist = Object.values(subtreeDistDict).reduce((acc, subtree) => ([ ...acc, { ...subtree, percent: subtree.value*100/selectedPlacementsNum } ]), [])
    
    return (
        <Root elevation={1}>
            <Grid container spacing={0}>
                <TitleContainer item xs={12}>
                    <Title>
                        submission ID: {retrieveTiparsOutputData.id}
                    </Title>
                    <Link
                        component='button'
                        style={{ textDecoration: 'underline' }}
                        onClick={_onBack}
                    >
                        <BackLink>
                            back
                        </BackLink>
                    </Link>
                </TitleContainer>
                <InnerMain item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={4} style={{ paddingRight: 15, paddingTop: 5, paddingBottom: 3 }}>
                            <VisualiseButton
                                variant='outlined'
                                color='primary'
                                disableRipple={true}
                                onClick={_onVisualise}
                                tiparsVisible={tiparsVisible}
                            >
                                <VisibilityRoundedIcon fontSize='small' style={{ fontSize: 20, marginRight: 10 }}/>
                                <VisualiseLabel>
                                      VISUALISE<br />OUTPUT
                                </VisualiseLabel>
                            </VisualiseButton>
                        </Grid>
                        <Grid item xs={8}>
                            <DetailText>
                                <Subtitle>submitted at: </Subtitle>{retrieveTiparsOutputData.submitTime}
                            </DetailText>
                            <DetailText>
                                <Subtitle>info: </Subtitle>{retrieveTiparsOutputData.info}
                            </DetailText>
                            <DetailText>
                                <Subtitle>completed at: </Subtitle>{retrieveTiparsOutputData.endTime}
                            </DetailText>
                            <DetailText>
                                <Subtitle>expire at: </Subtitle>{retrieveTiparsOutputData.expireTime}
                            </DetailText>
                            <DetailText>
                                <Subtitle>runtime: </Subtitle>{retrieveTiparsOutputData.runDuration.timedelta.toFixed(2)} {retrieveTiparsOutputData.runDuration.unit}
                            </DetailText>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Schematic />
                    </Grid>
                    <Grid item xs={12}>
                        <Subsection>
                            Queries (n={retrieveTiparsOutputData.queryNum})
                        </Subsection>
                        <PlacementAmbiguityOptions>
                            <TableCheckBox
                                color='primary'
                                size='small'
                                disableRipple={true}
                                disabled={(retrieveTiparsOutputData.queryNum - retrieveTiparsOutputData.unambiguousQueryNum) === 0}
                                onMouseDown={_onToggleAmbiguousQueries}
                                checked={tiparsAmbiguousQueriesVisible}
                            />
                            show queries with {boldText('ambiguous')} placements (n={retrieveTiparsOutputData.queryNum - retrieveTiparsOutputData.unambiguousQueryNum})
                        </PlacementAmbiguityOptions>
                        <PlacementAmbiguityOptions>
                            <TableCheckBox
                                color='primary'
                                size='small'
                                disableRipple={true}
                                disabled={retrieveTiparsOutputData.unambiguousQueryNum === 0}
                                onMouseDown={_onToggleUnambiguousQueries}
                                checked={tiparsUnambiguousQueriesVisible}
                            />
                            show queries with {boldText('unambiguous')} placement (n={retrieveTiparsOutputData.unambiguousQueryNum})
                        </PlacementAmbiguityOptions>
                        <PlacementsTreeView
                            queryPassNum={retrieveTiparsOutputData.queryPassNum}
                            queries={retrieveTiparsOutputData.results}
                        />
                        <PieChart
                            sortedObjs={subtreeDist}
                            margin='6px 0px'
                            title='subtree'
                        />
                    </Grid>
                </InnerMain>
            </Grid>
        </Root>
    )
};

export default TiparsOutput;