import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Define styled components
const SnackBarContent = styled('div')(({ theme }) => ({
    fontSize: 13,
    fontWeight: 700,
    background: '#fff',
    minWidth: 0,
    color: theme.palette.text.main.normal,
    padding: '0px 5px',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.title,
}));

function CopiedToClipboard(props) {
    const { CustomIconButton, message, textToCopy } = props;

    const [copied, setCopied] = React.useState(false);

    const _onClick = () => {
        setCopied(true);
    };

    const _snackbarOnClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCopied(false);
    };

    return (
        <Fragment>
            <CopyToClipboard text={textToCopy} onCopy={_onClick}>
                <CustomIconButton>
                    {props.children}
                </CustomIconButton>
            </CopyToClipboard>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={copied}
                autoHideDuration={3000}
                onClose={_snackbarOnClose}
                message={<SnackBarContent>{message}</SnackBarContent>}
                action={
                    <CloseButton size="small" onClick={_snackbarOnClose}>
                        <CloseIcon fontSize="small" />
                    </CloseButton>
                }
                sx={{
                    '& .MuiSnackbarContent-root': {
                        minWidth: 0,
                        backgroundColor: '#fff'
                    },
                }}
            />
        </Fragment>
    );
}

export default CopiedToClipboard;
