import React, { Fragment, useEffect } from "react";
import { Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/system';

import TimeCurve from './timeCurve';
import FieldLegend from './fieldLegend';

const Root = styled(Paper)(({ theme }) => ({
    borderRadius: 2,
    backgroundColor: theme.palette.background.lighter,
    margin: 10
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.dark}`,
    textAlign: 'right',
    padding: '8px 15px',
    color: '#fff',
    borderRadius: '2px 2px 0px 0px'
}));

const Title = styled(Typography)({
    display: 'inline',
    float: 'left',
    fontSize: 14,
    fontWeight: 700
});

const ItemInfo = styled(Typography)({
    display: 'contents',
    float: 'right',
    fontSize: 12,
    fontWeight: 500,
});

const NoData = styled(Typography)(({ theme }) => ({
    padding: 15,
    width: '100%',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.main.normal
}));

function FreqChart(props) {
    const selectedRef = React.useRef(null);
    const { title, data, colorMap } = props;

    const defaultBarInfo = `${data.earliest} to ${data.latest}, n=${data.num}`;

    const [selectedBar, setSelectedBar] = React.useState(null);
    const [barInfo, setBarInfo] = React.useState(defaultBarInfo);
    const handleBarOnSelect = (bar) => {
        selectedRef.current = bar && bar.data.label;
        setSelectedBar(bar);
    };
    const handleSetBarInfo = (info) => {
        setBarInfo(info.length ? info : defaultBarInfo);
    };

    const [renderTrigger, setRenderTrigger] = React.useState('');
    useEffect(() => {
        setRenderTrigger(Math.random().toString());
        setBarInfo(defaultBarInfo);
        setSelectedBar(null);
        selectedRef.current = null;
    }, [data, defaultBarInfo]);

    return (
        <Root elevation={1}>
            <Grid container spacing={0}>
                <TitleContainer item xs={12}>
                    <Title>
                        {title}
                    </Title>
                    <ItemInfo>
                        {barInfo}
                    </ItemInfo>
                </TitleContainer>
                {
                    data.num ?
                    <Fragment key={renderTrigger}>
                        <TimeCurve
                            name={title.replace(' ', '-')}
                            data={data.counts}
                            colorMap={colorMap}
                            handleBarOnSelect={handleBarOnSelect}
                            handleSetBarInfo={handleSetBarInfo}
                            selectedRef={selectedRef}
                        />
                        <FieldLegend
                            bar={selectedBar}
                            colorMap={colorMap}
                        />
                    </Fragment>
                    :
                    <NoData>
                        No data to display.
                    </NoData>
                }
            </Grid>
        </Root>
    );
};

export default FreqChart;