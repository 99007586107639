import React, { Component } from "react";
import "../../../App.css";
import { Link } from "react-router-dom";

class LandingContent extends Component {
  render() {
    return (
      <div
        style={{
          height: "380px",
          display: "block",
          float: "center",
          marginTop: "0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "120px",
            marginRight: "auto",
            marginLeft: "auto",
            marginBottom: "0",
          }}
        >
          <h1>
            E<span id="redI">i</span>GENO
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "48px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <div class="btn-cont">
            <Link to={`/tryNow`} className="btn">
              TRY NOW
              <span class="line-1"></span>
              <span class="line-2"></span>
              <span class="line-3"></span>
              <span class="line-4"></span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingContent;
