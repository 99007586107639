const initialState = {
    visualMode: 'global', // global or rect
    tabIndex: 0,
    exploreMapActive: false,
    colorLegendActive: false,
    selectedColorLegend: 'subtree',
    branchScaleActive: true,
    selectedSubtreeId: null, // only for funnelling subtree selection from outside arcVis

    // Graph-related states
    centreGraphTrigger: false,
    selectedNodeId: null,
    spotlightActive: false,
    darkModeActive: true,
    goToNodeId: null,
    renderGoToNodeId: null,
};

const visualReducer = (state=initialState, action) => {
    switch(action.type) {
        case 'TOGGLE_VISUAL_MODE':
            return {
                ...state,
                visualMode: action.payload,
            }
        case 'SET_TAB_INDEX':
            return {
                ...state,
                tabIndex: action.payload
            }
        case 'TOGGLE_EXPLORE_MAP':
            return {
                ...state,
                exploreMapActive: action.payload ?? !state.exploreMapActive,
                colorLegendActive: false,
                branchScaleActive: false
            }
        case 'TOGGLE_COLOR_LEGEND':
            return {
                ...state,
                exploreMapActive: false,
                colorLegendActive: action.payload ?? !state.colorLegendActive,
                branchScaleActive: false,
            }
        case 'SET_COLOR_LEGEND':
            return {
                ...state,
                selectedColorLegend: action.payload
            }
        case 'TOGGLE_BRANCH_SCALE':
            return {
                ...state,
                exploreMapActive: false,
                colorLegendActive: false,
                branchScaleActive: action.payload ?? !state.branchScaleActive
            }
        case 'SET_HOVERED_SUBTREE':
            return {
                ...state,
                hoveredSubtree: action.payload
            }
        case 'SET_SELECTED_SUBTREE_ID':
            return {
                ...state,
                selectedSubtreeId: action.payload
            }
        
        // Graph-related actions
        case 'TOGGLE_CENTRE_GRAPH':
            return {
                ...state,
                centreGraphTrigger: !state.centreGraphTrigger
            }
        case 'SET_SELECTED_NODE_ID':
            return {
                ...state,
                selectedNodeId: action.payload,
                spotlightActive: action.payload === null && state.spotlightActive ? false : state.spotlightActive
            }
        case 'TOGGLE_SPOTLIGHT':
            return {
                ...state,
                spotlightActive: action.payload ?? !state.spotlightActive
            }
        case 'TOGGLE_DARK_MODE':
            return {
                ...state,
                darkModeActive: action.payload ?? !state.darkModeActive
            }
        case 'SET_GO_TO_NODE_ID':
            return {
                ...state,
                goToNodeId: action.payload
            }
        case 'SET_RENDER_GO_TO_NODE_ID':
            return {
                ...state,
                renderGoToNodeId: action.payload
            }
        case 'RESET_DEFAULT':
            // return {
            //     ...state,
            //     ...initialState
            // }
            return initialState;
        default: return state;
    }
};

export default visualReducer;