import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import * as d3 from "d3";

import { params } from './params';
import allActions from '../../../../../redux';

const Root = styled('div')(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: 175,
    marginTop: 15
}));

const TitleBox = styled(Typography)({
    position: "absolute",
    width: "100%",
    textAlign: "right",
    paddingTop: 10,
    lineHeight: "10px",
});

const Title = styled('span')(({ theme }) => ({
    fontWeight: 700,
    marginRight: 10,
    fontSize: 13,
    color: theme.palette.text.main.normal
}));

const InfoText = styled('span')({
    display: 'block',
    marginRight: 10,
    fontSize: 10,
    fontStyle: "italic",
});

const DeselectLink = styled(Link)(({ theme, tiparsPlacementSelected }) => ({
    color: tiparsPlacementSelected === null ? '#a8a8a8' : theme.palette.text.title,
    opacity: tiparsPlacementSelected === null ? 0.7 : 1,
    '&:hover': {
        textDecoration: tiparsPlacementSelected === null ? 'none' : 'underline',
        cursor: tiparsPlacementSelected === null ? 'text' : 'pointer'
    },
    fontSize: 11,
    fontWeight: 700,
    marginTop: 3,
    marginRight: 10,
}));

function Schematic() {
    const dispatch = useDispatch();

    const { tabIndex } = useSelector(state => state.visualReducer);
    const { tiparsPlacementSelected } = useSelector(state => state.tiparsReducer);

    const svgRef = React.useRef(null);

    const _deselectPlacement = () => {
        dispatch(allActions.tiparsActions.setTiparsPlacementSelection(null));
    };

    useEffect(() => {
        // redraw (only if tipars-tab is active, and when tipars-tab is being activated)
        if (tabIndex === 2) {
            d3.select(".schematic").selectAll("*").remove();
            drawChart(tiparsPlacementSelected);    
        }
    }, [tiparsPlacementSelected, tabIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        d3.select(".schematic").selectAll("*").remove();
        drawChart(tiparsPlacementSelected);    
        return () => {
            d3.select(".schematic").selectAll("*").remove();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const drawChart = (placement) => {

        // set the dimensions and margins of the graph
        const { margin, rootHeight, rootWidth } = params.root;
        const width = rootWidth - margin.left - margin.right,
            height = rootHeight - margin.top - margin.bottom;

        var svg = d3.select(svgRef.current);

        const apDistDisplay = placement === null ? null : parseFloat(placement.apDist.toPrecision(3)).toExponential(),
            pqDistDisplay = placement === null ? null : parseFloat(placement.pqDist.toPrecision(3)).toExponential(),
            pbDistDisplay = placement === null ? null : parseFloat((placement.abDist - placement.apDist).toPrecision(3)).toExponential();
      
        var graph = svg
            .attr("class", "schematic")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // draw link between A and P
        graph
            .append("line")
            .attr("id", "linkAP")
            .attr("stroke", placement !== null && placement.apDist > 0 ? params.APBLineColor : params.defaultColor)
            .attr("stroke-width", params.lineWidth)
            .attr("opacity", params.opacity)
            .style("stroke-dasharray", placement !== null && placement.apDist > 0 ? ("0, 0") : ("3, 3"))
            .attr("x1", params.leftPadding + params.nodeRadius + params.nodeBorderWidth)
            .attr("x2", params.leftPadding + params.nodeRadius + params.nodeBorderWidth)
            .attr("y1", 2*(params.nodeRadius + params.nodeBorderWidth))
            .attr("y2", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/2 - (params.PnodeRadius + params.nodeBorderWidth));

        graph
            .append("line")
            .attr("id", "linkPB")
            .attr("stroke", placement !== null && (placement.abDist - placement.apDist) > 0 ? params.APBLineColor : params.defaultColor)
            .attr("stroke-width", params.lineWidth)
            .attr("opacity", params.opacity)
            .style("stroke-dasharray", placement !== null && (placement.abDist - placement.apDist) > 0 ? ("0, 0") : ("3, 3"))
            .attr("x1", params.leftPadding + params.nodeRadius + params.nodeBorderWidth)
            .attr("x2", params.leftPadding + params.nodeRadius + params.nodeBorderWidth)
            .attr("y1", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/2 + (params.PnodeRadius + params.nodeBorderWidth))
            .attr("y2", 3*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength - (params.nodeRadius + params.nodeBorderWidth) - params.arrowHeight)
            .attr("marker-end", "url(#arrow)");

        graph
            .append("line")
            .attr("id", "linkPQ")
            .attr("stroke", placement !== null && placement.pqDist > 0 ? params.PQLineColor : params.defaultColor)
            .attr("stroke-width", params.lineWidth)
            .attr("opacity", params.opacity)
            .style("stroke-dasharray", placement !== null && placement.pqDist > 0 ? ("0, 0") : ("3, 3"))
            .attr("x1", params.leftPadding + 2*(params.nodeRadius + params.nodeBorderWidth) - params.nodeBorderWidth)
            .attr("x2", params.leftPadding + params.PQLineLength)
            .attr("y1", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/2)
            .attr("y2", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/2);

        graph
            .append("circle")
            .attr("id", "circleP")
            .attr("fill", "#fff")
            .attr("stroke", placement !== null ? params.PQLineColor : params.defaultColor)
            .attr("stroke-width", params.nodeBorderWidth)
            .attr("opacity", params.opacity)
            .attr("r", params.PnodeRadius)
            .attr("cx", params.leftPadding + params.nodeRadius + params.nodeBorderWidth)
            .attr("cy", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/2);

        graph
            .append("circle")
            .attr("id", "circleA")
            .attr("fill", "#fff")
            .attr("stroke", params.defaultColor)
            .attr("stroke-width", params.nodeBorderWidth)
            .attr("opacity", params.opacity)
            .attr("r", params.nodeRadius)
            .attr("cx", params.leftPadding + params.nodeRadius + params.nodeBorderWidth)
            .attr("cy", params.nodeRadius + params.nodeBorderWidth);

        graph
            .append("circle")
            .attr("id", "circleB")
            .attr("fill", "#fff")
            .attr("stroke", params.defaultColor)
            .attr("stroke-width", params.nodeBorderWidth)
            .attr("opacity", params.opacity)
            .attr("r", params.nodeRadius)
            .attr("cx", params.leftPadding + params.nodeRadius + params.nodeBorderWidth)
            .attr("cy", 3*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength);
        
        graph
            .append("circle")
            .attr("id", "circleQ")
            .attr("fill", "#fff")
            .attr("stroke", placement !== null ? params.PQLineColor : params.defaultColor)
            .attr("stroke-width", params.nodeBorderWidth)
            .attr("opacity", params.opacity)
            .attr("r", params.nodeRadius)
            .attr("cx", params.leftPadding + params.nodeRadius + params.nodeBorderWidth + params.PQLineLength)
            .attr("cy", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/2);

        var arrow = graph
            .append("path")
            .attr("id", "arrow")
            .attr("fill", placement !== null && (placement.abDist - placement.apDist) > 0 ? params.APBLineColor : params.defaultColor)
            .attr("opacity", params.opacity)
            .attr("d", `M 0 0 4 0 0 ${params.arrowHeight} -4 0 0 0`);
        
        graph
            .append("text")
            .attr("x", params.leftPadding + params.nodeRadius + params.nodeBorderWidth + params.textLeftMargin)
            .attr("y", params.nodeRadius + params.nodeBorderWidth)
            .attr("font-size", params.nodeFontSize)
            .attr("font-weight", 700)
            .attr("fill", params.defaultColor)
            .attr("text-anchor", "left")
            .text(placement === null ? 'n/a' : placement.aName);

        // graph
        //     .append("rect")
        //     .attr("x", params.leftPadding + params.nodeRadius + params.nodeBorderWidth + params.textLeftMargin - params.textPadding)
        //     .attr("y", params.nodeRadius + params.nodeBorderWidth - textA.node().getBBox().height + 1.5*params.textPadding)
        //     .attr("width", textA.node().getBBox().width + 2*params.textPadding)
        //     .attr("height", textA.node().getBBox().height)
        //     .style("fill", 'red');

        // textA.raise();
    
        graph
            .append("text")
            .attr("x", params.leftPadding + params.nodeRadius + params.nodeBorderWidth + params.textLeftMargin)
            .attr("y", 3*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength)
            .attr("font-size", params.nodeFontSize)
            .attr("font-weight", 700)
            .attr("fill", params.defaultColor)
            .attr("text-anchor", "left")
            .attr("alignment-baseline", "hanging")
            .text(placement === null ? 'n/a' : placement.bName);

        // graph
        //     .append("rect")
        //     .attr("x", params.leftPadding + params.nodeRadius + params.nodeBorderWidth + params.textLeftMargin - params.textPadding)
        //     .attr("y", 3*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength - params.textPadding)
        //     .attr("width", textB.node().getBBox().width + 2*params.textPadding)
        //     .attr("height", textB.node().getBBox().height)
        //     .style("fill", 'blue');

        // textB.raise();

        var textQ = graph
            .append("text")
            .attr("x", params.leftPadding + params.nodeRadius + params.nodeBorderWidth + params.PQLineLength + params.textLeftMargin)
            .attr("y", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/2 - 1)
            .attr("font-size", params.nodeFontSize)
            .attr("font-weight", 700)
            .attr("fill", params.defaultColor)
            .attr("text-anchor", "left")
            .attr("alignment-baseline", "central")
            .text(placement === null ? 'n/a' : placement.query.name);

        // If text box for query ID exceeds textMaxWidth
        // Subtract characters until textbox fits, then add ellipsis
        var queryNameMod = placement !== null ? placement.query.name : 'query'
        if (placement !== null && textQ.node().getBBox().width > params.textMaxWidth) {
            while (textQ.node().getBBox().width > params.textMaxWidth) {
                queryNameMod = queryNameMod.slice(0, queryNameMod.length - 1);
                textQ.text(queryNameMod)
            }
            queryNameMod = queryNameMod.slice(0, queryNameMod.length - 3) + "...";
            textQ.text(queryNameMod)
        }

        // graph
        //     .append("rect")
        //     .attr("x", params.leftPadding + params.nodeRadius + params.nodeBorderWidth + params.PQLineLength + params.textLeftMargin - params.textPadding)
        //     .attr("y", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/2 - 3.5*params.textPadding)
        //     .attr("width", textQ.node().getBBox().width + 2*params.textPadding)
        //     .attr("height", textQ.node().getBBox().height)
        //     .style("fill", placement === null ? "#FFF" : "#92d19f");

        // textQ.raise();

        var textAP = graph
            .append("text")
            .attr("x", params.leftPadding + params.nodeRadius + params.nodeBorderWidth)
            .attr("y", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/4 - (params.PnodeRadius + params.nodeBorderWidth)/2)
            .attr("font-size", 10)
            .attr("fill", params.defaultColor)
            .attr("text-anchor", "left")
            .attr("alignment-baseline", "central")
            .text(apDistDisplay);

        var textPB = graph
            .append("text")
            .attr("x", params.leftPadding + params.nodeRadius + params.nodeBorderWidth)
            .attr("y", 2.5*(params.nodeRadius + params.nodeBorderWidth) + 0.75*params.ABLineLength - (params.nodeRadius + params.nodeBorderWidth)/2 + 0.5*(params.PnodeRadius + params.nodeBorderWidth) - params.arrowHeight/2 + params.arrowHeight/2)
            .attr("font-size", 10)
            .attr("fill", params.defaultColor)
            .attr("text-anchor", "left")
            .attr("alignment-baseline", "central")
            .text(pbDistDisplay);

        var textPQ = graph
            .append("text")
            .attr("x", params.leftPadding + params.PQLineLength/2 + (params.nodeRadius + params.nodeBorderWidth) - params.nodeBorderWidth/2)
            .attr("y", 2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength/2)
            .attr("font-size", 10)
            .attr("fill", params.defaultColor)
            .attr("text-anchor", "left")
            .attr("alignment-baseline", "central")
            .text(pqDistDisplay);


        // Translate all element down by topPadding, and separately for the textboxes and arrowhead
        graph.selectAll("*").attr("transform", `translate(0,${params.topPadding})`)
        textAP.attr("transform", `translate(${-textAP.node().getBBox().width - params.textRightMargin},${params.topPadding})`)
        textPB.attr("transform", `translate(${-textPB.node().getBBox().width - params.textRightMargin},${params.topPadding})`)
        textPQ.attr("transform", `translate(${-textPQ.node().getBBox().width/2},${params.topPadding - textPQ.node().getBBox().height/2 - params.textBottomPadding})`)
        arrow.attr("transform", `translate(${params.leftPadding + params.nodeRadius + params.nodeBorderWidth},${2*(params.nodeRadius + params.nodeBorderWidth) + params.ABLineLength - params.arrowHeight + params.topPadding})`);

    }

    return (
        <Root>
            <TitleBox variant='body1'>
                <Title>TIPars Schematic</Title><br />
                <InfoText>(branches not drawn to scale)</InfoText>
                <DeselectLink
                    disable={tiparsPlacementSelected === null}
                    component="button"
                    onClick={_deselectPlacement}
                    tiparsPlacementSelected={tiparsPlacementSelected}
                >
                    (deselect)
                </DeselectLink>
            </TitleBox>
            <svg ref={svgRef} />
        </Root>
    );
};

export default Schematic;
