import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unstable_batchedUpdates } from 'react-dom';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import axios, { CancelToken, isCancel } from 'axios';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import debounce from 'lodash/debounce';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/system';

const SEARCH_MUTS_URL = '/tree/search-muts/';

const Root = styled('div')(({ theme }) => ({
    marginTop: 10,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.background.main,
    },
    '& .MuiInputBase-input': {
        maxLength: 60,
        textAlign: "left",
        fontSize: 13,
        color: theme.palette.text.main.normal,
    },
}));

const TableContainer = styled('div')(({ theme }) => ({
    marginTop: 10,
    border: `1px solid ${theme.palette.background.light}`,
    borderRadius: 2,
    height: 150,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    scrollbarWidth: 'none',
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        background: theme.palette.background.lighter,
    },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
    fontSize: 11,
    fontWeight: 500,
    paddingLeft: 25,
    height: 20,
    color: theme.palette.text.main.normal,
}));

const SummaryRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
}));

const SummaryCell = styled(TableCell)(({ theme }) => ({
    color: '#fff',
    fontSize: 12,
    fontWeight: 700,
    paddingLeft: 25,
    height: 20,
}));

const PageContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    color: '#484848',
    fontSize: 13,
    fontWeight: 100,
}));

const PageNumbers = styled('div')(({ theme }) => ({
    display: 'inline-flex',
    float: 'right',
    marginTop: 2,
    fontSize: 11,
    fontWeight: 500,
}));

const PageButton = styled(IconButton)(({ theme }) => ({
    height: 13,
    width: 13,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 20,
    color: theme.palette.background.main,
}));

const LoadingIcon = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    verticalAlign: 'middle',
    marginRight: 10,
}));

const ErrorIcon = styled(WarningIcon)(({ theme }) => ({
    fontSize: 15,
    verticalAlign: 'middle',
}));

const NoResults = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 12,
    flexGrow: 1,
    textAlign: 'center',
    lineHeight: '50px',
}));

const MutTitle = styled(Typography)(({ theme }) => ({
    marginTop: 10,
    color: theme.palette.text.main.normal,
    fontSize: 13,
    fontWeight: 700,
}));

const MutBox = styled('div')(({ theme }) => ({
    marginTop: 5,
    marginBottom: 5,
    padding: 5,
    border: `1px solid ${theme.palette.background.light}`,
    borderRadius: 2,
    height: 80,
    overflowY: 'auto',
}));

const MutText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 12,
    textAlign: 'center',
    lineHeight: '40px',
}));

const ResultBoxText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 12,
    flexGrow: 1,
    textAlign: 'center',
    lineHeight: '50px',
}));

const ClearAllButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.title,
    padding: 0,
    margin: 0,
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));

const ClearAllLabel = styled(Typography)({
    display: 'inline',
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 700,
});

const StyledChip = styled(Chip)(({ theme }) => ({
    margin: 2,
    padding: '0px 5px 0px 5px',
    fontSize: 12,
    color: theme.palette.text.main.normal,
    '&.MuiChip-outlined': {
        border: `1px solid ${theme.palette.background.main}`,
    },
    '& .MuiChip-deleteIcon': {
        color: theme.palette.background.main,
        '&:hover': {
            color: theme.palette.background.main,
            opacity: 0.8,
        },
    },
}));

const OptionTitle = styled(Typography)(({ theme }) => ({
    marginTop: 10,
    marginBottom: -5,
    color: theme.palette.text.main.normal,
    fontSize: 13,
    fontWeight: 700,
}));

const OptionInfo = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '12px',
}));

const OptionRadio = styled(Radio)(({ theme }) => ({
    backgroundColor: 'transparent',
    '&.Mui-checked': {
        color: theme.palette.background.main,
    },
}));

function MutTable(props) {
    const { muts, mutMode, inputPlaceholder, pageLimit, mutsNumMax } = props;
    const { handleMutsOnChange, handleMutModeOnChange } = props;
    const { apiURL } = useSelector(state => state.staticReducer);

    const resultBox = React.useRef(null);
    const inputBox = React.useRef(null);
    const axiosRef = React.useRef(null);
    const loadTimer = React.useRef(null);
    
    const [loading, setLoading] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [input, setInput] = React.useState('');
    const [dataFetchSuccess, setDataFetchSuccess] = React.useState(false);
    const [mutNum, setMutNum] = React.useState(0);
    const [pageNum, setPageNum] = React.useState(1);
    const [pageTotal, setPageTotal] = React.useState(0);
    const [nextURL, setNextURL] = React.useState(null);
    const [previousURL, setPreviousURL] = React.useState(null);
    const [searchOutput, setSearchOutput] = React.useState([]);

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    const _onMutSearch = async (mut, url=null) => {
        // set loadTimer to null if not null
        loadTimer.current && clearTimeout(loadTimer.current);

        // cancel previous axios request if exists
        axiosRef.current && axiosRef.current.cancel();

        // set loading to true if request takes longer than 1500 milliseconds
        loadTimer.current = setTimeout(() => {
            setLoading(true);
        }, dataFetchSuccess && !searchOutput.length ? 0 : 300); // don't delay loading if data was fetched successfully on previous input and no results were returned

        // get request
        try {
            const response = await axios.get(url ?? apiURL + `${SEARCH_MUTS_URL}?page_size=${pageLimit}&ss=${mut}`, { cancelToken: newCancelToken() });

            // update states with received response in one batch
            unstable_batchedUpdates(() => {
                setMutNum(response.data.count);
                setPageNum(response.data.current);
                setPageTotal(response.data.max_page);
                setNextURL(response.data.next);
                setPreviousURL(response.data.previous);
                setSearchOutput(response.data.results);
                setDataFetchSuccess(true);
                setLoading(false);
            });

            // scroll to top
            resultBox.current !== null && resultBox.current.scroll(0, 0);

            // axiosRef.current && axiosRef.current.cancel();
            clearTimeout(loadTimer.current);
        } catch (error) {
            // if get request was cancelled, skip error handling
            if (isCancel(error)) return;

            // handle error here
            setErrorMessage('An error has occured, please try again later.');
            console.error(error);

            // set loadTimer to null if not null
            loadTimer.current !== null && clearTimeout(loadTimer.current);
            setLoading(false);
        }
    };

    const debounceMutSearch = React.useCallback(debounce(_onMutSearch, 800), []); // eslint-disable-line react-hooks/exhaustive-deps

    const _onInputChange = (event) => {
        const newInput = event.target.value;
        setInput(newInput);
        errorMessage.length && setErrorMessage('');
        setDataFetchSuccess(false);

        // don't debounce if data was fetched successfully on previous input and no results were returned
        if (dataFetchSuccess && !searchOutput.length) {
            _onMutSearch(newInput);
        } else {
            debounceMutSearch(newInput);
        }
    };

    const _onInputFocus = () => {
        inputBox.current !== null && inputBox.current.select();
    };

    const _onInputEnter = (event) => {
        // when Enter is pressed
        // and new inputValue has been processed
        // and non-zero results are returned
        // and the input value is not an empty string
        if (event.key === "Enter" && dataFetchSuccess && searchOutput.length === 1 && input.length) {
            // if input has not been previously added to muts
            // and the number of search term has not exceeded mutsNumMax
            if (!muts.includes(input) && muts.length < mutsNumMax) {
                const newMuts = muts.concat([input]);
                handleMutsOnChange(newMuts);

                // refocus input
                inputBox.current.select();
            }
        }
    };

    const _onSelectionClick = (event, mut) => {
        // when one of the available selections is clicked
        // and new inputValue has been processed
        // and non-zero results are returned
        // and the input value is not an empty string
        // if (dataFetchSuccess && input.length) {

        // if input has not been previously added to muts
        // and the number of search term has not exceeded mutsNumMax
        if (!muts.includes(mut) && muts.length < mutsNumMax) {
            const newMuts = muts.concat([mut]);
            handleMutsOnChange(newMuts);

            // refocus input
            inputBox.current.select();
        }
        // }
    };

    const _onMutDelete = (mut) => {
        const newMuts = muts.filter(m => m !== mut);
        handleMutsOnChange(newMuts);
    };

    const _onClearAllMuts = () => {
        handleMutsOnChange([]);
    };

    const _nextPage = () => {
        if (nextURL !== null) {
            _onMutSearch('', nextURL);
        }
        // refocus input
        inputBox.current.select();
    };

    const _prevPage = () => {
        if (previousURL !== null) {
            _onMutSearch('', previousURL);
        }
        // refocus input
        inputBox.current.select();
    };

    useEffect(() => {
        _onMutSearch('');

        return (() => {
            // set loadTimer to null if not null
            loadTimer.current !== null && clearTimeout(loadTimer.current);
            // cancel axios request if exists
            axiosRef.current && axiosRef.current.cancel();
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Root>
            <StyledTextField
                autoFocus
                inputRef={inputBox}
                placeholder={inputPlaceholder}
                fullWidth
                variant='standard'
                value={input}
                onChange={_onInputChange}
                onFocus={_onInputFocus}
                onKeyUp={_onInputEnter}
            />
            <TableContainer ref={resultBox}>
                <Table>
                    <TableBody>
                        <SummaryRow>
                            <SummaryCell
                                component="th"
                                scope="row"
                                size="small"
                            >
                            {
                                loading ?
                                "Please wait..."
                                :
                                `${mutNum} result${mutNum === 1 ? "" : "s"}`
                            }
                            </SummaryCell>
                        </SummaryRow>
                        {
                            errorMessage.length ?
                            <ResultBoxText>
                                <span><ErrorIcon fontSize="inherit" /></span>&nbsp;&nbsp;&nbsp;{errorMessage}
                            </ResultBoxText>
                            :
                            (
                                loading ?
                                <ResultBoxText>
                                    <span>
                                        <LoadingIcon size={13} color="inherit" />
                                    </span>
                                    This should only take a few seconds.
                                </ResultBoxText>
                                :
                                (
                                    searchOutput.length ?
                                    (
                                        searchOutput.map((mut, index) => (
                                            <TableRowStyled
                                                key={index}
                                                style={{
                                                    cursor: dataFetchSuccess && input.length ? 'pointer' : 'auto'
                                                }}
                                                onClick={(event) => _onSelectionClick(event, mut)}
                                            >
                                                <TableCellStyled
                                                    component="th"
                                                    scope="row"
                                                    size="small"
                                                    style={{
                                                        borderBottomWidth: index + 1 === searchOutput.length && searchOutput.length > 5 ? 0 : 1,
                                                    }}
                                                >
                                                    {mut}
                                                </TableCellStyled>
                                            </TableRowStyled>
                                        ))
                                    )
                                    :
                                    <NoResults>
                                        "{input}" did not match any existing mutations.
                                    </NoResults>
                                )
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <PageContainer>
                <PageButton
                    size="small"
                    color="primary"
                    disabled={previousURL === null}
                    onClick={event => _prevPage(event)}
                >
                    <NavigateBeforeIcon/>
                </PageButton>
                <PageButton
                    size="small"
                    color="primary"
                    disabled={nextURL === null}
                    onClick={event => _nextPage(event)}
                >
                    <NavigateNextIcon/>
                </PageButton>
                <PageNumbers>
                    {((pageNum - 1)*pageLimit) + 1}-{pageNum === pageTotal ? mutNum : pageNum*pageLimit}
                    &nbsp;of {mutNum}
                </PageNumbers>
            </PageContainer>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <MutTitle>
                        Mutations ({mutsNumMax} max.)
                    </MutTitle>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right', marginTop: 'auto' }}>
                    <ClearAllButton
                        disableRipple={true}
                        onClick={_onClearAllMuts}
                    >
                        <ClearAllLabel>
                            CLEAR ALL
                        </ClearAllLabel>
                    </ClearAllButton>
                </Grid>
            </Grid>
            <MutBox>
                {
                    muts.length ?
                        muts.map((mut, i) => (
                            <StyledChip
                                key={mut}
                                size='small'
                                label={mut}
                                variant="outlined"
                                onDelete={() => _onMutDelete(mut)}
                            />
                        ))
                        :
                        <MutText>
                            Press "Enter" to add a single mutation.
                        </MutText>
                }
            </MutBox>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <OptionTitle>
                        Options
                    </OptionTitle>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={mutMode}
                            onChange={handleMutModeOnChange}
                        >
                            <FormControlLabel
                                value='exact'
                                control={<OptionRadio
                                            disableRipple={true}
                                            size='small'
                                            color='primary'
                                        />}
                                label={<MutText style={{ marginRight: 15 }}>Exact *</MutText>}
                            />
                            <FormControlLabel
                                value='all'
                                control={<OptionRadio
                                            disableRipple={true}
                                            size='small'
                                            color='primary'
                                        />}
                                label={<MutText style={{ marginRight: 15 }}>All **</MutText>}
                            />
                            <FormControlLabel
                                value='any'
                                control={<OptionRadio
                                            disableRipple={true}
                                            size='small'
                                            color='primary'
                                        />}
                                label={<MutText style={{ marginRight: 15 }}>Any ***</MutText>}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <OptionInfo>
                * Exact: only sequences with <strong style={{ textDecoration: 'underline' }}>EXACT</strong> matches<br />
                ** All: only sequences that include <strong style={{ textDecoration: 'underline' }}>ALL</strong> of the selections<br />
                *** Any: only sequences that include <strong style={{ textDecoration: 'underline' }}>ANY</strong> of the selections
            </OptionInfo>
        </Root>
    )
}

export default MutTable;