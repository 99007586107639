const params = {
    width: 230,
    height: 170,
    padding: 0.15,
    viewBoxParams: {
        color: '#484848',
        edgeColor: '#000000',
        darkModeColor: '#d4d4d4',
        darkModeEdgeColor: '#ffffff',
        opacity: 0.3,
    },
    treeParams: {
        node: {
            color: '#233133',
            darkModeColor: '#ffffff',
            opacity: 0.6,
            filteredOpacity: 0.05,
            radius: 2,
            highlighterRadius: 3.5,
            selectHighlighterColor: '#D40000',
            filterHighlighterColor: '#f0877b',
        },
        link: {
            width: 0.5,
            color: '#233133',
            darkModeColor: '#ffffff',
            opacity: 0.7,
            filteredOpacity: 0.05
        },
        tipars: {
            height: 3,
            lineWidth: 1.5,
            opacity: 0.9,
            unselectedOpacity: 0.1,
            color: '#D40000'
        }
    }
};

export default params;