import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken } from 'axios';
import * as d3 from "d3";

import allActions from '../../../redux';
import Stretcher from './stretcher';
import SubtreeInfo from './subtreeInfo';
import ExploreMap from '../exploreMap';
import BranchScale from '../branchScale';
import params from './params';

function calculate_r(val, gamma=params.node.gamma, maxR=params.node.maxR, minR=params.node.minR) {
	return minR + (maxR - minR)*(1 - Math.exp(-gamma*val));
};

function GlobalTree(props) {

    const dispatch = useDispatch();

    const { active, loading, width, height } = props;

    const {
        visualMode,
        selectedColorLegend,
        darkModeActive,
        spotlightActive,
        goToNodeId,
        selectedSubtreeId,
        centreGraphTrigger,
    } = useSelector(state => state.visualReducer);
    const {
        filtersActive,
        filteredGlobalData,
    } = useSelector(state => state.filtersReducer);
    const { apiURL, staticGlobalData } = useSelector(state => state.staticReducer);
    const colorMap = useSelector(state => state.staticReducer.meta.maps);
    const { tiparsVisible, retrieveTiparsOutputData } = useSelector(state => state.tiparsReducer);

    const mountedRef = React.useRef(false);
    const canvasRef = React.useRef(null);
    const axiosRef = React.useRef(null);
    const darkModeActiveRef = React.useRef(darkModeActive);
    const spotlightActiveRef = React.useRef(spotlightActive);
    const selectedColorLegendRef = React.useRef(selectedColorLegend);
    const filtersActiveRef = React.useRef(filtersActive);
    const filteredGlobalDataRef = React.useRef(filteredGlobalData);
    const tiparsVisibleRef = React.useRef(tiparsVisible);
    const tiparsOutputSubtreesRef = React.useRef([]);
    const rScaleRef = React.useRef(params.node.maxR);
    const transformRef = React.useRef(null);
    const [transformK, setTransformK] = React.useState(1); // to trigger rerender of branchScale
    const zoomRef = React.useRef(null);
    const stretchRef = React.useRef({
        x: staticGlobalData.scales.x,
        y: staticGlobalData.scales.y
    });
    const viewDimRef = React.useRef({
        width: width,
        height: height
    });
    const viewCentreRef = React.useRef(null);
    const selectionRef = React.useRef(null);

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    useEffect(() => {
        if (mountedRef.current && visualMode === 'global') {
            const nodeData = staticGlobalData.nodes[staticGlobalData.rootNodeID];

            // Calculate goTo xy
            const goTo_x = params.canvasOffset.top + nodeData.x*stretchRef.current.y,
                goTo_y = params.canvasOffset.left + nodeData.y*stretchRef.current.x + 400;

            _zoomTo(zoomRef.current, goTo_y, goTo_x, params.initialScaleK);
            _simulationUpdate();
        }
    }, [centreGraphTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mountedRef.current) {
            spotlightActiveRef.current = spotlightActive;
            _simulationUpdate();
        }
    }, [spotlightActive]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mountedRef.current) {
            if (!selectedSubtreeId) {
                selectionRef.current = null;
            }
            selectionRef.current = selectedSubtreeId;

            _simulationUpdate();
        }
    }, [selectedSubtreeId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (visualMode === 'global' && goToNodeId !== null) {
            const nodeData = staticGlobalData.nodes[goToNodeId];

            // Calculate goTo xy
            const goTo_x = params.canvasOffset.top + nodeData.x*stretchRef.current.y,
                goTo_y = params.canvasOffset.left + nodeData.y*stretchRef.current.x;

            _zoomTo(zoomRef.current, goTo_y, goTo_x, params.goToScaleK);
            _simulationUpdate();

            // Reset goToNodeId
            dispatch(allActions.visualActions.setGoToNodeID(null));
        }
    }, [visualMode, goToNodeId]); // eslint-disable-line react-hooks/exhaustive-deps

    const [xyzStore, setXYZStore] = React.useState(null);
    useEffect(() => {
        if (mountedRef.current) {
            if (visualMode === 'global' && xyzStore !== null) {
                transformRef.current.x = xyzStore.x;
                transformRef.current.y = xyzStore.y;
                transformRef.current.k = xyzStore.k;
                _simulationUpdate();
            }
        }
    }, [visualMode]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRScaleOnChange = (event, changeSgn) => {
        const newRScale = rScaleRef.current + changeSgn*params.rScaleStep;
        rScaleRef.current = newRScale > 0 ? newRScale : rScaleRef.current;
        _simulationUpdate();
    };

    const handleYScaleOnChange = (event, changeSgn) => {
        const newStretchY = stretchRef.current.y + changeSgn*params.yScaleStep;
        stretchRef.current.y = newStretchY;

        // Calculate goTo xy offset
        const goTo_x = params.canvasOffset.top + viewCentreRef.current.x*stretchRef.current.y,
            goTo_y = params.canvasOffset.left + viewCentreRef.current.y*stretchRef.current.x;

        _zoomTo(zoomRef.current, goTo_y, goTo_x, transformRef.current.k);
    };

    const [xScale, setXScale] = React.useState(staticGlobalData.scales.x);
    const handleXScaleOnChange = (event, changeSgn) => {
        const newStretchX = stretchRef.current.x + changeSgn*params.xScaleStep;
        stretchRef.current.x = newStretchX > 0 ? newStretchX : stretchRef.current.x;
        setXScale(newStretchX);

        // Calculate goTo xy offset
        const goTo_x = params.canvasOffset.top + viewCentreRef.current.x*stretchRef.current.y,
            goTo_y = params.canvasOffset.left + viewCentreRef.current.y*stretchRef.current.x;
            
        _zoomTo(zoomRef.current, goTo_y, goTo_x, transformRef.current.k);        
    };

    const [viewCentre, setViewCentre] = React.useState(null);
    const handleViewCentreOnChange = (x, y, zoomTo=false) => {
        viewCentreRef.current = { x: y, y: x };
        setViewCentre([x, y]);

        if (zoomTo) {
            // Calculate viewBox xy
            const goTo_x = params.canvasOffset.top + x*stretchRef.current.y,
                goTo_y = params.canvasOffset.left + y*stretchRef.current.x;

            _zoomTo(zoomRef.current, goTo_y, goTo_x, transformRef.current.k);
            _simulationUpdate();
        }
    };

    const [viewBoxLims, setViewBoxLims] = React.useState(null);
    const handleViewBoxOnChange = (x1, y1, x2, y2) => {
        setViewBoxLims([x1, y1, x2, y2]);
    };

    const _onSubtreeSelect = (id) => {
        selectionRef.current = id;
    };

    useEffect(() => {
        if (mountedRef.current) {
            darkModeActiveRef.current = darkModeActive;
            _simulationUpdate();    
        }
    }, [darkModeActive]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mountedRef.current) {
            selectedColorLegendRef.current = selectedColorLegend;
            _simulationUpdate();    
        }
    }, [selectedColorLegend]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mountedRef.current) {
            viewDimRef.current = {
                width: width,
                height: height
            };
            _simulationUpdate();    
        }
    }, [width, height]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mountedRef.current) {
            filtersActiveRef.current = filtersActive;
            if (filteredGlobalData) {
                filteredGlobalDataRef.current = filteredGlobalData;
            }
            _simulationUpdate();    
        }
    }, [filtersActive, filteredGlobalData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mountedRef.current) {
            tiparsVisibleRef.current = tiparsVisible;
            _simulationUpdate();    
        }
    }, [tiparsVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mountedRef.current) {
            if (tiparsVisible && retrieveTiparsOutputData) {
                const newTiparsOutputSubtrees = retrieveTiparsOutputData.results.reduce((accQuery, query) => {
                    const placements = query.placements.reduce((accSubtree, placement) => {
                        if (placement.selected) {
                            return [ ...accSubtree, placement.subtree ]
                        } else {
                            return accSubtree
                        }
                    }, []);
                    return accQuery.concat(placements)
                }, []);
                const uniqueNewTiparsOutputSubtrees = [ ...new Set(newTiparsOutputSubtrees)];
                tiparsOutputSubtreesRef.current = uniqueNewTiparsOutputSubtrees;
            }
            _simulationUpdate();    
        }
    }, [tiparsVisible, retrieveTiparsOutputData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        drawChart();

        const nodeData = staticGlobalData.nodes[staticGlobalData.rootNodeID];

        // Calculate goTo xy
        const goTo_x = params.canvasOffset.top + nodeData.x*stretchRef.current.y,
            goTo_y = params.canvasOffset.left + nodeData.y*stretchRef.current.x + 400;

        _zoomTo(zoomRef.current, goTo_y, goTo_x, params.initialScaleK);

        mountedRef.current = true;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const _simulationUpdate = () => {
        var canvas = document.getElementById('global');
        
        try {
            const context = canvas.getContext('2d');
            const transform = transformRef.current;

            context.save();
    
            context.clearRect(0, 0, params.canvasWidth, params.canvasHeight);
            if (transform) {
                context.translate(transform.x, transform.y);
                context.scale(transform.k, transform.k);
            };

            // Determine if spotlight is active
            const spotlightActive = spotlightActiveRef.current && selectionRef.current;

            // Determine if colour-coding is set to default
            const defaultColor = selectedColorLegendRef.current === 'subtree';

            // Update centre tracker
            const centre_x = viewDimRef.current.width/transform.k/2 - transform.x/transform.k,
                centre_y = viewDimRef.current.height/transform.k/2 - transform.y/transform.k;
            // Calculate true centre in tree reference frame
            const true_centre_x = (centre_x - params.canvasOffset.left)/stretchRef.current.x,
                true_centre_y = (centre_y - params.canvasOffset.top)/stretchRef.current.y;
            handleViewCentreOnChange(true_centre_x, true_centre_y);

            // Calculate viewbox limits [x1, y1, x2, y2]
            const x1 = -transform.x/transform.k,
                x2 = viewDimRef.current.width/transform.k - transform.x/transform.k,
                y1 = viewDimRef.current.height/transform.k - transform.y/transform.k,
                y2 = -transform.y/transform.k;
            // Calculate true centre in tree reference frame
            const true_x1 = (x1 - params.canvasOffset.left)/stretchRef.current.x,
                true_x2 = (x2 - params.canvasOffset.left)/stretchRef.current.x,
                true_y1 = (y1 - params.canvasOffset.top)/stretchRef.current.y,
                true_y2 = (y2 - params.canvasOffset.top)/stretchRef.current.y;
            handleViewBoxOnChange(true_x1, true_y1, true_x2, true_y2);

            // Draw branches
            Object.values(staticGlobalData.nodes).forEach(d => {
                if (d.up && d.up in staticGlobalData.nodes) {
                    const up = staticGlobalData.nodes[d.up];
                    const up_x = params.canvasOffset.top + up.x*stretchRef.current.y,
                        up_y = params.canvasOffset.left + up.y*stretchRef.current.x,
                        d_x = params.canvasOffset.top + d.x*stretchRef.current.y,
                        d_y = params.canvasOffset.left + d.y*stretchRef.current.x;

                    context.beginPath();
                    context.moveTo(up_y, up_x);
                    context.lineTo(up_y, d_x);
                    context.lineTo(d_y, d_x);
                    context.strokeStyle = darkModeActiveRef.current ? params.link.darkModeColor : params.link.color;
                    context.lineWidth = selectionRef.current === d.id && selectionRef.current ? params.link.selectedWidth : params.link.width;
                    if (tiparsVisibleRef.current) {
                        context.globalAlpha = tiparsOutputSubtreesRef.current.includes(d.id) ? params.link.opacity : params.link.filteredOpacity;
                    } else {
                        if (filtersActiveRef.current && filteredGlobalDataRef.current) {
                            context.globalAlpha = d.id in filteredGlobalDataRef.current.dists.subtree ? params.link.opacity : params.link.filteredOpacity;
                        } else {
                            context.globalAlpha = selectionRef.current === d.id && selectionRef.current ? params.link.selectedOpacity : (selectionRef.current ? params.link.unselectedOpacity : params.link.opacity);
                        }
                    }
                    context.stroke();
                }
            });

            // Draw nodes
            Object.values(staticGlobalData.nodes).sort((a, b) => a.num < b.num ? 1 : -1).forEach(d => {
                var nodeRadius = calculate_r(d.num, params.node.gamma, rScaleRef.current, params.node.minR),
                    beginAngle = 0,
                    endAngle = 0;

                const x = params.canvasOffset.top + d.x*stretchRef.current.y,
                    y = params.canvasOffset.left + d.y*stretchRef.current.x;

                if (d.num) {
                    context.moveTo(y, x);
                    context.beginPath();
                    context.arc(y, x, nodeRadius, 0, 2*Math.PI);

                    context.strokeStyle = darkModeActiveRef.current ? params.node.darkModeBorderColor : params.node.borderColor;
                    context.lineWidth = selectionRef.current === d.id && selectionRef.current ? params.node.selectedBorderWidth : params.node.borderWidth;
                    
                    if (tiparsVisibleRef.current) {
                        context.globalAlpha = tiparsOutputSubtreesRef.current.includes(d.id) ? params.node.opacity : params.node.filteredOpacity;
                    } else {
                        if (filtersActiveRef.current && filteredGlobalDataRef.current) {
                            context.globalAlpha = d.id in filteredGlobalDataRef.current.dists.subtree ? params.node.opacity : params.node.filteredOpacity;
                        } else {
                            context.globalAlpha = selectionRef.current === d.id && selectionRef.current ? params.node.selectedOpacity : (selectionRef.current ? (spotlightActive ? params.node.spotlightOpacity : params.node.unselectedOpacity) : params.node.opacity);
                        }
                    }

                    context.stroke();

                    if (defaultColor) {
                        context.fillStyle = d.num > 0 ? '#638687' : '#484848';
                        context.fill();
                    } else {
                        const isMulti = Object.keys(d[selectedColorLegendRef.current]).length - 1;
                        Object.entries(d[selectedColorLegendRef.current]).forEach(([k, v]) => {
                            beginAngle = endAngle;
                            endAngle = endAngle + v*2*Math.PI/d.num;
                                    
                            context.beginPath();
                            if (isMulti) { // multiple sectors
                                context.lineWidth = params.node.borderWidth;
                                context.moveTo(y, x);
                                context.arc(y, x, nodeRadius, beginAngle, endAngle);
                                context.lineTo(y, x);
                            } else { // only one sector
                                context.arc(y, x, nodeRadius, beginAngle, endAngle);
                            }
                            context.strokeStyle = darkModeActiveRef.current ? params.node.darkModeBorderColor : params.node.borderColor;
                            context.lineWidth = params.node.divsBorderWidth;
                            context.stroke();
        
                            context.fillStyle = colorMap[selectedColorLegendRef.current][k].color;
                            context.fill();
                        })
                    }
                }
            });

            // Store transform from last re-render
            const { x, y, k } = transformRef.current;
            setXYZStore({ x: x, y: y, k: k });            

            context.restore();
        } catch (e) {
            console.error(e);
        }

    }

    const _zoomTo = (zoom, x, y, z) => {
        var canvas = document.getElementById('global');
        var transform = d3.zoomIdentity;

        transform.k = z;

        var dx = transform.applyX(x) - viewDimRef.current.width/2,
            dy = transform.applyY(y) - viewDimRef.current.height/2;
        transform.x -= dx;
        transform.y -= dy;
        d3.select(canvas).call(zoom.transform, transform);
        transformRef.current = transform;
    }

    const drawChart = () => {

        const canvas = document.getElementById('global');
        canvas.width = params.canvasWidth;
        canvas.height = params.canvasHeight;

        var transform = d3.zoomIdentity;
        transformRef.current = transform;

        function zoomed(event) {
            transform = event.transform;
            transformRef.current = transform;
            setTransformK(transform.k);
            _simulationUpdate();
        }

        var zoom = d3.zoom().on('zoom', zoomed);
        zoomRef.current = zoom;
        
        function dragsubject(event) {

            const transform = transformRef.current;
            const x = transform.invertX(event.x),
                y = transform.invertY(event.y);

            // implement shift+select to improve performance
            // only select a node if you are pressing shift
            if (event.sourceEvent.shiftKey) {
                for (let i = Object.keys(staticGlobalData.nodes).length-1; i >= 0; --i) {
                    var node = Object.values(staticGlobalData.nodes)[i];
                    
                    const node_x = params.canvasOffset.top + node.x*stretchRef.current.y,
                        node_y = params.canvasOffset.left + node.y*stretchRef.current.x;

                    const dx = x - node_y;
                    const dy = y - node_x;

                    const nodeRadius = calculate_r(node.num, params.node.gamma, rScaleRef.current, params.node.minR);

                    if (dx * dx + dy * dy <= nodeRadius * nodeRadius) {
                        // node.x = transform.applyX(node.x);
                        // node.y = transform.applyY(node.y);

                        // dispatch(allActions.visualActions.setGhostNodeSelected(node.num === null));
                        // dispatch(allActions.visualActions.setSelectedNodeId(node.id));
                    
                        // only if subtree not already selected
                        if (selectionRef.current !== node.id) {
                            _onSubtreeSelect(node.id);
                            dispatch(allActions.filtersActions.resetStaticSubtreeData());
                            dispatch(allActions.filtersActions.resetFilteredSubtreeData());
                            dispatch(allActions.filtersActions.resetStaticSubtreeGraph());
                            dispatch(allActions.filtersActions.resetFilteredSubtreeGraph());
                            dispatch(allActions.visualActions.setSelectedNodeId());
                            dispatch(allActions.filtersActions.resetStaticNodeData());
                            dispatch(allActions.filtersActions.resetFilteredNodeData());
                            dispatch(allActions.filtersActions.resetFilteringParamsOnSubtreeDeselect());
                            dispatch(allActions.visualActions.setSelectedSubtreeId(node.id));
                            dispatch(allActions.filtersActions.loadStaticSubtreeData(node.id, newCancelToken, apiURL));
                            filtersActiveRef.current && dispatch(allActions.filtersActions.loadFilteredSubtreeData(node.id, newCancelToken, apiURL));
        
                            return node;    
                        }
                    }
                }
            }
        }

        function dragstarted(event) {
            _simulationUpdate();
        }

        function dragged(event) {
            _simulationUpdate();
        }
        
        function dragended(event) {
            _simulationUpdate();
        }

        d3
            .select(canvas)
            .call(d3.drag().subject(dragsubject).on('start', dragstarted).on('drag', dragged).on('end',dragended))
            .call(zoom);
        
        _simulationUpdate();

    }

    return (
        <div
            style={{
                position: 'absolute',
                width: width,
                height: height,
                backgroundColor: darkModeActive ? params.darkModeBackgroundColor : 'transparent',
                overflow: 'hidden',
                display: active ? 'unset' : 'none',
                opacity: loading ? 0.1 : 1
            }}
        >
            <SubtreeInfo
                active={active}
            />
            <Stretcher
                label="X"
                xOffset={0}
                darkModeActive={darkModeActive}
                handleOnChange={handleXScaleOnChange}
            />
            <Stretcher
                label="Y"
                xOffset={25}
                darkModeActive={darkModeActive}
                handleOnChange={handleYScaleOnChange}
            />
            <Stretcher
                label="R"
                xOffset={50}
                darkModeActive={darkModeActive}
                handleOnChange={handleRScaleOnChange}
            />
            {
                staticGlobalData ?
                <ExploreMap
                    visualMode='global'
                    tiparsOutputSubtrees={tiparsOutputSubtreesRef.current}
                    treeData={staticGlobalData.nodes}
                    treeBounds={staticGlobalData.treebounds}
                    filtersActive={filtersActive}
                    selectedNode={selectedSubtreeId ? selectedSubtreeId : null}
                    filteredData={filteredGlobalData ? filteredGlobalData.dists.subtree : null}
                    viewCentre={viewCentre}
                    viewBoxLims={viewBoxLims}
                    handleViewCentreOnChange={handleViewCentreOnChange}
                />
                :
                null
            }
            <BranchScale visualMode='global' epsilon={staticGlobalData.brlenSF*xScale} scale={transformK} />
            <canvas
                id='global'
                style={{ display: 'block', pointerEvents: loading ? 'none' : 'auto' }}
                ref={canvasRef}
            />
        </div>
    )
};

export default GlobalTree;