import React from "react";
import "./usecase.css";
import HeaderWhite from "../home/HeaderWhite";
import { Grid, Divider } from "@mui/material";
import Footer from "../Footer";

export default function Usecase3() {
  const context1 = require.context("./imgC/1", true);
  const imagesList1 = context1.keys().map((image) => context1(image));

  const context2 = require.context("./imgC/2", true);
  const imagesList2 = context2.keys().map((image) => context2(image));
  
  const textList1 = [
    'The simplest way to submit a single query sequence is to use the textfield in the TIPars tab. To see how it works, you can click the "example" button in the description above. This will populate the textfield with a sample query sequence in the required format. The “SUBMIT" button will then turn green, indicating that the query sequence is valid and ready for submission.',
    "The query sequence must be a valid nucleotide sequence, and the only allowed characters are ACTGURYKMSWBDHVN (case-insensitive). The submit button will become inactive and change to red if the sequence contains any illegal characters. Make sure that the entire sequence is entered on a single line without any line breaks.",
    'Alternatively, and especially if you want to submit multiple query sequences at once, you can upload a .fasta file (or other equivalent formats) by clicking the “UPLOAD” button beneath the textfield. A dialog will open, allowing you to select a file from your local file system. Once you have selected a file, click "Submit" to complete the upload process.',
    'Once you have either entered a valid query sequence as text or uploaded a .fasta file, the “Submit” button beneath the textfield should turn green. Clicking this button submits the query sequence(s) to TIPars, and a grey box will then appear in the output area below, displaying the message "Creating new submission…” alongside a spinning wheel, indicating that the submission is underway.',
    "Within a few seconds, the grey box should change to blue and display a unique submission ID. Each submission is assigned a unique ID with 38 characters; this ID can be used to retrieve the submission’s results at a later time. By clicking on the blue box, it expands to reveal the current job status as well as other relevant information. To get the most recent job status, click the “REFRESH” button; you can also remove the box by clicking the “REMOVE” button if you are no longer interested in the submission.",
  ];
  const textList2 = [
    'Depending on the number of query sequences submitted, the analysis could take anywhere from 10-20 seconds to a few minutes. Upon completion, arefresh will change the the blue box to green, and relevant information including when the analysis was completed and for how long the results are available for retrieval will be displayed. To view the results, click the "RETRIEVE" button.',
    "Refreshing the page or closing the browser will erase all previous submission records. To check the status or retrieve the results of a prior submission, enter its submission ID in the textfield and click the “SUBMIT” button. Make sure to add a hashtag (#) before the submission ID to indicate that it is a previous submission.",
  ];
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="external-pages">
              <h2 style={{ marginBottom: 10 }}>USE CASE 3</h2>
              <h1>Submitting a TIPars job</h1>
              <div className="text-button-container">
                <p>
                  Since the COVID-19 pandemic, there has been a massive increase
                  in genomic sequencing, necessitating the developing of novel
                  methods to incorporate new sequences into existing phylogenies
                  without the need for re-estimating the entire tree, which can
                  be computationally intensive. "TIPars" was designed
                  specifically for this task, integrating parsimony analysis
                  with pre-computed ancestral sequences. This approach allows
                  for real-time monitoring of pathogen transmission at a
                  global-level through large-scale phylogenetic analysis. In
                  addition to providing a fast and memory-efficient way to grow
                  a phylogeny through iterative addition of new sequences,
                  TIPars can also serve as a tool for identifying the most
                  likely ancestral lineage of a query sequence in a given
                  reference phylogeny, as we have implemented here in EiGENO. In
                  this example, you will learn how to submit either a single
                  query sequence, or a fasta file containing multiple query
                  sequences, to be analysed and inserted into the reference
                  phylogeny using TIPars. For more technical details on how
                  TIPars works, please refer to{" "}
                  <a
                    href="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1011871"
                    target="blank"
                  >
                    Robust expansion of phylogeny for fast-growing genome
                    sequence data
                  </a>{" "}
                  .
                </p>
              </div>

              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
              <div className="usecase-content">
                <h3>1. Creating a new submission</h3>
                {imagesList1.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div className="img-container">
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {textList1[index]}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
              <div className="usecase-content">
                <h3>2. Retrieving submission results</h3>
                {imagesList2.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div className="img-container">
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {textList2[index]}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
