import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import params from './params';

const Root = styled('div')({
    width: '100%',
    padding: 15,
    paddingTop: 5,
});

const KeyContainer = styled(Box)({
    display: 'inline-block',
    width: 135,
});

const CircleContainer = styled(Box)({
    height: 13,
    verticalAlign: 'middle',
    display: 'inline-block',
});

const ColourCircle = styled('span')({
    height: 12,
    width: 12,
    marginBottom: 3,
    borderRadius: '10%',
    display: 'inline-block',
});

const Key = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 10,
    color: theme.palette.text.main.normal,
    cursor: 'auto',
    userSelect: 'none',
}));

function GenderLegend(props) {
    const { distInfo } = props;
    const { opacity } = params;

    const genderColorMap = useSelector(state => state.staticReducer.meta.maps.gender);

    return (
        <Root>
            {
                Object.entries(genderColorMap).map(([key, value]) => (
                    <KeyContainer key={value.label}>
                        <CircleContainer>
                            <ColourCircle style={{ backgroundColor: value.color, opacity: opacity }} />
                        </CircleContainer>
                        <Key>{value.label}</Key>
                        <div style={{ lineHeight: '10px' }}>
                            <Key style={{ fontSize: 11 }}>
                                {distInfo === null || !(key in distInfo) ? 'N/A' : `n=${distInfo[key].num} (${distInfo[key].percent}%)`}
                            </Key>
                        </div>
                    </KeyContainer>
                ))
            }
        </Root>
    )
}

export default GenderLegend;