import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const Description = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    textAlign: 'justify',
    fontSize: 13,
    fontWeight: 700,
    marginBottom: 0,
}));

const Label = styled('span')({
    fontSize: 13,
    display: "inline",
    textTransform: 'none',
});

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    marginRight: 15,
    fontSize: 14,
    fontWeight: 700,
    padding: '3px 12px 3px 12px',
    color: theme.palette.text.title,
}));

const ConfirmButton = styled(StyledButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.lighter,
    "&:hover": {
        opacity: 0.8,
    },
}));

const RejectButton = styled(StyledButton)(({ theme }) => ({
    borderColor: "#fff",
    "&:hover": {
        backgroundColor: 'transparent',
    },
    padding: 10,
    paddingRight: 0,
}));

function GhostMoveDialog(props) {
    const { open, title, content } = props;
    const { confirmHandler, rejectHandler } = props;

    return (
        <Dialog open={open} fullWidth maxWidth={"xs"}>
            <DialogTitle style={{ paddingBottom: 5 }}>
                <Title>
                    {title}
                </Title>
            </DialogTitle>
            <DialogContent>
                <Description>
                    {content}
                </Description>
            </DialogContent>
            <DialogActions>
                <RejectButton disableRipple onClick={rejectHandler}>
                    <Label>No</Label>
                </RejectButton>
                <ConfirmButton disableRipple onClick={confirmHandler}>
                    <Label>Yes</Label>
                </ConfirmButton>
            </DialogActions>
        </Dialog>
    )
}

export default GhostMoveDialog;