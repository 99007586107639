const data = [
  {
    id: "4",
    date: "27.07.2024",
    type: "UPCOMING",
    description:
      "(NEW) DENV interface - coming soon!",
    content: null,
    canExpand: false,
  },
  {
    id: "3",
    date: "15.07.2024",
    type: "NEW FEATURE",
    description:
      "SARS-CoV-2 interface with more than 7 million sequences now up and running. Check it out!",
    content: null,
    canExpand: false,
  },
  {
    id: "2",
    date: "08.04.2024",
    type: "UPCOMING",
    description:
      "SARS-CoV-2 interface to be updated to latest dataset with ~7 million sequences in coming couple of weeks, the largest dataset yet. Stay tuned!",
    content: null,
    canExpand: false,
  },
  {
    id: "1",
    date: "22.01.2024",
    type: "UPCOMING",
    description:
      "SARS-CoV-2 interface to be updated to latest dataset with 600k sequences in coming couple of weeks. Stay tuned!",
    content: null,
    canExpand: false,
  },
];
export default data;
