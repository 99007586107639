import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import NatureRoundedIcon from '@mui/icons-material/NatureRounded';

import GhostMoveDialog from './ghostMoveDialog';
import helpers from '../../../helpers';

const Root = styled(Paper)(({ theme, disabled }) => ({
    borderRadius: 2,
    backgroundColor: disabled ? helpers.hexToRgb(theme.palette.background.dark, 0.7) : theme.palette.background.main,
    margin: 10,
    padding: 10,
    cursor: disabled ? 'auto' : 'pointer',
    opacity: disabled ? 0.7 : 1,
    '&:hover': {
        opacity: disabled ? 0.7 : 0.9
    },
}));

const Text = styled(Typography)({
    color: '#fff',
    fontWeight: 700,
    fontSize: 14
});

const Icon = styled(NatureRoundedIcon)({
    verticalAlign: 'bottom',
    marginLeft: 15,
});

function GoToNode(props) {
    const { active, isGhostMove, subtree, label } = props;
    const { onClickHandler } = props;

    const [ghostMoveDialogOpen, setGhostMoveDialogOpen] = React.useState(false);
    const _openGhostMoveDialog = () => {
        setGhostMoveDialogOpen(true);
    };
    const confirmGhostMoveDialog = () => {
        onClickHandler()
        setGhostMoveDialogOpen(false);
    };
    const rejectGhostMoveDialog = () => {
        setGhostMoveDialogOpen(false);
    };

    return(
        <Fragment>
            <Root disabled={!active} elevation={1} onClick={active ? (isGhostMove ? _openGhostMoveDialog : onClickHandler) : null}>
                <Text>
                    {label.toUpperCase()} <Icon fontSize='small' />
                </Text>
            </Root>
            {
                isGhostMove &&
                <GhostMoveDialog
                    open={ghostMoveDialogOpen}
                    title='Are you sure?'
                    content={`Confirming this action will take you to anoher subtree (${subtree}), are you sure you want to do that?`}
                    confirmHandler={confirmGhostMoveDialog}
                    rejectHandler={rejectGhostMoveDialog}
                />
            }
        </Fragment>
    )
}

export default GoToNode;