import React from "react";
import "./usecase.css";
import HeaderWhite from "../home/HeaderWhite";
import { Grid, Divider } from "@mui/material";
import Footer from "../Footer";

export default function Usecase1() {
  const context1 = require.context("./imgA/1", true);
  const imagesList1 = context1.keys().map((image) => context1(image));

  const context2 = require.context("./imgA/2", true);
  const imagesList2 = context2.keys().map((image) => context2(image));

  const context3 = require.context("./imgA/3", true);
  const imagesList3 = context3.keys().map((image) => context3(image));

  const context4 = require.context("./imgA/4", true);
  const imagesList4 = context4.keys().map((image) => context4(image));

  const textList1 = [
    "All filtering options can be found and accessed from the top-right panel. To add a country-filter, click the button labelled “country”.",
    "To locate only samples collected in the United Kingdom, we have to first deselect all countries (all selected by default) by clicking the white checkbox in the top row.",
    "Once we have done that, we can look up the United Kingdom using the search-bar (case-insensitive). To select the United Kingdom, simply click the row containing the label.",
    "Click “Save” to save the current settings and exit.",
    "You should see the “country” button highlighted in dark-green, indicating that the country-filter has been changed from its default setting.",
  ];
  const textList2 = [
    "Similar to (1a), click the button labelled “collection_date” in the top-right panel.",
    "Check the two boxes at the top based on your data inclusion preferences. If you want to include all sequences regardless of known collection dates, uncheck all boxes. To include only sequences with known year-month of collection, check only the first box. For sequences with a complete record of the collection date, check both boxes.",
    "Adjust the selected time-window using the interactive calendars at the bottom. The colouring of the histogram, which represents the collection dates of all available sequences, will update in real-time to reflect your selection. Finally, click “Save” to save the current settings and exit.",
  ];
  const textList3 = [
    "A button labelled “apply pending” at the top of the top-right panel will illuminate, indicating pending filtering options to be applied. Clicking this button will bring up a loading screen, which should only last a few seconds.",
  ];
  const textList4 = [
    "First, make sure that you are in bubble-view by clicking the globe icon button in the action-bar above the main view-box, where you will see some bubblenodes now being illuminated. To view a mini-map of the entire bubble-tree, click the 'explore map' button in the action-bar. Bubble-nodes with sequences matching the filtering criteria will be highlighted in red.",
    "You can also toggle the filters on and off by clicking the slider in the top-right corner of the filtering options panel. Node colouring in the main view-box will update in real-time.",
  ];

  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="external-pages">
              <h2 style={{ marginBottom: 10 }}>USE CASE 1</h2>
              <h1>
                Locating genome samples collected from a specific country during
                a specific time-window
              </h1>
              <div className="text-button-container">
                <p>
                  In this example, you will learn how to locate all SARS-CoV-2
                  sequences collected in the United Kingdom from January to
                  March 2020. Although it is straightforward to add additional
                  filters for attributes like patient gender or variant, we will
                  keep things simple for now and focus only on country and
                  collection date.
                </p>
              </div>

              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
              <div className="usecase-content">
                <h3>1. Adding a country-filter</h3>
                {imagesList1.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div className="img-container">
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {textList1[index]}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
              <div className="usecase-content">
                <h3>2. Adding a collection-date-filter</h3>
                {imagesList2.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div className="img-container">
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {textList2[index]}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
              <div className="usecase-content">
                <h3>3. Applying filters</h3>
                {imagesList3.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div className="img-container ">
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {textList3[index]}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
              <div className="usecase-content">
                <h3>4. Interpreting filtering output (bubble-view)</h3>
                {imagesList4.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div
                        className={
                          index === 0
                            ? "img-container big-image"
                            : "img-container"
                        }
                      >
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {textList4[index]}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
