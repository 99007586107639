import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
    palette: {
        text:{
            title: "#294546",
            main: {
                light: "#D5D5D5",
                normal: "#484848",
                dark: "#233133"
            },
            error: "#bd0d00"
        },
        background: {
            lighter: "#F4F5F8",
            light: "#babfbf",
            main: "#2C4849",
            dark: "#233133"
        },
    },
    typography: {
        fontFamily: [
            'PTRootUI',
            '-apple-system',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
})

export default customTheme;
