import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import * as d3 from "d3";

import params from './params';

function StackedChart(props) {
    const svgRef = React.useRef(null);
    const { data, total, setBarInfo, setDistInfo } = props;

    const genderColorMap = useSelector(state => state.staticReducer.meta.maps.gender);
    
    const {
        width,
        height,
        padding,
        margin,
        leftMargins,
        yAxisValueBreakpoint,
        axisColor,
        opacity,
        hoverOpacity,
        xAxisFontSize,
        yAxisFontSize,
        fontWeight,
        fadeInOutDuration
    } = params;

    useEffect(() => {
        drawChart();
        const svg = svgRef.current;

        return (() => {
            d3.select(svg).remove();
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const _getDecNum = value => Math.floor(Math.log10(value));

    const drawChart = () => {

        // Create svg
        const svg = d3
            .select(svgRef.current)
            .attr("viewBox", `0, 0, ${width + margin.right}, ${height - padding}`);

        // Get max number for age groups
        const numMax = data.reduce((currMax, bin) => bin.num > currMax ? bin.num : currMax, 0);

        // Set y-axis
        const y = d3.scaleLinear()
            .range([height - padding - margin.bottom, margin.top])
            .domain([0, numMax]).nice();

        // Extract maximum value of y-axis about nice() and set left-margin accordingly to accommodate different lengths
        const leftMarginIndex = y.domain()[1] >= yAxisValueBreakpoint ? leftMargins.length - 1 : _getDecNum(y.domain()[1]);
        margin.left = leftMargins[leftMarginIndex];

        // Create x-axis with updated left-margin
        const x = d3.scaleBand()
            .domain(data.map(bin => bin.label))
            .range([margin.left, width]);

        // On hover over a bar
        const hoverClick = (d) => {
            if (d !== null) {
                d3.selectAll('.age-gender-bars')
                    .each(function(node) {
                        const active = node.data.label === d.data.label;
                        if (active) {
                            setDistInfo(node.data.distInfo);
                            setBarInfo(`(age) ${node.data.label}, n=${node.data.num} (${node.data.percent}%)`);
                        }
                        d3.selectAll(`#bar-${node.data.label.replace(/\D/g,'')}`)
                            .transition()
                            .duration(fadeInOutDuration)
                            .style('fill-opacity', active ? 1 : hoverOpacity);
                    })
            } else {
                setBarInfo('');
                setDistInfo(null);
                d3
                    .selectAll('.age-gender-bars')
                    .transition()
                    .duration(fadeInOutDuration)
                    .style('fill-opacity', 1);
            }
        };

        svg.append('g')
            .selectAll('age-bin')
            .data(data)
            .join('g')
            .selectAll('stacked-bar')
            .data(d => d.divs.map(div => ({ ...div, data: { ...d, percent: Math.round(d.num*10000/total)/100, distInfo: d.divs.reduce((acc, curr) => ({ ...acc, [curr.id]: { num: curr.num, percent: Math.round(curr.num*10000/d.num)/100} }), {}) } })))
            .join('rect')
            .attr('class', 'age-gender-bars')
            .attr('id', d => `bar-${d.data.label.replace(/\D/g,'')}`)
            .attr('x', d => x(d.data.label))
            .attr('y', d => {
                const prevHeight = d.data.divs.reduce((acc, div) => acc + (div.id <= d.id ? div.num : 0), 0);
                return y(prevHeight);
            })
            .attr('height', d => y(0) - y(d.num))
            .attr('width', (width - margin.left - data.length*2)/data.length)
            .attr('fill', d => genderColorMap[d.id].color)
            .attr('opacity', opacity)
            .on('mouseover', (event, d) => hoverClick(d))
            .on('mouseout', () => hoverClick(null));
            
        var xAxis = g => g
            .attr('transform', `translate(0,${height - padding - margin.bottom})`)
            .call(d3.axisBottom(x));

        var yAxis = g => g
            .attr('transform', `translate(${margin.left},0)`)
            .call(d3.axisLeft(y).tickValues(y.ticks().filter(tick => Number.isInteger(tick))).tickFormat(d3.format(numMax >= 10000 ? `.1e` : 'd')));

        svg.append('g')
            .attr('class', 'age-gender-x-axis')
            .call(xAxis);

        svg.append('g')
            .attr('class', 'age-gender-y-axis')
            .call(yAxis);

        d3
            .select('.age-gender-x-axis')
            .selectAll('text')
            .attr('font-family', 'PTRootUI')
            .attr('font-size', xAxisFontSize)
            .attr('font-weight', fontWeight)
            .attr('fill', axisColor)
            .selectAll('*')
            .attr('color', axisColor);

        d3
            .select('.age-gender-y-axis')
            .selectAll('text')
            .attr('font-family', 'PTRootUI')
            .attr('font-size', yAxisFontSize)
            .attr('font-weight', fontWeight)
            .attr('fill', axisColor)
            .selectAll('*')
            .attr('color', axisColor);
    }

    return (
        <div style={{ width: width - 2*padding, height: height - 2*padding, padding: padding, paddingBottom: 0 }}>
            <svg ref={svgRef} />
        </div>
    );
}

export default StackedChart;