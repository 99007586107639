import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CenterFocusWeakRoundedIcon from '@mui/icons-material/CenterFocusWeakRounded';
import HighlightRoundedIcon from '@mui/icons-material/HighlightRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import HeightRoundedIcon from '@mui/icons-material/HeightRounded';
import PageviewRoundedIcon from '@mui/icons-material/PageviewRounded';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Brightness4RoundedIcon from '@mui/icons-material/Brightness4Rounded';
import PublicIcon from '@mui/icons-material/Public';
import TitleIcon from '@mui/icons-material/Title';
import clsx from 'clsx';

import allActions from '../redux';

// Define styled components
const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    paddingLeft: 10,
    color: '#fff',
    overflowX: 'hidden',
    zIndex: 10,
    boxShadow: '0px 3px 5px 0px rgb(0, 0, 0, 0.5)',
  }));

const CustomButton = styled(Button)(({ theme }) => ({
    '&:hover': {
        opacity: 0.8,
    },
    '&:disabled': {
        color: theme.palette.text.main.dark,
    },
    color: '#fff',
    marginRight: 10,
    textTransform: 'none',
}));

const ButtonLabel = styled(Typography)(({ theme }) => ({
    fontSize: 11,
    fontWeight: 700,
    lineHeight: '11px',
    textAlign: 'end',
    whiteSpace: 'nowrap',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 5,
}));

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    color: '#fff',
    '&:disabled': {
        color: theme.palette.text.main.dark,
        opacity: 1,
    },
    visibility: 'visible',
}));

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    root: {
    marginRight: 20,
    marginLeft: 10,
    },
    grouped: {
    opacity: 0.4,
    color: '#fff',
    '&.Mui-selected': {
        opacity: 1,
        color: '#fff',
        cursor: 'auto',
    },
    width: 50,
    borderColor: '#262626',
    cursor: 'pointer',
    borderRadius: 0,
    },
}));

const SplitButtonLabel = ({ label }) => {
    const stringSplits = label.split(' ');

    return (
        <ButtonLabel>
            {stringSplits[0]}<br />{stringSplits[1]}
        </ButtonLabel>
    );
};

function ActionBar(props) {
    const dispatch = useDispatch();

    const { fatalError, filteringError, initialLoading, filtersLoading } = props;
    const { filtersActive, filteredGlobalData, staticSubtreeGraph } = useSelector(state => state.filtersReducer);
    const { visualMode, spotlightActive, darkModeActive, exploreMapActive, colorLegendActive, branchScaleActive, selectedSubtreeId, selectedNodeId } = useSelector(state => state.visualReducer);
    const { tiparsVisible, retrieveTiparsOutputData } = useSelector(state => state.tiparsReducer);

    const disabled = fatalError || filteringError || initialLoading || filtersLoading;

    const _onToggleCentreGraph = () => {
        dispatch(allActions.visualActions.toggleCentreGraph());
    };

    const _onToggleVisualMode = (event, newVisualMode) => {
        newVisualMode !== null && dispatch(allActions.visualActions.toggleVisualMode(newVisualMode));
    };

    const _onBranchScaleOnClick = () => {
        dispatch(allActions.visualActions.toggleBranchScale());
    };

    const _onExploreMapOnClick = () => {
        dispatch(allActions.visualActions.toggleExploreMap());
    };

    const _onColorLegendOnClick = () => {
        dispatch(allActions.visualActions.toggleColorLegend());
    };

    const _onToggleSpotlight = () => {
        dispatch(allActions.visualActions.toggleSpotlight());
    };
    const _onToggleDarkMode = () => {
        dispatch(allActions.visualActions.toggleDarkMode());
    };

    const _onTiparsVisualise = () => {
        dispatch(allActions.tiparsActions.toggleTiparsVisibility());
    };

    const _deselectSelected = () => {
        if (visualMode === 'global') {
            dispatch(allActions.visualActions.setSelectedSubtreeId(null));
            dispatch(allActions.filtersActions.resetStaticSubtreeData());
            dispatch(allActions.filtersActions.resetFilteredSubtreeData());
            dispatch(allActions.filtersActions.resetStaticSubtreeGraph());
            dispatch(allActions.filtersActions.resetFilteredSubtreeGraph());
            dispatch(allActions.visualActions.setSelectedNodeId());
            dispatch(allActions.filtersActions.resetStaticNodeData());
            dispatch(allActions.filtersActions.resetFilteredNodeData());
            dispatch(allActions.filtersActions.resetFilteringParamsOnSubtreeDeselect());
            filtersActive && filteredGlobalData === null && dispatch(allActions.filtersActions.activateFilters(false));
        } else if (visualMode === 'rect') {
            dispatch(allActions.visualActions.setSelectedNodeId());
            dispatch(allActions.filtersActions.resetStaticNodeData());
            dispatch(allActions.filtersActions.resetFilteredNodeData());
        }
    };

    return (
        <Root>
            <div style={{ display: 'grid', width: '100%', gridTemplateColumns: 'auto auto' }}>
                <div style={{ display: 'flex' }}>
                    <CustomButton
                        disableRipple={true}
                        disabled={disabled}
                        onClick={_onToggleCentreGraph}
                    >
                        <SplitButtonLabel label='centre tree' />
                        <CenterFocusWeakRoundedIcon fontSize='small' />
                    </CustomButton>
                    <CustomButton
                        disableRipple={true}
                        disabled={disabled || (visualMode === 'global' && !selectedSubtreeId) || (visualMode === 'rect' && !selectedNodeId)}
                        onClick={_onToggleSpotlight}
                        style={{ opacity: spotlightActive ? 0.8 : null }}
                    >
                        <SplitButtonLabel label='spotlight on/off' />
                        <HighlightRoundedIcon fontSize='small' />
                    </CustomButton>
                    <CustomButton
                        disableRipple={true}
                        disabled={disabled}
                        onClick={_onToggleDarkMode}
                        style={{ opacity: darkModeActive ? 0.8 : null }}
                    >
                        <SplitButtonLabel label='light/dark mode' />
                        <Brightness4RoundedIcon fontSize='small' />
                    </CustomButton>
                    <CustomButton
                        disableRipple={true}
                        disabled={disabled || (visualMode === 'global' && !selectedSubtreeId) || (visualMode === 'rect' && !selectedNodeId)}
                        onClick={_deselectSelected}
                    >
                        <SplitButtonLabel label='deselect selected' />
                        <ClearRoundedIcon fontSize='small' />
                    </CustomButton>
                    <CustomButton
                        disableRipple={true}
                        disabled={disabled || retrieveTiparsOutputData === null}
                        className={clsx(tiparsVisible && { color: '#b50505', opacity: 0.8, marginTop: 1 })}
                        onClick={_onTiparsVisualise}
                    >
                        <SplitButtonLabel label='TIPars visible' />
                        <TitleIcon fontSize='small' />
                    </CustomButton>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomToggleButtonGroup
                        value={visualMode}
                        size='small'
                        exclusive
                        onChange={_onToggleVisualMode}
                    >
                        <CustomToggleButton disabled={disabled || !staticSubtreeGraph} disableRipple={true} value='global'>
                            <PublicIcon className={clsx(disabled && { color: theme => theme.palette.text.main.dark, opacity: 1 })} fontSize='small' />
                        </CustomToggleButton>
                        <CustomToggleButton disabled={disabled || !staticSubtreeGraph} disableRipple={true} value='rect'>
                            <AccountTreeIcon className={clsx((disabled || !staticSubtreeGraph) && visualMode !== 'rect' && { color: theme => theme.palette.text.main.dark, opacity: 1 })} fontSize='small' />
                        </CustomToggleButton>
                    </CustomToggleButtonGroup>
                    <CustomButton
                        disableRipple={true}
                        disabled={disabled}
                        onClick={_onExploreMapOnClick}
                        style={{ opacity: exploreMapActive ? 0.8 : null }}
                    >
                        <SplitButtonLabel label='explore map' />
                        <PageviewRoundedIcon fontSize='small' />
                    </CustomButton>
                    <CustomButton
                        disableRipple={true}
                        disabled={disabled}
                        onClick={_onColorLegendOnClick}
                        style={{ opacity: colorLegendActive ? 0.8 : null }}
                    >
                        <SplitButtonLabel label='colour legend' />
                        <ViewListRoundedIcon fontSize='small' />
                    </CustomButton>
                    <CustomButton
                        disableRipple={true}
                        disabled={disabled}
                        onClick={_onBranchScaleOnClick}
                        style={{ opacity: branchScaleActive ? 0.8 : null, marginRight: 0 }}
                    >
                        <SplitButtonLabel label='length scaling' />
                        <HeightRoundedIcon fontSize='small' />
                    </CustomButton>
                </div>
            </div>
        </Root>
    );
}

export default ActionBar;