import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import params from './params';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    maxHeight: 70,
    padding: 10,
    paddingTop: 0,
    paddingBottom: 15,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        display: 'none'
    },
    scrollbarWidth: 'none',
}));

const KeyContainer = styled(Box)({
    display: 'inline-block',
    padding: '0px 5px'
});

const CircleContainer = styled(Box)({
    height: 13,
    verticalAlign: 'middle',
    display: 'inline-block',
});

const ColorCircle = styled('span')({
    height: 12,
    width: 12,
    marginBottom: 3,
    borderRadius: '10%',
    display: 'inline-block',
});

const Key = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    height: 0,
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 5,
    color: theme.palette.text.main.normal,
    cursor: 'auto',
    userSelect: 'none',
}));

const NoData = styled(Typography)(({ theme }) => ({
    width: '100%',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.main.normal
}));

function FieldLegend(props) {
    const { bar, colorMap } = props;
    const { opacity } = params;

    return (
        <Root>
            {
                bar === null ?
                <NoData>
                    Click on a date-bin to see more info.
                </NoData>
                :
                bar.data.divs.sort((a, b) => b.num - a.num).map(div => (
                    <KeyContainer key={div.id}>
                        <CircleContainer>
                            <ColorCircle style={{ backgroundColor: colorMap[div.id].color, opacity: opacity }} />
                        </CircleContainer>
                        <Key>{colorMap[div.id].label} ({div.percent}%)</Key>
                    </KeyContainer>
                ))
            }
        </Root>
    )
}

export default FieldLegend;