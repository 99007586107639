import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Link } from '@mui/material';
import { styled } from '@mui/system';

import SubmissionTab from './submissionTab';
import allActions from '../../../../../redux';

const HistoryBox = styled('div')(({ theme }) => ({
    minHeight: 100,
    maxHeight: 200,
    display: "block",
    marginTop: 10,
    marginBottom: 5,
    border: `1px solid ${theme.palette.background.main}`,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 3,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        display: 'none'
    },
    scrollbarWidth: 'none',
}));

const Description = styled(Typography)(({ theme }) => ({
    padding: 0,
    fontSize: 12,
    color: theme.palette.text.main.normal,
    textAlign: "left",
}));

const SmallDescription = styled(Typography)(({ theme }) => ({
    padding: 0,
    marginTop: -7,
    fontSize: 11,
    fontWeight: 700,
    color: theme.palette.text.main.normal,
    textAlign: "left",
}));

const RemoveLink = styled(Typography)(({ theme, disabled }) => ({
    color: disabled ? theme.palette.text.main.normal : theme.palette.text.title,
    opacity: disabled ? 0.6 : 1,
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 2,
    cursor: disabled ? 'auto' : 'pointer',
    textDecoration: 'underline',
}));

const NoSubmission = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 12,
    flexGrow: 1,
    align: "center",
    lineHeight: "50px",
}));

function SubmissionHistory(props) {
    const dispatch = useDispatch();

    const { maxSubmissionsNum } = props;

    const { activeSubmissions } = useSelector(state => state.tiparsReducer);

    const [expandedId, setExpandedId] = React.useState(null);
    const handleTabOnClick = (id) => {
        setExpandedId(id);
    };

    const _onRemoveAll = () => {
        dispatch(allActions.tiparsActions.removeAllActiveSubmissions());
        dispatch(allActions.tiparsActions.resetTiparsSubmissionOutput());
    };

    useEffect(() => {
        setExpandedId(null);
    }, [activeSubmissions]);

    return (
        <div style={{ marginTop: 15 }}>
            <Description>
                Recent submissions in descending chronological order (
                <Link
                    component='button'
                    disabled={!activeSubmissions.length}
                    style={{ textDecoration: !activeSubmissions.length ? 'none' : 'underline' }}
                    onClick={_onRemoveAll}
                >
                    <RemoveLink disabled={!activeSubmissions.length}>
                        clear all
                    </RemoveLink>
                </Link>
            </Description>
            <SmallDescription>
                (a maximum of {maxSubmissionsNum} submissions allowed)
            </SmallDescription>
            <HistoryBox>
                {
                    activeSubmissions.length ?
                    activeSubmissions.map(submission => (
                        <SubmissionTab
                            expandedId={expandedId}
                            submission={submission}
                            handleTabOnClick={handleTabOnClick}
                        />
                    ))
                    :
                    <NoSubmission>
                        No active submissions.
                    </NoSubmission>
                }
            </HistoryBox>
        </div>
    )
}

export default SubmissionHistory;