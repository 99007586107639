import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
 
const KeyContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto 13px',
    marginBottom: 4,
    marginRight: 10,
});

const CircleContainer = styled('div')({
    height: 13,
    verticalAlign: 'middle',
    display: 'inline-block',
});

const ColourCircle = styled('span')({
    height: 12,
    width: 12,
    marginLeft: 10,
    marginBottom: 3,
    borderRadius: '10%',
    display: 'inline-block',
});

const Key = styled(Typography)(({ theme, darkModeActive }) => ({
    display: 'inline-block',
    lineHeight: '12px',
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 17,
    color: darkModeActive ? `${theme.palette.text.main.light} !important` : theme.palette.text.main.dark,
    cursor: 'auto',
    userSelect: 'none',
}));

const PageButton = styled(IconButton)(({ theme, darkModeActive }) => ({
    height: 10,
    width: 10,
    margin: '3px 0px 0px 15px',
    color: darkModeActive ? `${theme.palette.text.main.light} !important` : theme.palette.background.main,
    '&:disabled': {
        opacity: 0.5
    }
}));

function PaginatedLegend(props) {
    const { graphDefault, selectedLegend, sortedObjs, pageLimit } = props;

    const { darkModeActive } = useSelector(state => state.visualReducer);

    const [pageNum, setPageNum] = React.useState(1);
    const _nextPage = (event) => {
        setPageNum(pageNum + 1);
    };
    const _prevPage = (event) => {
        setPageNum(pageNum - 1);
    };

    useEffect(() => {
        setPageNum(1);
    }, [selectedLegend]);

    return (
        <div style={{ marginTop: 5 }}>
            {
                graphDefault ?
                <Fragment>
                    <KeyContainer key='all'>
                        <Key darkModeActive={darkModeActive}>ALL</Key>
                        <CircleContainer>
                            <ColourCircle style={{ backgroundColor: '#638687' }}></ColourCircle>
                        </CircleContainer>
                    </KeyContainer>
                    <KeyContainer key='nonNode'>
                        <Key darkModeActive={darkModeActive}>SEQLESS</Key>
                        <CircleContainer>
                            <ColourCircle style={{ backgroundColor: '#484848' }}></ColourCircle>
                        </CircleContainer>
                    </KeyContainer>
                </Fragment>
                :
                sortedObjs.slice((pageNum - 1)*pageLimit, Math.min(sortedObjs.length, pageNum*pageLimit)).map(obj => (
                    <KeyContainer key={obj.label}>
                        <Key darkModeActive={darkModeActive}>{obj.label}</Key>
                        <CircleContainer>
                            <ColourCircle style={{ backgroundColor: obj.color }}></ColourCircle>
                        </CircleContainer>
                    </KeyContainer>
                ))
            }
            <PageButton
                size='small'
                color='primary'
                darkModeActive={darkModeActive}
                disabled={graphDefault || sortedObjs.length <= pageLimit || pageNum === 1}
                onClick={_prevPage}
            >
                <ArrowLeftIcon />
            </PageButton>
            <PageButton
                size='small'
                color='primary'
                darkModeActive={darkModeActive}
                disabled={graphDefault || pageNum*pageLimit >= sortedObjs.length}
                onClick={_nextPage}
            >
                <ArrowRightIcon/>
            </PageButton>
        </div>
    )
}

export default PaginatedLegend;