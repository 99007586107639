import React, { Component } from "react";
import logo from "../../../images/logo.svg";
import Drawer from "../Drawer";
import "../../../";

class HeaderLeft extends Component {
  render() {
    return (
      <div
        className="HeaderLeft"
        style={{
          height: "96px",
          display: "flex",
          marginRight: "0",
          float: "right",
        }}
      >
        <div
          className="logo"
          style={{
            width: "48px",
            height: "48px",
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "30px",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: "48px",
              height: "48px",
            }}
          />
        </div>

        <Drawer bkgColor="#315A5E" />
      </div>
    );
  }
}

export default HeaderLeft;