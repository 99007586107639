import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import allActions from '../../../redux';

const TitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const DescriptionText = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    textAlign: 'justify',
    fontSize: 13,
    fontWeight: 700,
    marginBottom: 0,
}));

const ContentDiv = styled('div')(({ theme }) => ({
    marginTop: 4,
    marginBottom: 15,
    border: `1px solid ${theme.palette.background.light}`,
    borderBottom: 0,
    borderRadius: 2,
    overflowY: "auto",
    width: 243,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        background: theme.palette.background.lighter,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    cursor: "pointer",
    fontSize: 12,
    fontWeight: 500,
    height: 20,
    padding: 7,
    paddingLeft: 15,
    color: theme.palette.text.title,
    borderBottom: `1px solid ${theme.palette.background.light}`,
}));

function TiparsMultiDialog(props) {
    const dispatch = useDispatch();

    const { tiparsPlacements } = props;
    const { closeHandler } = props;

    const _placementOnClick = (event, placement) => {
        closeHandler();
        dispatch(allActions.tiparsActions.setTiparsPlacementSelection(placement));
    };

    return (
        <Dialog open={tiparsPlacements.length > 1} maxWidth={"xs"} onClose={closeHandler}>
            <DialogTitle style={{ paddingBottom: 5 }}>
                <TitleTypography>
                    Select A Single Placement
                </TitleTypography>
            </DialogTitle>
            <DialogContent>
                <DescriptionText>
                    Queries:
                    <ContentDiv>
                        <Table style={{ border: '0px' }}>
                            <TableBody>
                                {
                                    tiparsPlacements.map(placement => (
                                        <StyledTableRow
                                            onMouseDown={event => _placementOnClick(event, placement)}
                                            key={placement.id}
                                        >
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                                size="small"
                                            >
                                                {placement.query.name}
                                            </StyledTableCell>
                                            <StyledTableCell />
                                        </StyledTableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </ContentDiv>
                </DescriptionText>
            </DialogContent>
        </Dialog>
    )
}

export default TiparsMultiDialog;