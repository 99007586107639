import React, { Fragment, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

import Stacked from './stacked';
import GenderLegend from './genderLegend';

const Root = styled(Paper)(({ theme }) => ({
    borderRadius: 2,
    backgroundColor: theme.palette.background.lighter,
    margin: 10,
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.dark}`,
    textAlign: 'right',
    padding: '8px 15px',
    color: '#fff',
    borderRadius: '2px 2px 0px 0px',
}));

const Title = styled(Typography)(({ theme }) => ({
    display: 'inline',
    float: 'left',
    fontSize: 14,
    fontWeight: 700,
}));

const ItemInfo = styled(Typography)(({ theme }) => ({
    display: 'contents',
    float: 'right',
    fontSize: 12,
    fontWeight: 500,
}));

const NoData = styled(Typography)(({ theme }) => ({
    padding: 15,
    width: '100%',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.main.normal,
}));

function AgeGenderStacked(props) {
    const { data } = props;

    const [barInfo, setBarInfo] = React.useState('');
    const [distInfo, setDistInfo] = React.useState(null);

    const [renderTrigger, setRenderTrigger] = React.useState('');
    useEffect(() => {
        setRenderTrigger(Math.random().toString());
        setBarInfo('');
        setDistInfo(null);
    }, [data]);

    return (
        <Root elevation={1}>
            <Grid container spacing={0}>
                <TitleContainer item xs={12}>
                    <Title>
                        patient age/gender
                    </Title>
                    <ItemInfo>
                        {barInfo}
                    </ItemInfo>
                </TitleContainer>
                {
                    data.num ?
                    <Fragment key={renderTrigger}>
                        <Stacked
                            data={data.counts}
                            total={data.num}
                            setBarInfo={setBarInfo}
                            setDistInfo={setDistInfo}
                        />
                        <GenderLegend
                            distInfo={distInfo}
                        />
                    </Fragment>
                    :
                    <NoData>
                        No data to display.
                    </NoData>
                }
            </Grid>
        </Root>
    );
}

export default AgeGenderStacked;