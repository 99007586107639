import axios from 'axios'
import filtersActions from '../filters/actions';

const META_URL = '/seq/meta-static-fast/';
const GLOBAL_DATA_URL = '/tree/global-static-fast/';

// Set URL for API
const setApiURL = (url) => {
    return {
        type: 'SET_API_URL',
        payload: url
    };
};

// Initial load of static metadata
const loadMeta = (newCancelToken, apiURL) => async (dispatch) => {
    try {
        const response = await axios.get(apiURL + META_URL, { cancelToken: newCancelToken() });
        dispatch({
            type: 'LOAD_META',
            payload: response.data
        });
        dispatch(filtersActions.setInitialFilters(response.data));
        dispatch(filtersActions.setApiFilteringParams(true));
    } catch(error) {
        dispatch({
            type: 'LOAD_META_ERROR',
            payload: error.message
        });
    }
};

// Initial load of static global data
const loadGlobalData = (newCancelToken, apiURL) => async dispatch => {
    try {
        const response = await axios.get(apiURL + GLOBAL_DATA_URL, { cancelToken: newCancelToken() });
        dispatch({
            type: 'LOAD_STATIC_GLOBAL_DATA',
            payload: response.data
        });
    } catch(error) {
        dispatch({
            type: 'LOAD_STATIC_GLOBAL_DATA_ERROR',
            payload: error.message
        });
    }
};

// Reset static variables to default
const resetDefault = () => {
    return {
        type: 'RESET_DEFAULT'
    };
};

const allActions = {
    setApiURL,
    loadMeta,
    loadGlobalData,
    resetDefault
};

export default allActions;