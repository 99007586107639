import React from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import { styled } from '@mui/system';

const Root = styled('div')(({ theme }) => ({
  marginTop: 10,
  width: 260,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.background.main,
  },
  "& .MuiInputBase-input": {
    maxLength: 60,
    textAlign: "left",
    fontSize: 13,
    color: theme.palette.text.main.normal,  
  }
}));

const ShowOptionsContainer = styled(Grid)(({ theme }) => ({
  marginTop: 10,
  textAlign: "right",
  color: theme.palette.text.main.normal,
}));

const CustomButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  padding: 0,
  fontWeight: 700,
  marginBottom: 3,
  color: theme.palette.text.title,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const ShowOptionLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  paddingTop: 2,
  textTransform: 'none',
  "&:hover": {
    textDecoration: "underline",
  },
}));

const PageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: "#484848",
  fontSize: 13,
  fontWeight: 100,
}));

const PageNumbers = styled('div')(({ theme }) => ({
  display: "inline-flex",
  float: "right",
  marginTop: 2,
  fontSize: 11,
  fontWeight: 500,
}));

const PageButton = styled(IconButton)(({ theme }) => ({
  height: 13,
  width: 13,
  marginTop: 2,
  marginBottom: 2,
  marginRight: 20,
  color: theme.palette.background.main,
}));

const TableContainer = styled('div')(({ theme }) => ({
  marginTop: 0,
  border: `1px solid ${theme.palette.background.light}`,
  borderRadius: 2,
  height: 240,
  overflowY: "auto",
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  scrollbarWidth: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    background: theme.palette.background.lighter,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 500,
  paddingLeft: 25,
  height: 20,
  color: theme.palette.text.title,
}));

const SummaryRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
}));

const SummaryCell = styled(TableCell)(({ theme }) => ({
  color: "#fff",
  fontSize: 12,
  fontWeight: 700,
  paddingLeft: 25,
  height: 20,
}));

const KeyText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  display: "inline",
  verticalAlign: 'baseline',
}));

const TableCheckBox = styled(Checkbox)(({ theme }) => ({
  height: 14,
  width: 14,
  marginTop: 2,
  marginBottom: 2,
  color: theme.palette.background.main,
  '&.Mui-checked': {
    color: theme.palette.background.main,
  },
}));

const SummaryCheckBox = styled(Checkbox)(({ theme }) => ({
  height: 14,
  width: 14,
  marginTop: 2,
  marginBottom: 2,
  color: "#fff",
  '&.Mui-checked': {
    color: "#fff",
  },
  '&.Mui-disabled': {
    color: theme.palette.background.light,
  },
}));

const CustomIndeterminateIcon = styled(IndeterminateCheckBox)(({ theme }) => ({
  color: '#fff',
}));

const HelperText = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  fontWeight: 500,
  color: theme.palette.text.normal,
}));

function SearchSelect(props) {
  const resultBox = React.createRef();
  const inputBox = React.createRef();

  const { choices, inputPlaceholder, pageLimit, helperText } = props;
  const { handleChoicesOnChange, handleAllUncheckedUpdate } = props;

  const [displayChoices, setDisplayChoices] = React.useState(choices);
  const [pageArr, setPageArr] = React.useState(choices.slice(0, choices.length < pageLimit ? choices.length : pageLimit));
  const [pageTotal, setPageTotal] = React.useState(Math.ceil(choices.length/pageLimit));
  const [pageNum, setPageNum] = React.useState(1);

  const _choicesFilter = (value) => {
    return choices.filter(choice => choice.label.toLowerCase().startsWith(value.toLowerCase()));
  }

  const _onInputChange = (event) => {
    const filteredChoices = _choicesFilter(event.target.value);
    setDisplayChoices(filteredChoices);
    setPageArr(filteredChoices.slice(0, filteredChoices.length < pageLimit ? filteredChoices.length : pageLimit));
    setPageTotal(Math.ceil(filteredChoices.length/pageLimit));
    setPageNum(1);

    // scroll to top
    resultBox.current.scroll(0, 0);
  }

  const _onSelect = (event, choiceSelected) => {
    event.preventDefault();

    const newPreSaveChoices = choices.map(choice => (choice.label === choiceSelected.label ? { ...choice, checked: !choice.checked } : choice));
    const newDisplayChoices = displayChoices.map(choice => (choice.label === choiceSelected.label ? { ...choice, checked: !choice.checked } : choice));
    handleChoicesOnChange(newPreSaveChoices);
    setDisplayChoices(newDisplayChoices);
    
    // check if all choices are unchecked
    handleAllUncheckedUpdate(newPreSaveChoices.every(choice => !choice.checked));

    // refocus input
    inputBox.current.select();
  }

  const _onSelectDisplayAll = (event) => {
    const allChecked = displayChoices.every(choice => choice.checked); // if all choices are checked, uncheck
    const newPreSaveChoices = choices.map(choice => (displayChoices.findIndex(c => c.label === choice.label) === -1 ? choice : {  ...choice, checked: !allChecked }));
    const newDisplayChoices = displayChoices.map(choice => ({ ...choice, checked: !allChecked }));
    handleChoicesOnChange(newPreSaveChoices);
    setDisplayChoices(newDisplayChoices);

    // check if all choices are unchecked
    handleAllUncheckedUpdate(allChecked);

    // refocus input
    inputBox.current.select();
  }

  const _showSelected = (event) => {
    event.preventDefault();

    const checkedChoices = choices.filter(choice => choice.checked);
    setDisplayChoices(checkedChoices);
    setPageArr(checkedChoices.slice(0, checkedChoices.length < pageLimit ? checkedChoices.length : pageLimit));
    setPageTotal(Math.ceil(checkedChoices.length/pageLimit));
    setPageNum(1);

    // refocus input
    inputBox.current.select();
  }

  const _showDeselected = (event) => {
    event.preventDefault();

    const uncheckedChoices = choices.filter(choice => !choice.checked);
    setDisplayChoices(uncheckedChoices);
    setPageArr(uncheckedChoices.slice(0, uncheckedChoices.length < pageLimit ? uncheckedChoices.length : pageLimit));
    setPageTotal(Math.ceil(uncheckedChoices.length/pageLimit));
    setPageNum(1);

    // refocus input
    inputBox.current.select();
  }

  const _showAll = (event) => {
    event.preventDefault();

    setDisplayChoices(choices);
    setPageArr(choices.slice(0, choices.length < pageLimit ? choices.length : pageLimit));
    setPageTotal(Math.ceil(choices.length/pageLimit));
    setPageNum(1);

    // refocus and empty input
    // scroll to top
    inputBox.current.select();
    inputBox.current.value = "";
    resultBox.current.scroll(0, 0);
  }

  const _nextPage = () => {
    const isNextPageLast = pageNum === pageTotal - 1;
    setPageArr(displayChoices.slice(pageNum*pageLimit, isNextPageLast ? displayChoices.length : (pageNum + 1)*pageLimit));
    setPageNum(pageNum + 1);

    // refocus input
    // scroll to top
    inputBox.current.select();
    resultBox.current.scroll(0, 0);
  }

  const _prevPage = () => {
    setPageArr(displayChoices.slice((pageNum - 2)*pageLimit, (pageNum - 1)*pageLimit));
    setPageNum(pageNum - 1);

    // refocus input
    // scroll to top
    inputBox.current.select();
    resultBox.current.scroll(0, 0);
  }

  return (
    <Root>
      <StyledTextField
        autoFocus
        inputRef={inputBox}
        placeholder={inputPlaceholder}
        fullWidth
        onChange={_onInputChange}
        variant='standard'
      />
      <ShowOptionsContainer container>
        <Grid item xs={12}>
          <KeyText>
            Show:&nbsp;
          </KeyText>
          <CustomButton
            disableRipple
            onMouseDown={event => _showSelected(event)}
          >
            <ShowOptionLabel>
              selected
            </ShowOptionLabel>
          </CustomButton>
          <KeyText>
            &nbsp;/&nbsp;
          </KeyText>
          <CustomButton
            disableRipple
            onMouseDown={event => _showDeselected(event)}
          >
            <ShowOptionLabel>
              deselected
            </ShowOptionLabel>
          </CustomButton>
          <KeyText>
            &nbsp;/&nbsp;
          </KeyText>
          <CustomButton
            disableRipple
            onMouseDown={event => _showAll(event)}
          >
            <ShowOptionLabel>
              all
            </ShowOptionLabel>
          </CustomButton>
        </Grid>
      </ShowOptionsContainer>
      <TableContainer ref={resultBox}>
        <Table>
          <TableBody>
            <SummaryRow role="checkbox">
              <SummaryCell component="th" scope="row" size="small">
                {displayChoices.length + " result" + (displayChoices.length !== 1 ? "s, " : ", ")}
                {displayChoices.length && displayChoices.every(choice => choice.checked) ? "all" : (displayChoices.some(choice => choice.checked) ? displayChoices.filter(choice => choice.checked).length : "none")} selected
              </SummaryCell>
              <TableCell padding="checkbox">
                <SummaryCheckBox
                  size='small'
                  indeterminateIcon={<CustomIndeterminateIcon />}
                  indeterminate={!displayChoices.every(choice => !choice.checked) && !displayChoices.every(choice => choice.checked)}
                  checked={displayChoices.every(choice => choice.checked)}
                  onChange={event => _onSelectDisplayAll(event)}
                />
              </TableCell>
            </SummaryRow>
            {choices.filter(choice => pageArr.findIndex(c => c.label === choice.label) !== -1).map((choice, index) => (
              <StyledTableRow
                onMouseDown={event => _onSelect(event, choice)}
                role="checkbox"
                key={choice.order}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  size="small"
                  style={{
                    fontWeight: choice.checked ? 700 : 300,
                    borderBottomWidth: index + 1 === pageArr.length && index >= 5 ? 0 : 1,
                  }}
                >
                  {choice.label}
                </StyledTableCell>
                <TableCell
                  padding="checkbox"
                  style={{
                    borderBottomWidth: index + 1 === pageArr.length && index >= 5 ? 0 : 1,
                  }}
                >
                  <TableCheckBox
                    color="primary"
                    size='small'
                    checked={choice.checked}
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <HelperText>{helperText}</HelperText>
      <PageContainer>
        <PageButton
          size="small"
          color="primary"
          disabled={pageTotal === 1 || pageNum === 1 || !displayChoices.length}
          onClick={event => _prevPage(event)}
        >
          <NavigateBeforeIcon/>
        </PageButton>
        <PageButton
          size="small"
          color="primary"
          disabled={pageNum === pageTotal || !displayChoices.length}
          onClick={event => _nextPage(event)}
        >
          <NavigateNextIcon/>
        </PageButton>
        <PageNumbers>
          {((pageNum - 1)*pageLimit) + 1}-{pageNum === pageTotal ? displayChoices.length : pageNum*pageLimit}
          &nbsp;of {displayChoices.length}
        </PageNumbers>
      </PageContainer>
    </Root>
  )
};

export default SearchSelect;