import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";

export default function RecentUpdateContent({ data }) {
  let color =
    data.type === "UPCOMING"
      ? "#FFA05C"
      : data.type === "BUG FIX"
      ? "#ED6A5A"
      : data.type === "NEW FEATURE"
      ? "#00a621"
      : data.type === "ENHANCEMENT"
      ? "#419DFF"
      : "black";
  return (
    <div>
      <Accordion
        disabled={!data.canExpand}
        style={{ marginBottom: "10px", boxShadow: "0 2px 6px rgba(0,0,0,0.2" }}
      >
        <AccordionSummary
          expandIcon={data.canExpand ? <ExpandMoreIcon /> : null}
          aria-controls="panel1-content"
          id={data.id}
          style={{
            backgroundColor: "#fff",
            color: "#000",
            opacity: 1,
          }}
        >
          <div
            className=""
            style={{
              textAlign: "right",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 16,
                fontWeight: "600",
                fontFamily: "PTRootUIWebBold,sans-serif",
                color: "#5a5a5a",
              }}
            >
              {data.date}
            </div>
            <div
              style={{
                fontSize: 16,
                fontWeight: "400",
                fontFamily: "PTRootUIWebBold,sans-serif",
                color: `${color}`,
              }}
            >
              {data.type}
            </div>
          </div>
          <Divider orientation="vertical" flexItem style={{ marginLeft: 12, marginRight: 12 }} />
          <div
            style={{
              margin: "auto",
              marginLeft: "0",
              fontSize: 15,
              fontWeight: 700,
              color: "#315a5e",
              lineHeight: 1.2
            }}
          >
            {data.description}
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ color: "#5a5a5a" }}>
          {data.content}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
