import React, { useEffect } from "react";
import * as d3 from "d3";

import params from './params';

function TimeCurve(props) {
    const svgRef = React.useRef(null);
    const { name, data, colorMap, selectedRef } = props;
    const { handleBarOnSelect, handleSetBarInfo } = props;
    
    const {
        width,
        height,
        padding,
        margin,
        leftMargins,
        yAxisValueBreakpoint,
        axisColor,
        opacity,
        hoverOpacity,
        xAxisFontSize,
        yAxisFontSize,
        fontWeight,
        fadeInOutDuration,
        xAxisTextHorizontalOffset,
        xAxisTextVerticalOffset
    } = params;

    useEffect(() => {
        drawChart();
        const svg = svgRef.current;

        return (() => {
            d3.select(svg).selectAll('*').remove();
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const _getDecNum = value => Math.floor(Math.log10(value));

    const drawChart = () => {

        // Prepare data by parsing date-string into Date object
        const aData = data.map(bin => {
            const date = new Date(bin.label);
            date.setHours(0);
            return { ...bin, date: date, divs: bin.divs.map(div => ({ ...div, percent: Math.round(div.num*10000/bin.num)/100 })) };
        });

        // Create svg
        const svg = d3
            .select(svgRef.current)
            .attr('viewBox', `0, 0, ${width + margin.right}, ${height - padding}`)

        // Get max height
        const numMax = aData.reduce((currMax, bin) => bin.num > currMax ? bin.num : currMax, 0);

        // Set y-axis
        const y = d3.scaleLinear()
            .range([height - padding - margin.bottom, margin.top])
            .domain([0, numMax]).nice();

        // Extract maximum value of y-axis about nice() and set left-margin accordingly to accommodate different lengths
        const leftMarginIndex = y.domain()[1] >= yAxisValueBreakpoint ? leftMargins.length - 1 : _getDecNum(y.domain()[1]);
        margin.left = leftMargins[leftMarginIndex];

        // Set x-axes
        const x = d3.scaleTime()
            .domain(
                // if only one date, give extra padding
                [new Date(aData[0].label).setDate(aData[0].date.getDate() - (aData.length === 1 ? 3 : 1)), new Date(aData[aData.length-1].label).setDate(aData[aData.length-1].date.getDate() + (aData.length === 1 ? 3 : 1))]
            )
            .range([margin.left, width]);

        const x2 = d3.scaleTime()
            .domain(x.domain())
            .range([margin.left, width]);

        // Function for determining width of each bar
        const bandwidthCalc = () => {
            const fac = 0.8264e+8; //set arbitrarily
            const newBarWidth = (x.range()[1] - x.range()[0])*fac/(x.domain()[1].getTime() - x.domain()[0].getTime());
            return newBarWidth;
        }

        // Set extent and zoom
        const extent = [[margin.left, margin.top], [width + margin.right, height - padding]];

        const zoomed = (event) => {
            const t = event.transform;
            
            x.range([margin.left, width].map(d => t.applyX(d)));
            x.domain(t.rescaleX(x2).domain());

            svg.selectAll(`.${name}-bars`)
                .attr('x', d => x(d.data.date) - bandwidthCalc()/2)
                .attr('width', bandwidthCalc());

            svg.select(`.${name}-x-axis`)
                .call(xAxis);

            svg.select(`.${name}-x-axis`)
                .selectAll('text')
                .attr('font-family', 'PTRootUI')
                .attr('font-size', xAxisFontSize)
                .attr('font-weight', fontWeight)
                .attr('fill', axisColor)
                .attr('transform', `rotate(-90) translate(${xAxisTextHorizontalOffset},${xAxisTextVerticalOffset})`);
        };

        const zoom = d3.zoom()
            .scaleExtent([1, 3])
            .translateExtent(extent)
            .extent(extent)
            .on('zoom', event => zoomed(event));

        svg.append('g')
            .selectAll('date-bin')
            .data(aData)
            .join('g')
            .selectAll('stacked-bar')
            .data(d => d.divs.map(div => ({ ...div, data: { ...d } })))
            .join('rect')
            .attr('class', `${name}-bars`)
            .attr('cursor', 'pointer')
            .attr('id', d => `${name}-bar-${d.data.label}`)
            .attr('x', d => x(d.data.date) - bandwidthCalc()/2)
            .attr('y', (d, i) => {
                const prevHeight = d.data.divs.reduce((acc, div, index) => acc + (index <= i ? div.num : 0), 0);
                return y(prevHeight);
            })
            .attr('height', d => y(0) - y(d.num))
            .attr('width', bandwidthCalc())
            .attr('fill', d => colorMap[d.id].color)
            .attr('opacity', opacity)
            .on('click', (event, d) => click(event, d));

        const click = (event, d) => {
            event.stopPropagation();
            if (selectedRef.current === null) {
                handleBarOnSelect(d);
                d3.selectAll(`.${name}-bars`)
                    .each(function(node) {
                        const active = node.data.label === d.data.label;
                        active && handleSetBarInfo(`${node.data.label}, n=${node.data.num}`);
                        d3.selectAll(`#${name}-bar-${node.data.label}`)
                            .transition()
                            .duration(fadeInOutDuration)
                            .style('fill-opacity', active ? 1 : hoverOpacity);
                    })
            } else { // switch from one selected bar to another
                d3.selectAll(`.${name}-bars`)
                    .each(function(node) {
                        const active = node.data.label === d.data.label;
                        if (active) {
                            handleSetBarInfo(`${node.data.label}, n=${node.data.num}`);
                            handleBarOnSelect(node);
                        }
                        d3.selectAll(`#${name}-bar-${node.data.label}`)
                            .transition()
                            .duration(fadeInOutDuration)
                            .style('fill-opacity', active ? 1 : hoverOpacity);
                    })
            }
        };

        d3.select(`#${name}-freq-container`).on('click', () => {
            handleSetBarInfo('');
            handleBarOnSelect(null);
            d3
                .selectAll(`.${name}-bars`)
                .transition()
                .duration(fadeInOutDuration)
                .style('fill-opacity', 1);
        });

        const formatWeek = d3.timeFormat('%d/%m'),
            formatMonth = d3.timeFormat('%y/%m'),
            formatYear = d3.timeFormat('%y/%m');

        // const formatMillisecond = d3.timeFormat('.%L'),
        //     formatSecond = d3.timeFormat(':%S'),
        //     formatMinute = d3.timeFormat('%I:%M'),
        //     formatHour = d3.timeFormat(''),
        //     // formatDay = d3.timeFormat("%a %d"),
        //     formatWeek = d3.timeFormat('%d/%m'),
        //     formatMonth = d3.timeFormat('%y/%m'),
        //     formatYear = d3.timeFormat('%y/%m');

            
        // const multiFormat = (date) => {
        //     return (d3.timeSecond(date) < date ? formatMillisecond
        //         : d3.timeMinute(date) < date ? formatSecond
        //         : d3.timeHour(date) < date ? formatMinute
        //         : d3.timeDay(date) < date ? formatHour
        //         // : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
        //         : d3.timeMonth(date) < date ? formatWeek
        //         : d3.timeYear(date) < date ? formatMonth
        //         : formatYear)(date);
        // };
        const multiFormat = (date) => {
            return (d3.timeMonth(date) < date ? formatWeek
                : d3.timeYear(date) < date ? formatMonth
                : formatYear)(date);
        };

        
        const xAxis = g => g
            .attr('transform', `translate(0,${height - padding - margin.bottom})`)
            .call(d3.axisBottom(x).tickFormat(multiFormat).tickValues(x.ticks()));
            // .call(d3.axisBottom(x).tickFormat(multiFormat).tickValues(x.ticks().filter((tick, index) => {
            //     // only check if number of dates is greater than 5 and not first tick
            //     if (x.ticks().length > 5 && index > 0) {
            //         var date = new Date(tick.getTime()),
            //             month = date.getMonth();
            //         // check if first day of month
            //         date.setDate(date.getDate() - 1);
            //         if (date.getMonth() !== month) {
            //             var date0 = new Date(x.ticks()[index >= 2 ? index+1 : index-1]),
            //                 date1 = new Date(x.ticks()[index >= 2 ? index-1 : index+1]),
            //                 date2 = new Date(x.ticks()[index >= 2 ? index-2 : index+2]),
            //                 diffDays = Math.ceil(Math.abs(date1 - date2)/(1000 * 60 * 60 * 24));
            //             // if min-diffDays is different
            //             return (Math.ceil(Math.min(Math.abs(tick - date1), Math.abs(tick - date0))/(1000 * 60 * 60 * 24)) === diffDays)
            //         } else {
            //             return true
            //         }
            //     } else {
            //         return true
            //     }
            // })));

        const yAxis = g => g
            .attr('transform', `translate(${margin.left},0)`)
            .call(d3.axisLeft(y).tickValues(y.ticks().filter(tick => Number.isInteger(tick))).tickFormat(d3.format(numMax >= 10000 ? `.1e` : 'd')))
            .call(g => g.select('.domain'));

        svg.append('g')
            .attr('class', `${name}-x-axis`)
            .call(xAxis);

        svg.append('g')
            .attr('class', `${name}-y-axis`)
            .call(yAxis);

        d3
            .select(`.${name}-x-axis`)
            .selectAll('text')
            .attr('font-family', 'PTRootUI')
            .attr('font-size', xAxisFontSize)
            .attr('font-weight', fontWeight)
            .attr('fill', axisColor)
            .attr('transform', `rotate(-90) translate(${xAxisTextHorizontalOffset},${xAxisTextVerticalOffset})`)
            .selectAll('*')
            .attr('color', axisColor);

        d3
            .select(`.${name}-y-axis`)
            .selectAll('text')
            .attr('font-family', 'PTRootUI')
            .attr('font-size', yAxisFontSize)
            .attr('font-weight', fontWeight)
            .attr('fill', axisColor)
            .selectAll('*')
            .attr('color', axisColor);

        // const transform = d3.zoomIdentity.scale(1/d3.zoomIdentity.k).translate(-d3.zoomIdentity.x, -d3.zoomIdentity.y);
        // svg.call(zoom.transform, transform)
        svg.call(zoom);

    }

    return (
        <div id={`${name}-freq-container`} style={{ width: width - 2*padding, height: height - 2*padding, padding: padding, paddingBottom: 0 }}>
            <svg ref={svgRef} />
        </div>
    );
}

export default TimeCurve;