import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import { CancelToken } from 'axios';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import helpers from '../../../helpers';
import allActions from '../../../redux';

const MAX_ANC_PATH_LENGTH = 7;

const Root = styled('div')(({ theme, active, darkModeActive }) => ({
    position: 'absolute',
    padding: '5px 15px 8px 15px',
    textAlign: "left",
    left: 0,
    backgroundColor: helpers.hexToRgb(theme.palette.background.main, 0.5),
    borderRadius: '0px 0px 3px 0px',
    borderBottomWidth: 2,
    borderRightWidth: 2,
    borderWidth: '0px 1px 1px 0px',
    borderColor: darkModeActive ? theme.palette.background.light : theme.palette.background.dark,
    borderStyle: 'solid',
    zIndex: 1,
    maxWidth: 600,
    display: active ? null : 'none',
}));

const Title = styled(Typography)(({ theme, darkModeActive }) => ({
    display: 'inline-block',
    height: 0,
    fontSize: 13,
    fontWeight: 700,
    marginRight: 10,
    color: darkModeActive ? theme.palette.text.main.light : theme.palette.text.main.dark,
    cursor: "auto",
    userSelect: "none",
}));

const StyledButton = styled(Button)({
    minWidth: 0,
    padding: 0,
    height: 0,
    verticalAlign: 'baseline',
    "&:hover": {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
    },
});

const Label = styled('span')(({ theme, darkModeActive }) => ({
    fontSize: 11,
    fontWeight: 500,
    display: "inline",
    textTransform: 'none',
    color: darkModeActive ? theme.palette.text.main.light : theme.palette.text.main.dark,
    "&:hover": {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        color: darkModeActive ? theme.palette.text.main.light : theme.palette.text.main.dark,
    },
}));

const SequenceText = styled(Typography)(({ theme, darkModeActive }) => ({
    display: 'inline-block',
    fontSize: 11,
    fontWeight: 700,
    color: darkModeActive ? theme.palette.text.main.light : theme.palette.text.main.dark,
    cursor: "auto",
    userSelect: "none"
}));

const Text = styled(Typography)(({ theme, darkModeActive }) => ({
    display: 'inline-block',
    fontSize: 11,
    fontWeight: 700,
    color: darkModeActive ? theme.palette.text.main.light : theme.palette.text.main.dark,
}));

const NodeSelectionInfo = styled(Typography)(({ theme }) => ({
    fontSize: 11,
    color: '#c70000',
}));  

function SubtreeInfo(props) {
    const dispatch = useDispatch();

    const { active, filtersLoading, error } = props;

    const { apiURL } = useSelector(state => state.staticReducer);
    const { staticSubtreeData } = useSelector(state => state.filtersReducer);
    const { selectedSubtreeId, darkModeActive } = useSelector(state => state.visualReducer);
    const axiosRef = React.useRef(null);

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    // process staticSubtreeData.ancPath to display only MAX_ANC_PATH_LENGTH elements
    var subtrees_hidden = false;
    if (staticSubtreeData && staticSubtreeData.ancPath.length > MAX_ANC_PATH_LENGTH) {
        subtrees_hidden = true;
        const firstHalf = staticSubtreeData.ancPath.slice(0, Math.ceil(MAX_ANC_PATH_LENGTH / 2));
        const secondHalf = staticSubtreeData.ancPath.slice(-Math.floor(MAX_ANC_PATH_LENGTH / 2));
        staticSubtreeData.ancPath = firstHalf.concat([[-1, null]], secondHalf);
    }

    const _onClickSubtree = (subtreeId) => {
        dispatch(allActions.filtersActions.resetStaticSubtreeData());
        dispatch(allActions.filtersActions.resetFilteredSubtreeData());
        dispatch(allActions.filtersActions.resetStaticSubtreeGraph());
        dispatch(allActions.filtersActions.resetFilteredSubtreeGraph());
        dispatch(allActions.filtersActions.resetStaticNodeData());
        dispatch(allActions.filtersActions.resetFilteredNodeData());
        dispatch(allActions.visualActions.setSelectedNodeId());
        dispatch(allActions.filtersActions.resetFilteringParamsOnSubtreeDeselect());
        dispatch(allActions.visualActions.setSelectedSubtreeId(subtreeId));
        dispatch(allActions.visualActions.setGoToNodeID(subtreeId));
        dispatch(allActions.filtersActions.loadStaticSubtreeData(subtreeId, newCancelToken, apiURL));
    };

    return (
        <Root active={active} darkModeActive={darkModeActive}>
            <Title darkModeActive={darkModeActive}>
                path-to-root{subtrees_hidden ? `(only up to ${MAX_ANC_PATH_LENGTH} subtrees are shown)` : ''}:
            </Title>
            <SequenceText darkModeActive={darkModeActive}>
                {
                    staticSubtreeData ?
                    <Fragment>
                        {
                            staticSubtreeData.ancPath.map((subtree) => (
                                <Fragment key={subtree[0]}>
                                    <StyledButton
                                        disabled={filtersLoading || error || (selectedSubtreeId && subtree[0] === selectedSubtreeId) || subtree[1] === null}
                                        disableRipple={true}
                                        onClick={(event) => _onClickSubtree(subtree[0])}
                                    >
                                        <Label darkModeActive={darkModeActive}>
                                            {(subtree[0] === selectedSubtreeId ? <span style={{ fontWeight: 900 }}>{subtree[1]}</span> : subtree[1]) ?? '...'}
                                        </Label>
                                    </StyledButton>
                                    &nbsp;<Text darkModeActive={darkModeActive}>/</Text>&nbsp;
                                </Fragment>
                            ))
                        }
                    </Fragment>
                    :
                    'N/A'
                }
            </SequenceText>
            <NodeSelectionInfo>
                <span style={{ fontStyle: 'italic', fontWeight: 1000, backgroundColor: 'white' }}>
                &nbsp;<span style={{ textDecoration: 'underline' }}>Shift + Click</span>&nbsp;to select a node&nbsp;</span>
            </NodeSelectionInfo>
        </Root>
    )
}

export default SubtreeInfo;