import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken } from 'axios';

import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import Footer from './footer';
import helpers from './helpers';

import allActions from './redux';

const RootContainer = styled('div')({
  display: 'grid',
  margin: '0 auto',
  maxHeight: 800,
  width: '100vw',
  maxWidth: 1400,
  boxShadow: '0px 0px 6px 2px rgb(0, 0, 0, 0.2)',
  borderRadius: 2,
  backgroundColor: theme => theme.palette.background.lighter,
  gridTemplateRows: 'calc(max(500px, min(100vh, 800px) - 35px)) 35px',
});

const BodyContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto 470px',
});

function App(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const axiosRef = React.useRef(null);

  const { mutsDisabled, tiparsDisabled, globalDataFilteringDisabled, globalDataFilteringDisabledForced, apiURL } = props;
  const { metaLoading, metaError, staticGlobalDataLoading, staticGlobalDataError } = useSelector(state => state.staticReducer);
  const { filteredGlobalDataLoading, filteredGlobalDataError, staticSubtreeDataLoading, staticSubtreeDataError, filteredSubtreeDataLoading, filteredSubtreeDataError, staticSubtreeGraphLoading, staticSubtreeGraphError, filteredSubtreeGraphLoading, filteredSubtreeGraphError, staticNodeDataLoading, staticNodeDataError, filteredNodeDataLoading, filteredNodeDataError } = useSelector(state => state.filtersReducer);
  
  const initialLoading = metaLoading || staticGlobalDataLoading;
  const fatalError = metaError || staticGlobalDataError;
  const filteringError = filteredGlobalDataError || staticSubtreeDataError || filteredSubtreeDataError || staticSubtreeGraphError || filteredSubtreeGraphError || staticNodeDataError || filteredNodeDataError;
  const filtersLoading = filteredGlobalDataLoading || staticSubtreeDataLoading || filteredSubtreeDataLoading || staticSubtreeGraphLoading || filteredSubtreeGraphLoading || staticNodeDataLoading || filteredNodeDataLoading;
  const newCancelToken = () => {
    axiosRef.current = CancelToken.source();
    return axiosRef.current.token;
  };

  useEffect(() => {
    dispatch(allActions.staticActions.setApiURL(apiURL));
    dispatch(allActions.filtersActions.toggleGlobalFilteredDataAggregation(!globalDataFilteringDisabled));
    dispatch(allActions.staticActions.loadMeta(newCancelToken, apiURL));
    dispatch(allActions.staticActions.loadGlobalData(newCancelToken, apiURL));
    return (() => {
      // cancel previous axios request if exists
      axiosRef.current && axiosRef.current.cancel();
    });
  }, [dispatch, apiURL, globalDataFilteringDisabled]);

  useEffect(() => {
    document.documentElement.style.height = '100%';
    document.documentElement.style.weight = '100%';
    document.body.style.background = `linear-gradient(0deg, ${helpers.hexToRgb(theme.palette.background.main, 0.2)} 80%, ${helpers.hexToRgb(theme.palette.background.main, 0.8)} 20%)`;

    return (() => {
        document.documentElement.style.height = '';
        document.documentElement.style.weight = '';
        document.body.style.background = '';

        // reset store to default
        dispatch(allActions.filtersActions.resetDefault());
        dispatch(allActions.tiparsActions.resetDefault());
        dispatch(allActions.staticActions.resetDefault());
        dispatch(allActions.visualActions.resetDefault());
    });
  }, [dispatch, theme]);

  return (
    <RootContainer>
        <BodyContainer>
            <LeftPanel fatalError={fatalError} filteringError={filteringError} initialLoading={initialLoading} filtersLoading={filtersLoading} tiparsDisabled={tiparsDisabled} />
            <RightPanel fatalError={fatalError} filteringError={filteringError} initialLoading={initialLoading} filtersLoading={filtersLoading} mutsDisabled={mutsDisabled} tiparsDisabled={tiparsDisabled} globalDataFilteringDisabledForced={globalDataFilteringDisabledForced} />
        </BodyContainer>
        <Footer fatalError={fatalError} filteringError={filteringError} initialLoading={initialLoading} filtersLoading={filtersLoading} globalDataFilteringDisabled={globalDataFilteringDisabled} apiURL={apiURL} />
    </RootContainer>
  );
}

export default App;
