import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import { CancelToken } from 'axios';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import FormatLineSpacingRoundedIcon from '@mui/icons-material/FormatLineSpacingRounded';
import Tooltip from '@mui/material/Tooltip';

import GhostMoveDialog from './ghostMoveDialog';
import helpers from '../../../helpers';
import allActions from '../../../redux';

const StyledMenu = styled(Menu)(({ theme }) => ({
    paper: {
        marginLeft: 10,
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    root: {
        height: 27,
        color: theme.palette.text.main.normal,
    },
    selected: {
        color: theme.palette.text.title,
    }
}));

const Root = styled(Paper)(({ theme }) => ({
    borderRadius: 2,
    backgroundColor: theme.palette.background.lighter,
    margin: 10,
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    borderBottom: `1px solid ${theme.palette.background.dark}`,
    textAlign: 'right',
    padding: '8px 15px',
    color: '#fff',
    borderRadius: '2px 2px 0px 0px'
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    display: 'inline',
    float: 'left',
    fontSize: 14,
    fontWeight: 700
}));

const InfoTypography = styled(Typography)(({ theme, visible }) => ({
    width: 190,
    float: 'right',
    fontSize: 11,
    fontWeight: 300,
    lineHeight: '10px',
    visibility: visible ? 'visible' : 'hidden',
}));

const ParentIndicatorCell = styled(TableCell)(({ theme, isParent }) => ({
    width: 3,
    padding: 0,
    borderBottom: '1px solid',
    borderColor: theme.palette.background.light,
    backgroundColor: isParent ? 'null' : theme.palette.background.lighter
}));

const TableCellStyled = styled(TableCell)(({ theme, isParent }) => ({
    fontSize: 12,
    fontWeight: 900,
    paddingLeft: 25,
    height: isParent ? 26 : 20,
    color: theme.palette.text.main.normal,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderBottom: '1px solid',
    borderColor: theme.palette.background.light,
    backgroundColor: isParent ? '#dedede' : theme.palette.background.lighter,
}));

const EmptyTableRow = styled(TableRow)(({ theme }) => ({
    height: 48,
    borderBottom: '1px solid',
    borderColor: theme.palette.background.lighter
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    display: 'inline',
    color: '#fff',
    padding: 0,
    marginLeft: 10,
    '&:disabled': {
        color: theme.palette.text.main.dark,
    }
}));

const GoToButton = styled(IconButton)(({ theme, goToGhost }) => ({
    display: 'inline',
    color: goToGhost ? '#D40000' : theme.palette.background.main,
}));

const MenuItemStyled = styled(MenuItem)(({ theme, selected }) => ({
    fontSize: 12,
    fontWeight: selected ? 900 : 500,
}));

const PaginationDiv = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    width: '100%',
    color: '#fff',
    height: 30,
    borderRadius: '0px 0px 2px 2px',
    display: 'flex',
    alignItems: 'center'
}));

const PageButton = styled(IconButton)(({ theme }) => ({
    height: 10,
    width: 10,
    margin: '0px 0px 0px 15px',
    color: '#fff',
    '&:disabled': {
        color: theme.palette.text.main.dark,
    }
}));

const PageText = styled(Typography)({
    color: '#fff',
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'right',
    width: '100%',
    paddingRight: 15
});

const NoDataTypography = styled(Typography)(({ theme }) => ({
    padding: 15,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.main.normal
}));

const MutTooltip = styled(Tooltip)(({ theme }) => ({
    fontSize: 12,
    borderRadius: 3,
    borderColor: '#FFFFFF',
    border: '1px solid',
    backgroundColor: theme.palette.background.main,
    color: '#FFFFFF',
    padding: 8,
    maxWidth: 260,
}));

const namesMap = {
    'label': 'node_name',
    'num': 'num',
    'len': 'branch_len',
};

const MAX_MUTS_NUM = 20;

function NodeInfo(props) {
    const dispatch = useDispatch();
    const axiosRef = React.useRef(null);

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    const { staticData, filteredData, filtersActive, pageLimit } = props;

    const { apiURL } = useSelector(state => state.staticReducer);
    const subtreeMap = useSelector(state => state.staticReducer.meta.maps.subtree);
    const { staticSubtreeGraph, staticSubtreeData, filteredSubtreeData } = useSelector(state => state.filtersReducer);

    const subtreeId = (staticSubtreeData || filteredSubtreeData).id;
    const parentSubtreeId = (staticSubtreeData || filteredSubtreeData).parentId;

    const [pageNum, setPageNum] = React.useState(1);
    const _nextPage = (event) => {
        setPageNum(pageNum + 1);
    };
    const _prevPage = (event) => {
        setPageNum(pageNum - 1);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [sortAttr, setSortAttr] = React.useState('label');
    const [sortReversed, setSortReversed] = React.useState(false);
    const [sortedFilteredNodes, setSortedFilteredNodes] = React.useState([]);
    const _onMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const _onMenuClose = () => {
        setAnchorEl(null);
    };
    const _onSortAttrSelect = (event, attr) => {
        setSortAttr(attr);
        setAnchorEl(null);
    };
    const _onSortReverse = () => {
        setSortReversed(!sortReversed);
    };

    const [ghostMoveTargetNode, setGhostMoveTargetNode] = React.useState(null);
    const [ghostMoveDialogOpen, setGhostMoveDialogOpen] = React.useState(false);
    const _openGhostMoveDialog = (node) => {
        setGhostMoveTargetNode(node);
        setGhostMoveDialogOpen(true);
    };
    const confirmGhostMoveDialog = () => {
        _goToNode(ghostMoveTargetNode);
        setGhostMoveDialogOpen(false);
    };
    const rejectGhostMoveDialog = () => {
        setGhostMoveTargetNode(null);
        setGhostMoveDialogOpen(false);
    };

    const _goToNode = (nodeData) => {
        if (staticData.info.subtree !== subtreeId && nodeData.subtree !== subtreeId) {
            dispatch(allActions.filtersActions.resetStaticSubtreeData());
            dispatch(allActions.filtersActions.resetFilteredSubtreeData());
            dispatch(allActions.filtersActions.resetStaticSubtreeGraph());
            dispatch(allActions.filtersActions.resetFilteredSubtreeGraph());
            dispatch(allActions.visualActions.setSelectedNodeId());
            dispatch(allActions.filtersActions.resetStaticNodeData());
            dispatch(allActions.filtersActions.resetFilteredNodeData());
            dispatch(allActions.filtersActions.resetFilteringParamsOnSubtreeDeselect());
            dispatch(allActions.visualActions.setSelectedSubtreeId(nodeData.subtree));
            dispatch(allActions.filtersActions.loadStaticSubtreeData(nodeData.subtree, newCancelToken, apiURL));
            dispatch(allActions.filtersActions.loadStaticSubtreeGraph(nodeData.subtree, newCancelToken, apiURL));
            filtersActive && dispatch(allActions.filtersActions.loadFilteredSubtreeData(nodeData.subtree, newCancelToken, apiURL)) && dispatch(allActions.filtersActions.loadFilteredSubtreeGraph(nodeData.subtree, newCancelToken, apiURL));
            dispatch(allActions.visualActions.toggleSpotlight(false));
            dispatch(allActions.visualActions.setRenderGoToNodeID(nodeData.id));
        } else {
            dispatch(allActions.visualActions.setGoToNodeID(nodeData.id));
            dispatch(allActions.visualActions.setSelectedNodeId(nodeData.id));
        }
    };

    const _onNodeHover = (id) => {
        dispatch(allActions.visualActions.setHoveredNodeId(id));
    };

    useEffect(() => {
        setPageNum(1);

        var unsortedFilteredNodes = [];
        staticData.children.forEach(child => {
            const addFilteredChildren = filtersActive && filteredData && child.id in filteredData.children;
            (addFilteredChildren || !filtersActive || !filteredData) && unsortedFilteredNodes.push({ id: child.id, label: child.targetName, subtree: child.subtreeId, num: addFilteredChildren ? filteredData.children[child.id] : child.targetNum, len: child.len, isParent: false, muts: child.muts });
        });

        // sort children nodes
        const updatedSortedFilteredNodes = helpers.sortByAttr(unsortedFilteredNodes, sortAttr, sortReversed);

        // add parent node to beginning of array and update
        const parentNode = staticData.info.parentId ? [{ ...staticData, id: staticData.info.parentId, label: staticData.info.parentName, subtree: staticData.info.parentSubtree, num: staticData.info.parentNum, len: staticData.info.parentLen, muts: staticData.info.muts, isParent: true }] : [];
        setSortedFilteredNodes(parentNode.concat(updatedSortedFilteredNodes));

    }, [staticData, filteredData, sortAttr, sortReversed, parentSubtreeId, subtreeId, subtreeMap, filtersActive]);

    return(
        <Root elevation={1}>
            <Grid container spacing={0}>
                <TitleContainer xs={12}>
                    <TitleTypography>
                        parent/children node(s)
                        <IconButtonStyled
                            size='small'
                            disableRipple={true}
                            disabled={!sortedFilteredNodes.length}
                            onClick={_onMenuOpen}
                        >
                            <SortRoundedIcon fontSize='small' style={{ fontSize: 18 }} />
                        </IconButtonStyled>
                        <StyledMenu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={_onMenuClose}
                        >
                            {
                                Object.entries(namesMap).map(([k, v]) => (
                                    <StyledMenuItem
                                        color='primary'
                                        disabled={!sortedFilteredNodes.length}
                                        disableRipple={true}
                                        key={v}
                                        selected={k === sortAttr}
                                        onClick={(event) => _onSortAttrSelect(event, k)}
                                    >
                                        <MenuItemStyled selected={k === sortAttr}>
                                            {v}
                                        </MenuItemStyled>
                                    </StyledMenuItem>
                                ))
                            }
                        </StyledMenu>
                        <IconButtonStyled
                            size='small'
                            disableRipple={true}
                            disabled={!sortedFilteredNodes.length}
                            onClick={_onSortReverse}
                        >
                            <FormatLineSpacingRoundedIcon fontSize='small' style={{ fontSize: 18 }} />
                        </IconButtonStyled>
                    </TitleTypography>
                    <InfoTypography visible={sortedFilteredNodes.length}>
                        parent node is indicated by a coloured bar on the left
                    </InfoTypography>
            </TitleContainer>
                {
                    sortedFilteredNodes.length ?
                    <Fragment>
                        <TableContainer>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <ParentIndicatorCell />
                                        <TableCellStyled style={{ minWidth: 70 }}>name</TableCellStyled>
                                        <TableCellStyled style={{ minWidth: 40 }}>
                                            num{filtersActive ? ' (filtered)' : ''}
                                        </TableCellStyled>
                                        <TableCellStyled>branch_len</TableCellStyled>
                                        <TableCellStyled style={{ width: 20 }}></TableCellStyled>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        sortedFilteredNodes.slice((pageNum - 1)*pageLimit, Math.min(sortedFilteredNodes.length, pageNum*pageLimit)).map(row => (
                                            <MutTooltip title={row.muts.length > 0 ? (row.muts.slice(0, MAX_MUTS_NUM).join(', ') + (row.muts.length > MAX_MUTS_NUM ? ` (${row.muts.length - MAX_MUTS_NUM} more)` : '')) : 'N/A'} placement="bottom">
                                                <TableRow
                                                    key={row.id}
                                                    onMouseEnter={() => _onNodeHover(row.id)}
                                                    onMouseLeave={() => _onNodeHover(null)}
                                                    style={{ border: 0 }}
                                                >
                                                    <ParentIndicatorCell isParent={row.isParent}></ParentIndicatorCell>
                                                    <TableCellStyled isParent={row.isParent} style={{ maxWidth: 130 }}>{row.label}</TableCellStyled>
                                                    <TableCellStyled isParent={row.isParent}>{row.num}</TableCellStyled>
                                                    <TableCellStyled isParent={row.isParent}>{Number.parseFloat(row.len).toExponential(2)}</TableCellStyled>
                                                    <TableCellStyled isParent={row.isParent} style={{ width: 20 }}>
                                                        <GoToButton
                                                            size='small'
                                                            disableRipple={true}
                                                            goToGhost={!(row.id in staticSubtreeGraph.nodes)}
                                                            onMouseDown={(staticData.info.subtree !== subtreeId && row.subtree !== subtreeId) ? () => _openGhostMoveDialog(row) : (() => _goToNode(row))}
                                                        >
                                                            <PlayCircleOutlineRoundedIcon fontSize='small' />
                                                        </GoToButton>
                                                    </TableCellStyled>
                                                </TableRow>
                                            </MutTooltip>
                                        ))
                                    }
                                    {
                                        (sortedFilteredNodes.length - (pageNum - 1)*pageLimit < pageLimit && pageNum*pageLimit - sortedFilteredNodes.length) &&
                                        Array(pageNum*pageLimit - sortedFilteredNodes.length).fill().map((row, index) => (
                                            <EmptyTableRow key={index} />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PaginationDiv>
                            <PageButton
                                size='small'
                                color='primary'
                                disabled={sortedFilteredNodes.length <= pageLimit || pageNum === 1}
                                onClick={_prevPage}
                            >
                                <ArrowLeftIcon />
                            </PageButton>
                            <PageButton
                                size='small'
                                color='primary'
                                disabled={pageNum*pageLimit >= sortedFilteredNodes.length}
                                onClick={_nextPage}
                            >
                                <ArrowRightIcon/>
                            </PageButton>
                            <PageText>
                                page {pageNum} of {Math.ceil(sortedFilteredNodes.length/pageLimit)}
                            </PageText>
                        </PaginationDiv>
                    </Fragment>
                    :
                    <NoDataTypography>
                        No data to display.
                    </NoDataTypography>
                }
            </Grid>
            {
                ghostMoveTargetNode &&
                <GhostMoveDialog
                    open={ghostMoveDialogOpen}
                    title='Are you sure?'
                    content={`Confirming this action will take you to anoher subtree (${staticData.info.subtreeName}), are you sure you want to do that?`}
                    confirmHandler={confirmGhostMoveDialog}
                    rejectHandler={rejectGhostMoveDialog}
                />
            }
        </Root>
    )
}

export default NodeInfo;