import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import * as d3 from 'd3';

import params from './params';
import helpers from '../../../helpers';

const map_xy = (in_x, in_y, treeBounds, width, height, padding, invert=false) => {
    const xFac = (height*(1 - 2*padding))/treeBounds.x,
        yFac = (width*(1 - 2*padding))/treeBounds.y;

    var out_x = 0,
        out_y = 0;
    if (invert) {
        out_x = ((in_x - height*padding)/xFac) - treeBounds.x/2;
        out_y = ((in_y - width*padding)/yFac) - treeBounds.y/2 - treeBounds.offset;
    } else {
        out_x = (in_x + treeBounds.x/2)*xFac + height*padding;
        out_y = (in_y + treeBounds.offset + treeBounds.y/2)*yFac + width*padding;
    }

    return([out_x, out_y]);
};

const Root = styled('div')(({ theme, active, darkModeActive, width, height }) => ({
    position: 'absolute',
    right: 0,
    backgroundColor: helpers.hexToRgb(theme.palette.background.main, 0.3),
    borderRadius: '0px 0px 0px 3px',
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderWidth: '0px 0px 1px 1px',
    borderColor: darkModeActive ? theme.palette.background.light : theme.palette.background.dark,
    borderStyle: 'solid',
    zIndex: 1,
    visibility: active ? 'visible' : 'hidden',
    width: width,
    height: height
}));

function ExploreMap(props) {
    const topCanvasRef = React.useRef(null);
    const baseCanvasRef = React.useRef(null);

    const { visualMode, tiparsOutputPlacements, tiparsOutputSubtrees, treeData, treeBounds, filtersActive, filteredData, selectedNode, viewCentre, viewBoxLims, handleViewCentreOnChange } = props;

    const { exploreMapActive, darkModeActive } = useSelector(state => state.visualReducer);
    const { tiparsVisible, tiparsPlacementSelected } = useSelector(state => state.tiparsReducer);

    const tiparsVisibleRef = React.useRef(tiparsVisible);
    const tiparsPlacementSelectedRef = React.useRef(tiparsPlacementSelected);
    const tiparsOutputPlacementsRef = React.useRef(tiparsOutputPlacements);
    const tiparsOutputSubtreesRef = React.useRef(tiparsOutputSubtrees);
    const filtersActiveRef = React.useRef(filtersActive);
    const filteredDataRef = React.useRef(filteredData);
    const selectedNodeRef = React.useRef(selectedNode);
    const darkModeActiveRef = React.useRef(darkModeActive);
    const viewBoxXY = React.useRef({ x: 50, y: 30 });
    const viewBoxWidth = React.useRef(60);
    const viewBoxHeight = React.useRef(40);
    const active = exploreMapActive;

    // extract parameters from props
    const {
        width,
        height,
        padding,
        viewBoxParams,
        treeParams
    } = params;

    useEffect(() => {
        tiparsVisibleRef.current = tiparsVisible;
        drawTree();
    }, [tiparsVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        tiparsPlacementSelectedRef.current = tiparsPlacementSelected;
        drawTree();
    }, [tiparsPlacementSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        tiparsOutputPlacementsRef.current = tiparsOutputPlacements;
        drawTree();
    }, [tiparsOutputPlacements]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        tiparsOutputSubtreesRef.current = tiparsOutputSubtrees;
        drawTree();
    }, [tiparsOutputSubtrees]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        darkModeActiveRef.current = darkModeActive;
        filtersActiveRef.current = filtersActive;
        selectedNodeRef.current = selectedNode;
        if (filteredData) {
            filteredDataRef.current = filteredData;
        }
        drawTree();
        _render();
    }, [darkModeActive, filtersActive, filteredData, selectedNode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (viewCentre) {
            [viewBoxXY.current.y, viewBoxXY.current.x] = map_xy(viewCentre[1], viewCentre[0], treeBounds, width, height, padding);
        }
        if (viewBoxLims) {
            const mapped_xy1 = map_xy(viewBoxLims[1], viewBoxLims[0], treeBounds, width, height, padding),
                mapped_xy2 = map_xy(viewBoxLims[3], viewBoxLims[2], treeBounds, width, height, padding);
            viewBoxHeight.current = mapped_xy2[0] - mapped_xy1[0];
            viewBoxWidth.current = mapped_xy2[1] - mapped_xy1[1];
        }
        _render();
    }, [viewCentre, viewBoxLims]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        drawTree();
        drawChart();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const _render = () => {
        var canvas = document.getElementById(`${visualMode}-top-layer`);
        const context = canvas.getContext('2d');

        context.clearRect(0, 0, width, height);
        context.beginPath();

        context.fillStyle = darkModeActiveRef.current ? viewBoxParams.darkModeColor : viewBoxParams.color;
        context.globalAlpha = viewBoxParams.opacity;
        context.fillRect(
            viewBoxXY.current.x - viewBoxWidth.current/2,
            viewBoxXY.current.y - viewBoxHeight.current/2,
            viewBoxWidth.current,
            viewBoxHeight.current,
        );

        context.rect(
            viewBoxXY.current.x - viewBoxWidth.current/2,
            viewBoxXY.current.y - viewBoxHeight.current/2,
            viewBoxWidth.current,
            viewBoxHeight.current,
        );
        context.globalAlpha = 1;
        context.strokeStyle = darkModeActiveRef.current ? viewBoxParams.darkModeEdgeColor : viewBoxParams.edgeColor;
        context.stroke();
    };

    const drawTree = () => {
        const canvas = document.getElementById(`${visualMode}-base-layer`);
        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext('2d');

        // Draw branches
        Object.values(treeData).forEach(d => {
            if (d[visualMode === 'global' ? 'up' : 'parent'] && d[visualMode === 'global' ? 'up' : 'parent'] in treeData) {
                const parent = treeData[d[visualMode === 'global' ? 'up' : 'parent']];
                const [parent_x, parent_y] = map_xy(parent.x, parent.y, treeBounds, width, height, padding);
                const [d_x, d_y] = map_xy(d.x, d.y, treeBounds, width, height, padding);

                context.beginPath();
                context.moveTo(parent_y, parent_x);
                context.lineTo(parent_y, d_x);
                context.lineTo(d_y, d_x);
                context.strokeStyle = darkModeActiveRef.current ? treeParams.link.darkModeColor : treeParams.link.color;
                context.lineWidth = treeParams.link.width;
                context.globalAlpha = tiparsVisibleRef.current && visualMode === 'global' ? (tiparsOutputSubtreesRef.current.includes(d.id) ? treeParams.node.opacity : treeParams.node.filteredOpacity) : (!filtersActiveRef.current || (filtersActiveRef.current && filteredDataRef.current && d.id in filteredDataRef.current) ? treeParams.link.opacity : treeParams.link.filteredOpacity);
                context.stroke();
            }
        });

        // Draw nodes
        Object.values(treeData).forEach(d => {            
            const [x, y] = map_xy(d.x, d.y, treeBounds, width, height, padding);

            if (d.num) {
                context.moveTo(y, x);
                context.beginPath();
                context.arc(y, x, treeParams.node.radius, 0, 2*Math.PI);

                context.globalAlpha = tiparsVisibleRef.current && visualMode === 'global' ? (tiparsOutputSubtreesRef.current.includes(d.id) ? treeParams.node.opacity : treeParams.node.filteredOpacity) : (!filtersActiveRef.current || (filtersActiveRef.current && filteredDataRef.current && d.id in filteredDataRef.current) ? treeParams.node.opacity : treeParams.node.filteredOpacity);
                context.fillStyle = darkModeActiveRef.current ? treeParams.node.darkModeColor : treeParams.node.color;
                context.fill();
            }
        });

        // Draw Tipars placements
        if (tiparsVisibleRef.current && tiparsOutputPlacementsRef.current) {
            tiparsOutputPlacementsRef.current.forEach(placement => {
                const [x, y] = map_xy(placement.x, placement.y, treeBounds, width, height, padding);

                context.beginPath();
                context.moveTo(y - params.treeParams.tipars.height, x - params.treeParams.tipars.height);
                context.lineTo(y + params.treeParams.tipars.height, x + params.treeParams.tipars.height);
            
                context.moveTo(y - params.treeParams.tipars.height, x + params.treeParams.tipars.height);
                context.lineTo(y + params.treeParams.tipars.height, x - params.treeParams.tipars.height);
                
                context.strokeStyle = params.treeParams.tipars.color;
                context.lineWidth = params.treeParams.tipars.lineWidth;
                context.globalAlpha = tiparsPlacementSelectedRef.current && tiparsPlacementSelectedRef.current.id !== placement.id ? params.treeParams.tipars.unselectedOpacity : params.treeParams.tipars.opacity;
                context.stroke();
            })
        }

        // Draw highlighter circles if filtersActive
        var highlightNodes = [];
        if (selectedNodeRef.current) {
            highlightNodes = Object.values(treeData).filter(d => d.id === selectedNodeRef.current);
        } else if (filtersActiveRef.current && filteredDataRef.current) {
            highlightNodes = Object.values(treeData).filter(d => d.id in filteredDataRef.current);
        }
        highlightNodes.forEach(d => {                
            const [x, y] = map_xy(d.x, d.y, treeBounds, width, height, padding);

            context.moveTo(y, x);
            context.beginPath();
            context.arc(y, x, treeParams.node.highlighterRadius, 0, 2*Math.PI);

            context.strokeStyle = selectedNodeRef.current ? treeParams.node.selectHighlighterColor : treeParams.node.filterHighlighterColor;
            context.lineWidth = 1.5;
            context.globalAlpha = treeParams.node.opacity;
            context.stroke();
        });
    };
              
    const drawChart = () => {
        const canvas = document.getElementById(`${visualMode}-top-layer`);
        canvas.width = width;
        canvas.height = height;

        function _dragStarted(event) {
            event.subject.active = true;
        };

        function _dragSubject(event) {
            const subject = viewBoxXY.current;
            const x = Math.max(Math.min(event.x, width - viewBoxWidth.current/2), viewBoxWidth.current/2),
                y = Math.max(Math.min(event.y, height + viewBoxHeight.current/2), -viewBoxHeight.current/2);
            const [true_x, true_y] = map_xy(y, x, treeBounds, width, height, padding, true);
            handleViewCentreOnChange(true_x, true_y, true);
            return subject;
        };
    
        function _dragged(event) {
            event.subject.x = Math.max(Math.min(event.x, width - viewBoxWidth.current/2), viewBoxWidth.current/2);
            event.subject.y = Math.max(Math.min(event.y, height + viewBoxHeight.current/2), -viewBoxHeight.current/2);
            const [true_x, true_y] = map_xy(event.subject.y, event.subject.x, treeBounds, width, height, padding, true);
            handleViewCentreOnChange(true_x, true_y, true);
        };
    
        function _dragEnded(event) {
            event.subject.active = false;
        };
    
        d3
            .select(canvas)
            .call(d3.drag().subject(_dragSubject)
                .on('start', _dragStarted)
                .on('drag', _dragged)
                .on('end', _dragEnded)
                .on('start.render drag.render end.render', _render)
            );
    
        _render();
    };

    return (
        <Root active={active} darkModeActive={darkModeActive} width={width} height={height}>
            <canvas
                id={`${visualMode}-base-layer`}
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: width,
                    height: height,
                    opacity: 1,
                    zIndex: 0
                }}
                ref={baseCanvasRef}
            />
            <canvas
                id={`${visualMode}-top-layer`}
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: width,
                    height: height,
                    opacity: 0.8,
                    zIndex: 1
                }}
                ref={topCanvasRef}
            />
        </Root>
    )
}

export default ExploreMap;