// external libraries
import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import ContactUsDialog from "./ContactUsDialog";

// custom components
import HeaderWhite from "../home/HeaderWhite";
import Footer from "../Footer";

// constant data
import * as DisplayedMessages from "./DisplayedMessages";
import { CAPTCHA_SITE_KEY } from "./captcha_key";
import { getBackendURL } from "./backendSettings";

// css
import "./ContactUs.css";

// Input keys for react hook form
const FORM_KEY_FIRST_NAME = "formKeyFirstName";
const FORM_KEY_LAST_NAME = "formKeyLastName";
const FORM_KEY_EMAIL = "formKeyEmail";
const FORM_KEY_MESSAGE = "formKeyMessage";
const FORM_KEY_CAPTCHA_TOKEN = "formCaptchaToken";

// Input validation rules for react hook form
const FORM_VALIDATION_FIRST_NAME = {
  required: true,
  maxLength: 800,
};
const FORM_VALIDATION_LAST_NAME = {
  required: true,
  maxLength: 800,
};
const FORM_VALIDATION_EMAIL = {
  required: true,
  // email validation is handled by HTML
  maxLength: 345,
};
const FORM_VALIDATION_MESSAGE = {
  required: true,
  maxLength: 8000,
};
const FORM_VALIDATION_CAPTCHA = {
  required: true,
};

const ContactUs = () => {
  // API control
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = (data, e) => {
    setIsSubmitting(true);
    fetch(`${getBackendURL(window.location.host)}covid-7m/contact/`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        first_name: data.formKeyFirstName,
        last_name: data.formKeyLastName,
        recipent: data.formKeyEmail,
        message: data.formKeyMessage,
      }),
    })
      .then(
        (result) => {
          if (result.ok) {
            e.target.reset();
            onSubmitSuccess();
          } else {
            console.error("server error on contact us api call");
            console.error(result);
            onSubmitFail();
          }
        },
        (error) => {
          console.error("server error on contact us api call");
          console.error(error);
          onSubmitFail();
        }
      )
      .then(() => {
        setIsSubmitting(false);
      });
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [DialogTitle, setDialogTitle] = useState("");

  const onSubmitSuccess = () => {
    setDialogMessage(DisplayedMessages.SUCCESS_CONTACT_US_MESSAGE);
    setDialogTitle(DisplayedMessages.SUCCESS_CONTACT_US_TITLE);
    setDialogOpen(true);
  };

  const onSubmitFail = () => {
    setDialogMessage(DisplayedMessages.FAILED_CONTACT_US_MESSAGE);
    setDialogTitle(DisplayedMessages.FAILED_CONTACT_US_TITLE);
    setDialogOpen(true);
  };

  // message character counter
  const [msgCharCount, setMsgCharCount] = useState(0);
  const onMsgChange = (e) => {
    setMsgCharCount(e.target.value.length);
  };

  // validation with react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const renderFirstNameError = () => {
    switch (errors[FORM_KEY_FIRST_NAME]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      case "maxLength":
        return <span>{DisplayedMessages.EXCEED_MAX_CHAR_LIMIT}</span>;
      default:
        return null;
    }
  };
  const renderLastNameError = () => {
    switch (errors[FORM_KEY_LAST_NAME]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      case "maxLength":
        return <span>{DisplayedMessages.EXCEED_MAX_CHAR_LIMIT}</span>;
      default:
        return null;
    }
  };
  const renderEmailError = () => {
    switch (errors[FORM_KEY_EMAIL]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      case "maxLength":
        return <span>{DisplayedMessages.EXCEED_MAX_CHAR_LIMIT}</span>;
      default:
        return null;
    }
  };
  const renderMessageError = () => {
    switch (errors[FORM_KEY_MESSAGE]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      case "maxLength":
        return <span>{DisplayedMessages.EXCEED_MAX_CHAR_LIMIT}</span>;
      default:
        return null;
    }
  };
  const renderCaptchaError = () => {
    switch (errors[FORM_KEY_CAPTCHA_TOKEN]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      default:
        return null;
    }
  };

  // captcha init + token handling + validation
  useEffect(() => {
    register(FORM_KEY_CAPTCHA_TOKEN, FORM_VALIDATION_CAPTCHA);
  }, [register]);
  const onReceiveToken = (token) => {
    console.log("token received:" + token);
    setValue(FORM_KEY_CAPTCHA_TOKEN, token, { shouldValidate: true });
  };

  // using user data as default value
  // const [inputFirstName, setInputFirstName] = useState("");
  // const [inputEmail, setInputEmail] = useState("");

  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid
          item
          container
          style={{
            marginBottom: "36px",
          }}
        >
          <Grid item md={2} sm={2} xs></Grid>
          <Grid item md={4} sm={8} xs={12}>
            <div className="external-pages">
              <div className="text-container-m" style={{ paddingRight: 20 }}>
                <h2 style={{ marginBottom: 10 }}>CONTACT US</h2>
                <h1>
                  Get in <span>Touch</span> with Us
                </h1>
                <div className="text-button-container">
                  <p>
                    Before submitting your question, please visit&nbsp;
                    <Link to={`/faq`}>FAQ</Link>.
                    <br />
                    <br />
                    If you cannot find an answer to your question or would like
                    to submit a suggestion, feel free to drop us a line using
                    the form on the right and we will get back to you shortly.
                  </p>
                </div>
              </div>
              <div
                className="external-page-divider"
                style={{
                  marginBottom: "36px",
                  marginRight: 30,
                }}
              />
            </div>
          </Grid>
          <Grid item md sm={2} xs></Grid>
          <Grid item md sm={2} xs></Grid>
          {/* <Grid item md sm xs></Grid> */}
          <Grid item md={4} sm={8} xs={12}>
            <div className="external-pages">
              <div className="contact-form-container">
                <h5 style={{ marginBottom: 30 }}>How can we help?</h5>
                <form
                  id="contact-form"
                  onSubmit={handleSubmit(onSubmit)}
                // onSubmit={this.handleSubmit.bind(this)}
                //method="POST"
                >
                  <label
                    htmlFor="name"
                    style={{ fontSize: 15, fontWeight: 700 }}
                  >
                    First Name
                  </label>
                  <div
                    className="form-group"
                    style={{
                      marginTop: 0,
                      marginBottom: 15,
                      display: "flex",
                    }}
                  >
                    <input
                      type="text"
                      className="form-input"
                      placeholder="First name"
                      style={{
                        width: "100%",
                        height: 20,
                      }}
                      // onInput={(e) => setInputFirstName(e.target.value)}
                      // value={inputFirstName}
                      {...register(
                        FORM_KEY_FIRST_NAME,
                        FORM_VALIDATION_FIRST_NAME
                      )}
                    />
                    {renderFirstNameError()}
                  </div>
                  <label
                    htmlFor="name"
                    style={{ fontSize: 15, fontWeight: 700 }}
                  >
                    Last Name
                  </label>
                  <div
                    className="form-group"
                    style={{
                      marginTop: 0,
                      marginBottom: 15,
                      display: "flex",
                    }}
                  >
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Last name"
                      style={{
                        width: "100%",
                        height: 20,
                      }}
                      {...register(
                        FORM_KEY_LAST_NAME,
                        FORM_VALIDATION_LAST_NAME
                      )}
                    />
                    {renderLastNameError()}
                  </div>
                  <label
                    htmlFor="exampleInputEmail1"
                    style={{ fontSize: 15, fontWeight: 700 }}
                  >
                    Email address
                  </label>
                  <div
                    className="form-group"
                    style={{
                      marginTop: 0,
                      marginBottom: 15,
                      display: "flex",
                    }}
                  >
                    <input
                      type="email"
                      className="form-input"
                      style={{ width: "100%" }}
                      aria-describedby="emailHelp"
                      placeholder="Email address"
                      // onInput={(e) => setInputEmail(e.target.value)}
                      // value={inputEmail}
                      {...register(FORM_KEY_EMAIL, FORM_VALIDATION_EMAIL)}
                    />
                    {renderEmailError()}
                  </div>
                  <label
                    htmlFor="message"
                    style={{ fontSize: 15, fontWeight: 700 }}
                  >
                    Message
                  </label>
                  <div
                    className="form-group"
                    style={{
                      marginTop: 0,
                      marginBottom: 5,
                      display: "flex",
                    }}
                  >
                    <textarea
                      placeholder="Leave your message here..."
                      className="form-input"
                      rows="5"
                      maxLength={FORM_VALIDATION_MESSAGE.maxLength}
                      style={{
                        height: "100px",
                        width: "100%",
                        resize: "none",
                        paddingTop: "6px",
                        marginBottom: 0,
                      }}
                      onInput={onMsgChange}
                      {...register(FORM_KEY_MESSAGE, FORM_VALIDATION_MESSAGE)}
                    ></textarea>
                    <div>{renderMessageError()}</div>
                  </div>
                  <div
                    style={{
                      color: "grey",
                      fontSize: "0.75rem",
                      marginTop: 0,
                    }}
                  >
                    {msgCharCount}/{FORM_VALIDATION_MESSAGE.maxLength}
                  </div>
                  <div
                    className="form-group captcha-container"
                    style={{
                      // height: "80px",
                      marginTop: 15,
                    }}
                  >
                    <ReCAPTCHA
                      sitekey={CAPTCHA_SITE_KEY}
                      onChange={onReceiveToken}
                    />
                    {renderCaptchaError()}
                  </div>
                  <button
                    type="submit"
                    className="form-input-btn submit-button"
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={24}
                        className="spinner"
                        thickness={5}
                      />
                    ) : (
                      "Send"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs></Grid>
        </Grid>
      </div>
      <Footer />
      <ContactUsDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title={DialogTitle}
        message={dialogMessage}
      />
    </div>
  );
};

export default ContactUs;
