import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

import helpers from '../../../helpers';

const Root = styled('div')(({ theme, active, darkModeActive }) => ({
    position: 'absolute',
    padding: '5px 15px 8px 15px',
    textAlign: 'left',
    left: 0,
    backgroundColor: helpers.hexToRgb(theme.palette.background.main, 0.5),
    borderRadius: '0px 0px 3px 0px',
    borderBottomWidth: 2,
    borderRightWidth: 2,
    borderWidth: '0px 1px 1px 0px',
    borderColor: darkModeActive ? theme.palette.background.light : theme.palette.background.dark,
    borderStyle: 'solid',
    zIndex: 1,
    display: active ? null : 'none',
}));

const Title = styled(Typography)(({ theme, darkModeActive }) => ({
    display: 'inline-block',
    height: 0,
    fontSize: 13,
    fontWeight: 700,
    marginRight: 10,
    color: darkModeActive ? theme.palette.text.main.light : theme.palette.text.main.dark,
    cursor: 'auto',
    userSelect: 'none',
}));

const NodeText = styled(Typography)(({ theme, darkModeActive }) => ({
    display: 'inline-block',
    fontSize: 11,
    fontWeight: 700,
    color: darkModeActive ? theme.palette.text.main.light : theme.palette.text.main.dark,
    cursor: 'auto',
    userSelect: 'none',
}));

const NodeSelectionInfo = styled(Typography)(({ theme }) => ({
    fontSize: 11,
    color: '#c70000',
}));  

function NodeInfo(props) {
    const { active, nodeData } = props;
    const { darkModeActive } = useSelector(state => state.visualReducer);

    return (
        <Root active={active} darkModeActive={darkModeActive}>
            <Title darkModeActive={darkModeActive}>
                node selected:
            </Title>
            <NodeText darkModeActive={darkModeActive}>
                {
                    nodeData ? `${nodeData.info.name} (${nodeData.info.subtreeName})` : 'N/A'
                }
            </NodeText>
            <NodeSelectionInfo>
                <span style={{ fontStyle: 'italic', fontWeight: 1000, backgroundColor: 'white' }}>
                &nbsp;<span style={{ textDecoration: 'underline' }}>Shift + Click</span>&nbsp;to select a node&nbsp;</span>
            </NodeSelectionInfo>
        </Root>
    )
}

export default NodeInfo;