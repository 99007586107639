import React from "react";
import Grid from "@mui/material/Grid";
import HeaderLeft from "./HeaderLeft";
import LandingContent from "./LandingContent";
import background from "../../../images/background-sm.jpg";
import LandingFeatures from "./LandingFeatures";
import Footer from "../Footer";
import './home.css';

export default function Home() {
  return (
    <div className="home">
      <Grid container>
        <Grid
          item
          container
          style={{
            backgroundImage: `url(${background})`,
            backgroundColor: "#315A5E",
            backgroundSize: "cover",
            width: "100%",
            height: "800px",
          }}
        >
          <Grid item sm={2} xs={2}>
            <HeaderLeft />
          </Grid>
          <Grid item sm xs></Grid>
          <Grid container
            style={{
              display: 'flex',
              justifyContent: 'center',  
            }}
          >
            <Grid item xs={12} sm={8}>
              <LandingContent />
            </Grid>
          </Grid>
          <LandingFeatures />
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
};