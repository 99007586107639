import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

import filtersReducer from './filters/reducers';
import visualReducer from './visual/reducers';
import staticReducer from './static/reducers';
import tiparsReducer from './tipars/reducers';

const rootReducer = combineReducers({
    filtersReducer,
    visualReducer,
    staticReducer,
    tiparsReducer
});

const store = createStore(
    rootReducer,
    // composeWithDevTools(applyMiddleware(thunkMiddleware)),
    applyMiddleware(thunk)
);

export default store;