import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, AppBar, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import LoadingErrorScreen from '../../../../loadingError';
import helpers from '../../helpers';
import Phylo from './phylo';
import Epi from './epi';
import Tipars from './tipars';
import allActions from '../../redux';

const Root = styled(Box)({
    textAlign: 'center',
});

const TabRoot = styled(AppBar)(({ theme }) => ({
    height: 42,
    position: 'static',
    width: '100%',
    borderBottom: `2px solid ${theme.palette.background.light}`,
}));

const SwipeableView = styled(Box)(({ theme }) => ({
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    scrollbarWidth: 'none',
    height: 'calc(max(500px, min(100vh, 800px) - 35px) - 170px - 42px)',
    justifyContent: 'center',
    backgroundColor: helpers.hexToRgb(theme.palette.background.light, 0.4),
}));

const CustomTabs = styled(Tabs)({
    minHeight: 0,
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    },
});

const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    color: theme.palette.text.title,
    fontSize: 12,
    minWidth: 0,
    minHeight: 0,
    height: 40,
    opacity: 1,
    '&:hover': {
        backgroundColor: theme.palette.background.lighter,
        opacity: 0.8,
    },
    '&.Mui-selected': {
        color: '#fff',
        backgroundColor: theme.palette.background.main,
        fontWeight: 700,
        opacity: 1,
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.background.light,
        fontWeight: 300,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function Dashboard(props) {
    const dispatch = useDispatch();

    const { fatalError, filteringError, initialLoading, tiparsDisabled } = props;
    const {
        filtersActive,
        filteredGlobalData,
        filteredGlobalDataLoading,
        staticSubtreeDataLoading,
        filteredSubtreeData,
        filteredSubtreeDataLoading,
        staticSubtreeGraphLoading,
        filteredSubtreeGraphLoading,
        staticSubtreeData,
        staticNodeData,
        staticNodeDataLoading,
        filteredNodeData,
        filteredNodeDataLoading,
    } = useSelector((state) => state.filtersReducer);
    const { visualMode, tabIndex } = useSelector((state) => state.visualReducer);

    const filtersLoading =
        filteredGlobalDataLoading ||
        staticSubtreeDataLoading ||
        filteredSubtreeDataLoading ||
        staticNodeDataLoading ||
        filteredNodeDataLoading;
    const graphLoading =
        staticSubtreeGraphLoading || filteredSubtreeGraphLoading;
    const disabled =
        fatalError || filteringError || initialLoading || filtersLoading;

    const staticGlobalData = useSelector(
        (state) => state.staticReducer.staticGlobalData
    );
    const activeData =
        (filtersActive
            ? (visualMode === 'rect' ? filteredNodeData : null) ??
              filteredSubtreeData ??
              filteredGlobalData
            : null) ??
        (visualMode === 'rect'
            ? staticNodeData
            : null) ??
        staticSubtreeData ??
        staticGlobalData;

    const handleTabChange = (event, newValue) => {
        dispatch(allActions.visualActions.setTabIndex(newValue));
    };

    return (
        <Root>
            <TabRoot elevation={0} color="inherit">
                <CustomTabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="fullWidth"
                >
                    <CustomTab
                        disableRipple={true}
                        disabled={disabled}
                        label="PHYLO"
                        {...a11yProps(0)}
                    />
                    <CustomTab
                        disableRipple={true}
                        disabled={disabled}
                        label="EPI"
                        {...a11yProps(1)}
                    />
                    <CustomTab
                        disableRipple={true}
                        disabled={disabled | tiparsDisabled}
                        style={{
                            textDecoration: tiparsDisabled ? 'line-through' : null,
                            backgroundColor: tiparsDisabled ? '#c2c2c2' : null
                         }}
                        label="TIPARS"
                        {...a11yProps(2)}
                    />
                </CustomTabs>
            </TabRoot>
            {initialLoading || fatalError || filteringError || filtersLoading ? (
                <LoadingErrorScreen
                    fatalError={fatalError}
                    error={filteringError}
                    imgWidth={95}
                    padding="17% 1px 10% 0px"
                    darkModeActive={false}
                />
            ) : (
                <SwipeableView>
                    <TabPanel value={tabIndex} index={0}>
                        <Phylo activeData={activeData} graphLoading={graphLoading} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <Epi activeData={activeData} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <Tipars />
                    </TabPanel>
                </SwipeableView>
            )}
        </Root>
    );
}

export default Dashboard;