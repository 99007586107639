import React, { Fragment, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';

import Pie from './pie';
import PaginatedLegend from './paginatedLegend';

const Root = styled(Paper)(({ theme }) => ({
    borderRadius: 2,
    backgroundColor: theme.palette.background.lighter,
    margin: 10,
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.dark}`,
    textAlign: 'right',
    padding: '8px 15px',
    color: '#fff',
    borderRadius: '2px 2px 0px 0px',
}));

const Title = styled(Typography)({
    display: 'inline',
    float: 'left',
    fontSize: 14,
    fontWeight: 700,
});

const ItemInfo = styled(Typography)({
    display: 'contents',
    float: 'right',
    fontSize: 12,
    fontWeight: 500,
});

const PieContainer = styled(Grid)({
    textAlign: 'center',
});

const LegendContainer = styled(Grid)(({ theme }) => ({
    textAlign: 'right',
    borderRight: `1px solid ${theme.palette.background.light}`,
}));

const NoData = styled(Typography)(({ theme }) => ({
    padding: 15,
    width: '100%',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.main.normal,
}));

const usePrevious = (value) => {
    const ref = React.useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

function PieChart(props) {
    const selectedRef = React.useRef(null);
  
    const { sortedObjs, margin, title } = props;

    const prevSortedObjs = usePrevious(JSON.stringify(sortedObjs));
    const [selectedItemId, setSelectedItemId] = React.useState(null);
    const [itemInfo, setItemInfo] = React.useState('');
    const handleItemOnSelect = (id) => {
        setSelectedItemId(id);
        selectedRef.current = id;
    };

    const [renderTrigger, setRenderTrigger] = React.useState('');
    useEffect(() => {
        if (JSON.stringify(sortedObjs) !== prevSortedObjs) {
            setRenderTrigger(Math.random().toString());
            setSelectedItemId(null);
            setItemInfo('');
            selectedRef.current = null;
        }
    }, [sortedObjs, prevSortedObjs]);

    return (
        <Root elevation={1} style={{ margin: margin !== null ? margin : null }}>
            <Grid container spacing={0}>
                <TitleContainer item xs={12}>
                    <Title>
                        {title}
                    </Title>
                    <ItemInfo>
                        {itemInfo}
                    </ItemInfo>
                </TitleContainer>
                {
                    sortedObjs.length ?
                    <Fragment key={renderTrigger}>
                        <LegendContainer item xs={4}>
                            <PaginatedLegend
                                sortedObjs={sortedObjs}
                                handleItemOnSelect={handleItemOnSelect}
                                selectedItemId={selectedItemId}
                                pageLimit={10}
                            />
                        </LegendContainer>
                        <PieContainer item xs={8}>
                            <Pie
                                name={title.replace(' ', '-')}
                                data={sortedObjs}
                                width={215}
                                handleItemOnSelect={handleItemOnSelect}
                                setItemInfo={setItemInfo}
                                selectedRef={selectedRef}
                                selectedItemId={selectedItemId}
                            />
                        </PieContainer>
                    </Fragment>
                    :
                    <NoData>
                        No data to display.
                    </NoData>
                }
            </Grid>
        </Root>
    );
};

export default PieChart;