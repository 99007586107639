import { styled } from '@mui/system';
import {
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";

const ContactUsDialog = ({ open, handleClose, message, title }) => {
    const Title = styled(Typography)(({ theme }) => ({
        color: "#333",
        fontSize: 16,
        fontWeight: 700,
    }));

    const CustomButton = styled(Button)(({ theme }) => ({
        minWidth: 0,
        marginRight: 15,
        fontSize: 14,
        fontWeight: 700,
        padding: '3px 12px',
        color: "#333",
    }));

    const MyButton = styled(CustomButton)(({ theme }) => ({
        backgroundColor: "#F4F5F8",
        "&:hover": {
            opacity: 0.8,
        },
    }));

    return (
        <MuiDialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Title>{title}</Title>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <MyButton onClick={handleClose} color="primary">
                    Close
                </MyButton>
            </DialogActions>
        </MuiDialog>
    );
};

export default ContactUsDialog;
