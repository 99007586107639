export function useFilteringRequest(filtersStore, staticStore) {
    // Extract color-maps from static store
    const maps = staticStore.meta.maps;

    // Get id of continents to be excluded in filtering
    const continentIdsExclude = Object.keys(maps.continent).filter(key => !filtersStore.continentIds.includes(key));

    // Get id of countries to be included/excluded and return shortest array
    const countriesIncluded = filtersStore.countryIds.length < Object.keys(maps.country).length,
        countryIds = countriesIncluded ? filtersStore.countryIds : Object.keys(maps.country).filter(key => !filtersStore.countryIds.includes(key));
    
    // Encode yearUnknownExcluded, monthUnknownExcluded
    const dateIncompleteExcluded = filtersStore.collectionDate.monthUnknownExcluded ? 2 : (filtersStore.collectionDate.yearUnknownExcluded ? 1 : 0);

    // Get id of patient genders to be excluded in filtering
    const genderIdsExclude = Object.keys(maps.gender).filter(key => !filtersStore.genderIds.includes(key));

    // Get id of GISAID lineages to be excluded in filtering
    const gisaidIdsExclude = Object.keys(maps.gisaid).filter(key => !filtersStore.gisaidIds.includes(key));

    // Get id of PANGO lineages to be included/excluded and return shortest array
    const pangoIncluded = filtersStore.pangoIds.length < Object.keys(maps.pango).length,
        pangoIds = pangoIncluded ? filtersStore.pangoIds : Object.keys(maps.pango).filter(key => !filtersStore.pangoIds.includes(key));

    // Replace keywords array with [''] if empty
    const keywords = filtersStore.keywords.length ? filtersStore.keywords : ['']

    // Construct obj for POST request
    const requestData = {
        continent_ids_exclude: continentIdsExclude,
        countries_included: countriesIncluded,
        country_ids: countryIds,
        earliest: filtersStore.collectionDate.earliest,
        latest: filtersStore.collectionDate.latest,
        date_incomplete_excluded: dateIncompleteExcluded,
        gender_ids_exclude: genderIdsExclude,
        min_age: filtersStore.age.minAge,
        max_age: filtersStore.age.maxAge,
        age_unknown_excluded: filtersStore.age.ageUnknownExcluded,
        gisaid_ids_exclude: gisaidIdsExclude,
        pango_included: pangoIncluded,
        pango_ids: pangoIds,
        keywords: keywords,
        muts: filtersStore.muts,
        mut_mode: filtersStore.mutMode
    };

    // Return JSON-string
    return JSON.stringify(requestData);
};