import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { styled } from '@mui/system';

import helpers from '../../../../helpers';

const formatBytes = (bytes, decimals=2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const CustomButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    marginRight: 15,
    fontSize: 14,
    fontWeight: 700,
    padding: '3px 12px',
    color: theme.palette.text.title,
}));

const SubmitButton = styled(CustomButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.lighter,
    "&:hover": {
        opacity: 0.8,
    },
}));

const ResetButton = styled(CustomButton)(({ theme }) => ({
    borderColor: "#fff",
    "&:hover": {
        backgroundColor: 'transparent',
    },
    padding: 10,
    paddingRight: 0,
}));

const BrowseButton = styled(Button)(({ theme, activeFile }) => ({
    width: '100%',
    height: '100%',
    margin: 0,
    fontSize: 14,
    borderColor: theme.palette.background.main,
    backgroundColor: activeFile ? theme.palette.background.main : 'transparent',
    border: '1px solid',
    color: activeFile ? '#fff' : theme.palette.background.main,
    '&:hover': {
        border: '1px solid',
        color: '#fff',
        borderColor: theme.palette.background.main,
        backgroundColor: theme.palette.background.main,
    },
}));

const BrowseLabel = styled('div')({
    fontSize: 12,
    textTransform: 'none',
    lineHeight: '12px',
    textAlign: 'start',
});

const FileInfo = styled(Typography)(({ theme, activeFile }) => ({
    width: '90%',
    fontSize: 12,
    color: '#484848',
    paddingRight: 10,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: activeFile ? 'ellipsis' : 'clip',
}));

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const Description = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 0,
    textAlign: 'justify',
}));

const Label = styled('span')(({ theme }) => ({
    fontSize: 13,
    display: 'inline',
    textTransform: 'none',
}));

const Skeleton = styled('div')(({ theme }) => ({
    width: '100%',
    height: 15,
    backgroundColor: helpers.hexToRgb(theme.palette.background.light, 0.5),
    verticalAlign: 'text-bottom',
    display: 'inline-block',
}));

function UploadDialog(props) {
    const hiddenFileInput = React.useRef(null);
    
    const { open, fileUploaded, fileSubmitted } = props;
    const { handleUploadDialogClose, handleFileUpload, handleFileSubmit, setFileUploaded } = props;

    const boldText = (string) => {
        return <span style={{ fontWeight: 700 }}>{string}</span>;
    };

    const _browseButtonOnClick = (event) => {
        hiddenFileInput.current.click();
    };

    const _onClose = () => {
        handleUploadDialogClose();
        setFileUploaded(null);
    };

    const _onSubmit = () => {
        fileUploaded && handleFileSubmit();
        handleUploadDialogClose();
    };

    const _onReset = () => {
        setFileUploaded(null);
        handleFileSubmit(true);
        handleUploadDialogClose();
    };

    const skeleton = () => (<Skeleton />);

    const [activeFile, setActiveFile] = React.useState(null);

    useEffect(() => {
        open && setActiveFile(fileUploaded ?? fileSubmitted);
    }, [open, fileSubmitted, fileUploaded]);

    return (
        <Dialog open={open} maxWidth='xs' onClose={_onClose}>
            <DialogTitle style={{ paddingBottom: 5 }}>
                <Title>
                    Upload Sequence(s)
                </Title>
            </DialogTitle>
            <DialogContent>
                <Description>
                    The file to be uploaded may contain a single or multiple nucleotide sequences in {boldText('FASTA')} format. The only accepted file extensions are {boldText('.fasta .faa .fa .fna .ffn')} and {boldText('.fnn')}. Sequence descriptions are accepted but discarded in the final output.
                    <br /><br />
                </Description>
                    <Grid container justify='center' style={{ marginBottom: 0 }}>
                        <Grid item xs={8}>
                            <FileInfo activeFile={activeFile}>
                                {boldText('FILE NAME:')} {activeFile ? activeFile.name : skeleton()}<br />
                            </FileInfo>
                            <FileInfo activeFile={activeFile}>
                                {boldText('FILE SIZE:')}&nbsp;&nbsp;{activeFile ? formatBytes(activeFile.size) : skeleton()}<br />
                            </FileInfo>
                            <FileInfo activeFile={activeFile}>
                                {boldText('LAST MODIFIED:')}&nbsp;&nbsp;{activeFile ? new Date(activeFile.lastModified).toDateString() : skeleton()}
                            </FileInfo>
                        </Grid>
                        <Grid item xs={4}>
                            <BrowseButton
                                variant='outlined'
                                color='primary'
                                disableRipple={true}
                                activeFile={activeFile}
                                onClick={(event) => _browseButtonOnClick(event)}
                            >
                                <InsertDriveFileIcon fontSize='medium'/>
                                <BrowseLabel>
                                    CHOOSE<br />FILE
                                </BrowseLabel>
                            </BrowseButton>
                            <input
                                type='file'
                                ref={hiddenFileInput}
                                accept='.fasta, .faa, .fa, .fna, .ffn, .fnn, .fas'
                                style={{ display: 'none' }}
                                onChange={(event) => handleFileUpload(event)}
                            />
                        </Grid>
                    </Grid>
            </DialogContent>
            <DialogActions>
                <ResetButton
                    disableRipple={true}
                    onClick={_onReset}
                >
                    <Label>Reset</Label>
                </ResetButton>
                <SubmitButton
                    disableRipple={true}
                    onClick={_onSubmit}
                >
                    <Label>Submit</Label>
                </SubmitButton>
            </DialogActions>
        </Dialog>
    )
};

export default UploadDialog;