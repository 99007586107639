import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import * as d3 from 'd3';

import params from './params';
import helpers from '../../../helpers';
const Root = styled('div')(({ theme, active, darkModeActive }) => ({
    position: 'absolute',
    right: 0,
    backgroundColor: helpers.hexToRgb(theme.palette.background.main, 0.3),
    borderRadius: '0px 0px 0px 3px',
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderWidth: '0px 1px 1px 1px',
    borderColor: darkModeActive ? theme.palette.background.light : theme.palette.background.dark,
    borderStyle: 'solid',
    visibility: active ? 'visible' : 'hidden',
}));

function BranchScale(props) {
    const svgRef = React.useRef(null);

    const { visualMode, epsilon, scale } = props;

    const svgID = `branchScale-${visualMode}`;

    const { branchScaleActive, darkModeActive } = useSelector(state => state.visualReducer);
    const active = branchScaleActive;

    // extract parameters from props
    const {
        width,
        height,
        arrowHeight,
        arrowWidthProp,
        arrowRightOffset,
        arrowTopOffset,
        arrowHeadWidth,
        arrowHeadHeight,
        color,
        darkModeColor,
        textFontSize,
        textVerticalOffset,
        textHorizontalOffset,
        helperTextFontSize,
        helperTextVerticalOffset,
        helperTextHorizontalOffset,
    } = params;
    const totalArrowLength = arrowWidthProp*width + 2*arrowHeadHeight;

    useEffect(() => {
        drawChart();
        const svg = svgRef.current;

        return (() => {
            d3.select(svg).remove();
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (branchScaleActive) {
            const newTrueLength = Number.parseFloat(totalArrowLength/(epsilon*scale)).toExponential(2);
            d3.select(`#${svgID}-label`).text(newTrueLength);
        }
    }, [branchScaleActive, scale, epsilon, totalArrowLength]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const newColor = darkModeActive ? darkModeColor : color;
        d3.select(`#${svgID}-label`).attr('fill', newColor);
        d3.select(`.${svgID}-helper-text`).attr('fill', newColor);
        d3.selectAll(`.${svgID}-arrow`).attr('fill', newColor);
    }, [darkModeActive]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const svg = svgRef.current;
        d3.select(svg).style('opacity', active ? 1 : 0);
    }, [active]);

    const drawChart = () => {

        // create svg
        const svg = d3
            .select(svgRef.current)
            .style('cursor', 'auto')
            .style('user-select', 'none')

        // draw double-ended arrow
        svg.append('rect')
            .attr('class', `${svgID}-arrow`)
            .attr('y', (height - arrowHeight)/2 + arrowTopOffset)
            .attr('x', width*(1 - arrowWidthProp)/2)
            .attr('width', width*arrowWidthProp)
            .attr('height', arrowHeight)
            .attr('transform', `translate(${width*arrowRightOffset},0)`)
            .attr('fill', darkModeActive ? darkModeColor : color);

        svg.append('path')
            .attr('class', `${svgID}-arrow`)
            .attr('d', `M0,0L0,${arrowHeadWidth/2}L-${arrowHeadHeight},0L0,${-arrowHeadWidth/2}`)
            .attr('fill', darkModeActive ? darkModeColor : color)
            .attr('transform', `translate(${width*(1 - arrowWidthProp)/2 + 5}, ${height/2 + arrowTopOffset})`);

        svg.append('path')
            .attr('class', `${svgID}-arrow`)
            .attr('d', `M0,0L0,${arrowHeadWidth/2}L${arrowHeadHeight},0L0,${-arrowHeadWidth/2}`)
            .attr('fill', darkModeActive ? darkModeColor : color)
            .attr('transform', `translate(${width*(1 + arrowWidthProp)/2 - 5}, ${height/2 + arrowTopOffset})`);

        // add branch length text
        svg.append('text')
            .attr('id', `${svgID}-label`)
            .attr('text-anchor', 'end')
            .attr('y', (height - arrowHeight)/2 + arrowTopOffset + textVerticalOffset)
            .attr('x', width/2 + textHorizontalOffset)
            .attr('font-family', 'PTRootUI')
            .attr('font-size', textFontSize)
            .attr('font-weight', 700)
            .attr('fill', darkModeActive ? darkModeColor : color)
            .text(Number.parseFloat(totalArrowLength/(epsilon*scale)).toExponential(2))

        // add helper text
        svg.append('text')
            .attr('class', `${svgID}-helper-text`)
            .attr('text-anchor', 'end')
            .attr('y', (height - arrowHeight)/2 + arrowTopOffset + textVerticalOffset + helperTextVerticalOffset)
            .attr('x', width/2 + textHorizontalOffset + helperTextHorizontalOffset)
            .attr('width', 9)
            .attr('font-family', 'PTRootUI')
            .attr('font-size', helperTextFontSize)
            .attr('fill', darkModeActive ? darkModeColor : color)
            .text('(substitution per site)')

    }

    return (
        <Root active={active} darkModeActive={darkModeActive}>
            <svg
                id={svgID}
                style={{
                    width: width,
                    height: height,
                    opacity: 1,
                }}
                ref={svgRef}
            />
        </Root>
    )
}

export default BranchScale;