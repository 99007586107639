import React from "react";
import Grid from "@mui/material/Grid";
import logo from "../../../images/logo-light-theme.svg";
import Drawer from "../Drawer";
import { Link } from "react-router-dom";
import "../../../App.css";

const HeaderWhite = () => {
  return (
    <div
      className="header-container"
      style={{
        width: "100%",
        height: "96px",
        // boxShadow: "0 2px 12px rgba(0, 0, 0, 0.06)",
        borderBottom: "lightgrey solid 1px",
      }}
    >
      <Grid container direction="column">
        <Grid item container>
          <Grid item sm={2} xs={2}>
            <div
              className="header-container-left"
              style={{
                height: "96px",
                display: "flex",
                marginRight: "0",
                float: "right",
              }}
            >
              <Link
                to={`/`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "40px",
                  float: "right",
                }}
              >
                <div className="logo">
                  <img
                    alt="logo"
                    src={logo}
                    style={{
                      width: "48px",
                      height: "48px",
                    }}
                  />
                </div>
              </Link>
              <Drawer bkgColor="white" />
            </div>
          </Grid>
          <Grid item sm xs>
            <div> </div>
          </Grid>
          <Grid item sm={2} xs={2}>
            <div
              className="header-container-right"
              style={{
                height: "96px",
              }}
            >
              {/* <Switch>
                {isSignedIn ? (
                  <Link to={`/profile`}>
                    <div
                      className="profileIcon"
                      style={{
                        height: "36px",
                        width: "36px",
                        marginTop: "28px",
                        borderRadius: "50%",
                        textAlign: "left",
                      }}
                    >
                      <img
                        alt="profileIcon"
                        src={ProfileIcon}
                        style={{
                          width: "36px",
                          height: "36px",
                        }}
                      />
                    </div>
                  </Link>
                ) : (
                  <div
                    className="HeaderRight"
                    style={{
                      height: "96px",
                      display: "flex",
                      marginLeft: "0",
                      float: "left",
                    }}
                  >
                    <div
                      className="login"
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      <Link to={`/login`}>
                        <span
                          style={{
                            fontSize: "1.2rem",
                          }}
                        >
                          LOGIN
                        </span>
                      </Link>
                    </div>
                  </div>
                )}
              </Switch> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeaderWhite;