import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken } from 'axios';

import PieChart from '../pieChart';
import NodeInfo from './nodeInfo';
import GoToTree from './goToTree';
import GoToNode from './goToNode';
import SeqsTable from './seqsTable';
import helpers from '../../../helpers';
import allActions from '../../../redux';

function Phylo(props) {
    const dispatch = useDispatch();
    const axiosRef = React.useRef(null);

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    const { activeData } = props;

    const { apiURL } = useSelector(state => state.staticReducer);
    const colorMaps = useSelector(state => state.staticReducer.meta.maps);
    const { filtersActive, staticSubtreeGraph, staticSubtreeData, filteredSubtreeGraph, filteredSubtreeData, staticNodeData, filteredNodeData } = useSelector(state => state.filtersReducer);
    const { visualMode, selectedSubtreeId } = useSelector(state => state.visualReducer);

    const isGhost = staticNodeData && staticSubtreeGraph && staticNodeData.info.subtree !== staticSubtreeGraph.subtree;

    const goToTreeOnClickHandler = () => {
        if (visualMode === 'global' && !staticSubtreeGraph) {
            dispatch(allActions.filtersActions.loadStaticSubtreeGraph(selectedSubtreeId, newCancelToken, apiURL));
            filtersActive && !filteredSubtreeGraph && dispatch(allActions.filtersActions.loadFilteredSubtreeGraph(selectedSubtreeId, newCancelToken, apiURL));
            // dispatch(allActions.visualActions.setColorLegend('subtree'));
        } else if (visualMode === 'rect' && isGhost) {
            dispatch(allActions.filtersActions.resetStaticSubtreeData());
            dispatch(allActions.filtersActions.resetFilteredSubtreeData());
            dispatch(allActions.filtersActions.resetStaticSubtreeGraph());
            dispatch(allActions.filtersActions.resetFilteredSubtreeGraph());
            dispatch(allActions.visualActions.setSelectedNodeId());
            dispatch(allActions.filtersActions.resetStaticNodeData());
            dispatch(allActions.filtersActions.resetFilteredNodeData());
            dispatch(allActions.filtersActions.resetFilteringParamsOnSubtreeDeselect());
            dispatch(allActions.visualActions.setSelectedSubtreeId(staticNodeData.info.subtree));
            dispatch(allActions.filtersActions.loadStaticSubtreeData(staticNodeData.info.subtree, newCancelToken, apiURL));
            dispatch(allActions.filtersActions.loadStaticSubtreeGraph(staticNodeData.info.subtree, newCancelToken, apiURL));
            filtersActive && dispatch(allActions.filtersActions.loadFilteredSubtreeData(staticNodeData.info.subtree, newCancelToken, apiURL)) && dispatch(allActions.filtersActions.loadFilteredSubtreeGraph(staticNodeData.info.subtree, newCancelToken, apiURL));
            dispatch(allActions.visualActions.toggleSpotlight(false));
        }
        dispatch(allActions.visualActions.toggleVisualMode('rect'));
    };

    const goToNodeOnClickHandler = (isGhostMove) => {
        if (visualMode === 'rect' && isGhostMove) {
            dispatch(allActions.filtersActions.resetStaticSubtreeData());
            dispatch(allActions.filtersActions.resetFilteredSubtreeData());
            dispatch(allActions.filtersActions.resetStaticSubtreeGraph());
            dispatch(allActions.filtersActions.resetFilteredSubtreeGraph());
            dispatch(allActions.visualActions.setSelectedNodeId());
            dispatch(allActions.filtersActions.resetStaticNodeData());
            dispatch(allActions.filtersActions.resetFilteredNodeData());
            dispatch(allActions.filtersActions.resetFilteringParamsOnSubtreeDeselect());
            dispatch(allActions.visualActions.setSelectedSubtreeId(staticNodeData.info.subtree));
            dispatch(allActions.filtersActions.loadStaticSubtreeData(staticNodeData.info.subtree, newCancelToken, apiURL));
            dispatch(allActions.filtersActions.loadStaticSubtreeGraph(staticNodeData.info.subtree, newCancelToken, apiURL));
            filtersActive && dispatch(allActions.filtersActions.loadFilteredSubtreeData(staticNodeData.info.subtree, newCancelToken, apiURL)) && dispatch(allActions.filtersActions.loadFilteredSubtreeGraph(staticNodeData.info.subtree, newCancelToken, apiURL));
            dispatch(allActions.visualActions.toggleSpotlight(false));
            dispatch(allActions.visualActions.setRenderGoToNodeID(staticNodeData.info.id));
        } else {
            dispatch(allActions.visualActions.setGoToNodeID(visualMode === 'rect' ? staticNodeData.info.id : staticSubtreeData.id));
        }
    };

    const gisaidDist = helpers.distMapToArray(activeData.dists.gisaid, colorMaps.gisaid, activeData.seqNum);
    const pangoDist = helpers.distMapToArray(activeData.dists.pango, colorMaps.pango, activeData.seqNum);
  
    return(
        <div>
            {
                (staticSubtreeData || filteredSubtreeData) &&
                <Fragment>
                    {
                        visualMode === 'global' && (!staticSubtreeGraph || (staticSubtreeGraph && staticSubtreeGraph.subtree !== selectedSubtreeId)) &&
                        <GoToTree
                            active={(!staticSubtreeGraph || (staticSubtreeGraph && staticSubtreeGraph.subtree !== selectedSubtreeId))}
                            onClickHandler={goToTreeOnClickHandler}
                            label='view tree'
                        />
                    }
                    <GoToNode
                        active={(visualMode === 'rect' && staticNodeData) || (visualMode === 'global' && staticSubtreeData)}
                        isGhostMove={false}
                        onClickHandler={() => goToNodeOnClickHandler(false)}
                        label='go to node'
                    />
                    {
                        visualMode === 'rect' && isGhost &&
                        <GoToNode
                            active={true}
                            isGhostMove={true}
                            subtree={staticNodeData.info.subtreeName}
                            onClickHandler={() => goToNodeOnClickHandler(true)}
                            label={`go to node (${staticNodeData.info.subtreeName})`}
                        />
                    }
                    {/* <SubtreeChildrenDescendantsTable
                        disabled={graphLoading}
                        subtreeData={activeData}
                        filtersActive={filtersActive}
                        pageLimit={4}
                    /> */}
                </Fragment>
            }
            {
                visualMode === 'rect' && (staticSubtreeData || filteredSubtreeData) && staticNodeData &&
                <Fragment>
                    <NodeInfo
                        staticData={staticNodeData}
                        filteredData={filteredNodeData}
                        filtersActive={filtersActive}
                        pageLimit={4}
                    />
                    <SeqsTable
                        staticData={staticNodeData}
                        filteredData={filteredNodeData}
                        filtersActive={filtersActive}
                        pageLimit={4}
                    />
                </Fragment>
            }
            <PieChart
                sortedObjs={gisaidDist}
                title='GISAID lineage'
            />
            <PieChart
                sortedObjs={pangoDist}
                title='PANGO lineage'
            />
        </div>
    )
}

export default Phylo;