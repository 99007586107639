import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { styled } from '@mui/system';

import params from './params';

const Root = styled('div')({
    display: 'grid',
    gridTemplateRows: '1fr 30px',
    height: 219,
});

const KeyContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'auto 13px',
    marginBottom: 4,
    marginRight: 10,
    cursor: 'pointer',
});

const CircleContainer = styled(Box)({
    height: 13,
    verticalAlign: 'middle',
    display: 'inline-block',
});

const ColourCircle = styled('span')(({ color, opacity }) => ({
    height: 12,
    width: 12,
    marginLeft: 10,
    marginBottom: 3,
    borderRadius: '10%',
    display: 'inline-block',
    backgroundColor: color,
    opacity: opacity,
}));

const Key = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    lineHeight: '12px',
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.text.main.normal,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
}));

const KeysPanel = styled(Box)({
    padding: 10,
    paddingBottom: 0,
});

const ButtonsPanel = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    borderRadius: '0px 0px 0px 2px',
    display: 'flex',
    alignItems: 'center',
}));

const PageButton = styled(IconButton)(({ theme }) => ({
    height: 10,
    width: 10,
    margin: '0px 0px 0px 15px',
    color: '#fff',
    '&:disabled': {
        color: theme.palette.text.main.dark,
    },
}));

const PageText = styled(Typography)({
    color: '#fff',
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'right',
    width: '100%',
    paddingRight: 10,
});

function PaginatedLegend(props) {
    const { sortedObjs, selectedItemId, pageLimit } = props;
    const { handleItemOnSelect } = props;

    const { opacity } = params;
    
    const [pageNum, setPageNum] = React.useState(1);
    const _nextPage = (event) => {
        setPageNum(pageNum + 1);
    };
    const _prevPage = (event) => {
        setPageNum(pageNum - 1);
    };

    const _itemOnClick = (event, id) => {
        event.stopPropagation();
        handleItemOnSelect(id);
    };

    const _panelOnClick = (event) => {
        handleItemOnSelect(null);
    };

    useEffect(() => {
        if (selectedItemId !== null) {
            const selectedItemIndex = sortedObjs.findIndex(obj => obj.id === selectedItemId);
            setPageNum(Math.ceil((selectedItemIndex + 1)/pageLimit));
        }
    }, [selectedItemId, pageLimit, sortedObjs]);

    return (
        <Root>
            <KeysPanel onClick={_panelOnClick}>
                {
                    sortedObjs.slice((pageNum - 1)*pageLimit, Math.min(sortedObjs.length, pageNum*pageLimit)).map(obj => (
                        <KeyContainer
                            key={obj.label}
                            onClick={(event) => _itemOnClick(event, obj.id)}
                        >
                            <Key style={{ opacity: selectedItemId === null || selectedItemId === obj.id ? 1 : 0.2 }}>{obj.label}</Key>
                            <CircleContainer>
                                <ColourCircle color={obj.color} opacity={selectedItemId === null || selectedItemId === obj.id ? opacity : 0.2} />
                            </CircleContainer>
                        </KeyContainer>
                    ))
                }
            </KeysPanel>
            <ButtonsPanel>
                <PageButton
                    size='small'
                    color='primary'
                    disabled={sortedObjs.length <= pageLimit || pageNum === 1}
                    onClick={_prevPage}
                >
                    <ArrowLeftIcon />
                </PageButton>
                <PageButton
                    size='small'
                    color='primary'
                    disabled={pageNum*pageLimit >= sortedObjs.length}
                    onClick={_nextPage}
                >
                    <ArrowRightIcon/>
                </PageButton>
                <PageText>
                    page {pageNum} of {Math.ceil(sortedObjs.length/pageLimit)}
                </PageText>
            </ButtonsPanel>
        </Root>
    )
}

export default PaginatedLegend;