import React from "react";
import { Grid, Divider } from "@mui/material";
import HeaderWhite from "../home/HeaderWhite";
import Footer from "../Footer";
import FaqContent from "./FaqContent";

const FAQ = () => {
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="external-pages">
              <h2 style={{ marginBottom: 10 }}>FAQ</h2>
              <h1>
                Frequently Asked&nbsp;
                <span
                  style={{
                    color: "#ED6A5A",
                  }}
                >
                  Questions
                </span>
              </h1>
              <div className="text-button-container">
                <p>
                  Below are some of the frequently asked questions we receive.
                  If you have any other questions not answered here, please
                  don’t hesitate to <a href="/contactUs">get in touch</a>.
                </p>
              </div>

              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
              <div
                className="faq-content-container"
                style={{
                  marginTop: "48px",
                  marginBottom: "48px",
                  borderBottom: "rgba(0, 0, 0, 0.12) 1px solid",
                }}
              >
                <FaqContent />
              </div>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
