import React from "react";
import { styled } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
}));

const Question = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  fontWeight: 900,
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
  boxShadow: "none",
  backgroundColor: "none",
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: "0",
}));

export default function SimpleAccordion() {
  return (
    <Root>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Question>1. Is the platform free to use?</Question>
        </StyledAccordionSummary>
        <AccordionDetails>
          <p>
            Yes, the platform is free to use for all users. There are no subscription fees or charges for accessing the platform. In the future, we may introduce premium features that require a subscription (e.g., increased limit on the maximum number of query sequences that can be submitted for TIPars insertion), but the core functionality of the platform will remain available for free.
          </p>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Question>2. How often are the phylogenetic trees updated?</Question>
        </StyledAccordionSummary>
        <AccordionDetails>
          <p>
            For pathogens undergoing rapid dissemination and evolution, their associated phylogenetic trees are updated on a weekly basis to ensure that users have access to the most up-to-date data. For trees involving historical genome data or slower-evolving pathogens, updates are less frequent, typically on a monthly basis or as needed.
          </p>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Question>3. Are there any system requirements for using the platform?</Question>
        </StyledAccordionSummary>
        <AccordionDetails>
          <p>
            There are no specific system requirements for using the platform. The platform can be accessed from any modern web browser, and users do not need to install any additional software. However, for the best performance, we recommend using the latest version of Google Chrome.
          </p>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Question>4. Can I share my visualisation with others?</Question>
        </StyledAccordionSummary>
        <AccordionDetails>
          <p>
            It is currently not possible to share a visualisation with others. However, there is a plan to add a feature that allows users to take screenshots of their visualisation and export it as an image file. This feature is still in development and will be released in future updates.
          </p>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Question>5. Is there a limit to how many query sequences I can upload for TIPars insertion?</Question>
        </StyledAccordionSummary>
        <AccordionDetails>
          <p>
            There is currently a limit of 100 query sequences that can be uploaded for TIPars insertion at one time. Users can however upload multiple batches of query sequences if they have more than 100 sequences to be analysed.
          </p>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Question>6. Should I be concerned about data privacy and security when using the TIPars functionality?</Question>
        </StyledAccordionSummary>
        <AccordionDetails>
          <p>
            Our platform has implemented strict security measures to protect user data and ensure privacy. None of the query sequences uploaded for TIPars insertion are stored on our servers, and only the results of the analysis are retained for up to 48 hours before they are automatically deleted. We do not share user data with any third parties, and all data is encrypted to prevent unauthorised access.
          </p>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Question>7. There is a pathogen that I am interested in, but I cannot find the relevant phylogenetic tree on the platform. What should I do?</Question>
        </StyledAccordionSummary>
        <AccordionDetails>
          <p>
            We are always looking to expand the platform's database of phylogenetic trees. If you cannot find the relevant phylogenetic tree on the platform, please contact the support team with the details of the pathogen you are interested in. We will do our best to add the relevant tree to the platform as soon as possible.
          </p>
        </AccordionDetails>
      </StyledAccordion>
    </Root>
  );
}
