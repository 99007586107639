import React from 'react';
import { useSelector } from 'react-redux';

import PieChart from '../pieChart';
import AgeGenderStacked from './ageGenderStacked';
import FreqChart from '../freqChart';
import helpers from '../../../helpers';
import { Fragment } from 'react';

function Epi(props) {
    const { activeData } = props;

    const colorMaps = useSelector(state => state.staticReducer.meta.maps);

    const continentDist =  helpers.distMapToArray(activeData.dists.continent, colorMaps.continent, activeData.seqNum);
    const countryDist = helpers.distMapToArray(activeData.dists.country, colorMaps.country, activeData.seqNum);
    const ageGenderDist = activeData.dists.ageGender;
    const dateCountryDist = activeData.dists.dateCountry;

    return(
        <div>
            <Fragment>
                <PieChart
                    sortedObjs={continentDist}
                    title='continent'
                />
                <PieChart
                    sortedObjs={countryDist}
                    title='country'
                />
                <AgeGenderStacked
                    data={ageGenderDist}
                />
                <FreqChart
                    title='geo-frequency'
                    data={dateCountryDist}
                    colorMap={colorMaps.continent}
                />
            </Fragment>
        </div>
    )
}

export default Epi;