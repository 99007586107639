import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import TiparsInput from './input';
import TiparsOutput from './output';
import LoadingErrorScreen from '../../../../../loadingError';

function Tipars() {
    const { tiparsOutputSubmissionID, retrieveTiparsOutputData, retrieveTiparsOutputLoading } = useSelector(state => state.tiparsReducer);

    return (
        <Fragment>
            <Fragment>
                {
                    retrieveTiparsOutputData && retrieveTiparsOutputData.id === tiparsOutputSubmissionID ?
                    <TiparsOutput />
                    :
                    <TiparsInput
                        invisible={retrieveTiparsOutputLoading}
                    />
                }
            </Fragment>
            <Fragment>
                {
                    retrieveTiparsOutputLoading ?
                    <LoadingErrorScreen
                        imgWidth={95}
                        padding='17% 1px 10% 0px'
                        darkModeActive={false}
                    />
                    :
                    null
                }
            </Fragment>
        </Fragment>
    )
};

export default Tipars;