// Toggle between tree/rect visual-mode
const toggleVisualMode = (visualMode) => {
    return {
        type: 'TOGGLE_VISUAL_MODE',
        payload: visualMode
    }
};

// Set PHYLO/EPI/TIPARS tab index
const setTabIndex = (tabIndex) => {
    return {
        type: 'SET_TAB_INDEX',
        payload: tabIndex
    }
};

// Toggle downstream accumulation
const toggleDownstreamAggregate = (isActive=null) => {
    return {
        type: 'TOGGLE_DOWNSTREAM_AGGREGATE',
        payload: isActive
    }
};

// Toggle explore map
const toggleExploreMap = (isActive=null) => {
    return {
        type: 'TOGGLE_EXPLORE_MAP',
        payload: isActive
    }
};

// Toggle color legend
const toggleColorLegend = (isActive=null) => {
    return {
        type: 'TOGGLE_COLOR_LEGEND',
        payload: isActive
    }
};

// Switch between available color legends
const setColorLegend = (newColorLegend) => {
    return {
        type: 'SET_COLOR_LEGEND',
        payload: newColorLegend
    }
};

// Toggle branchScale legend
const toggleBranchScale = (isActive=null) => {
    return {
        type: 'TOGGLE_BRANCH_SCALE',
        payload: isActive
    }
};

// Set selected subtree id (for funnelling subtree selection from outside arcVis)
const setSelectedSubtreeId = (subtreeId=null) => {
    return {
        type: 'SET_SELECTED_SUBTREE_ID',
        payload: subtreeId
    }
};

// Reset to default
const resetDefault = () => {
    return {
        type: 'RESET_DEFAULT'
    }
};

//--------------------------------------------------------//
// Graph-related actions
const toggleCentreGraph = () => {
    return {
        type: 'TOGGLE_CENTRE_GRAPH'
    }
};

// Set selected node id
const setSelectedNodeId = (nodeId=null) => {
    return {
        type: 'SET_SELECTED_NODE_ID',
        payload: nodeId
    }
};

// Toggle spotlight
const toggleSpotlight = (isActive=null) => {
    return {
        type: 'TOGGLE_SPOTLIGHT',
        payload: isActive
    }
};

// Toggle dark mode
const toggleDarkMode = (isActive=null) => {
    return {
        type: 'TOGGLE_DARK_MODE',
        payload: isActive
    }
};

// Set go-to node id
const setGoToNodeID = (nodeId=null) => {
    return {
        type: 'SET_GO_TO_NODE_ID',
        payload: nodeId
    }
};

// Set go-to node id on graph render
const setRenderGoToNodeID = (nodeId=null) => {
    return {
        type: 'SET_RENDER_GO_TO_NODE_ID',
        payload: nodeId
    }
};

// Set hovered node id
const setHoveredNodeId = (nodeId=null) => {
    return {
        type: 'SET_HOVERED_NODE_ID',
        payload: nodeId
    }
};

const allActions = {
    toggleVisualMode,
    setTabIndex,
    toggleDownstreamAggregate,
    toggleExploreMap,
    toggleColorLegend,
    setColorLegend,
    toggleBranchScale,
    setSelectedSubtreeId,
    toggleCentreGraph,
    setSelectedNodeId,
    toggleSpotlight,
    toggleDarkMode,
    setGoToNodeID,
    setRenderGoToNodeID,
    setHoveredNodeId,
    resetDefault
};

export default allActions;