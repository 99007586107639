export const params =  {
    root: {
        rootHeight: 175,
        rootWidth: 400,
        margin: {
            top: 25,
            right: 0,
            bottom: 0,
            left: -45,
        }
    },
    lineWidth: 3, // width of branches
    nodeRadius: 5, // radius of nodes A, B and Q
    PnodeRadius: 3, // radius of node P
    nodeBorderWidth: 2, // width of node border (same for all nodes)
    ABLineLength: 100, // length of branch A --> B
    PQLineLength: 185, // length of branch P --> Q
    APBLineColor: '#51a7c2',
    PQLineColor: '#74a26c',
    defaultColor: '#484848',
    nodeFontSize: 10, // fontsize for node labels
    textMaxWidth: 95, // max width of textbox for node Q label
    textPadding: 2, // textbox inner padding
    textBottomPadding: 5, // textbox inner bottom padding
    textLeftMargin: 15, // margin between node A/B and textbox
    textRightMargin: 8, // margin between node Q and textbox
    topPadding: 9, // to give space between node A label so it is not cut off
    leftPadding: 110, // shift horizontally whole schematic
    arrowHeight: 6, // height of arrow triangle
    opacity: 0.9 // opacity of all components (except text)
};