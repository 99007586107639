import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
  
const Root = styled(Box)(({ theme }) => ({
    margin: 'auto',
    textAlign: 'center',
    display: 'inline-block',
    transform: 'translate(0,-5px)',
    cursor: 'auto',
}));

const Text = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.text.main.normal,
    height: 15,
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
    marginTop: 10,
    borderRadius: 2,
    backgroundColor: theme.palette.background.light,
    '& .MuiLinearProgress-bar': {
        borderRadius: 2,
        backgroundColor: theme.palette.background.main,
    },
}));

const Logo = styled('img')(({ theme }) => ({
    width: 120,
    marginBottom: 10,
    opacity: 0.9,
    animation: 'blinker 1500ms linear infinite',
    '@keyframes blinker': {
        '50%': {
            opacity: 0.6,
        },
    },
}));

function LoadingErrorScreen(props) {
    const { fatalError, error, text, imgWidth, padding, opacity, darkModeActive } = props;
    const loading = !(fatalError || error);

    return (
        <Root sx={{ padding, opacity }}>
            {!loading ? (
                <Logo
                    src={process.env.PUBLIC_URL + '/logo-light-theme-error.svg'}
                    alt="logo"
                    sx={{ width: imgWidth, animation: 'none' }}
                />
            ) : (
                <Logo
                    src={
                        process.env.PUBLIC_URL +
                        (darkModeActive ? '/logo-dark-theme.svg' : '/logo-light-theme.svg')
                    }
                    alt="logo"
                    sx={{ width: imgWidth }}
                />
            )}
            <Text sx={{ color: darkModeActive ? '#fff' : null }}>
                {text ??
                    (fatalError
                        ? 'A fatal error has occurred, please refresh and try again.'
                        : error
                        ? 'An error has occurred, please reset to default settings and try again.'
                        : 'Please wait, this should only take a few seconds...')}
                {loading && <ProgressBar />}
            </Text>
        </Root>
    );
}

export default LoadingErrorScreen;
