import React from "react";
import HeaderWhite from "../../landing/home/HeaderWhite";
import { Grid, Divider } from "@mui/material";
import Footer from "../Footer";
import RecentUpdateContent from "./RecentUpdateContent";
import data from "./updateContentData";

export default function RecentUpdate() {
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="external-pages">
              <h2 style={{ marginBottom: 10 }}>RECENT UPDATES</h2>
              <h1>
                Latest <span>changes</span> and upcoming <span>developments</span>.
              </h1>

              <Divider
                style={{
                  marginTop: "36px",
                  marginBottom: "36px",
                }}
              />
              {data.map((a) => (
                <RecentUpdateContent data={a} />
              ))}
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
