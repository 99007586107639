const params = {
    width: 140,
    height: 50,
    arrowHeight: 2,
    arrowWidthProp: 0.8,
    arrowRightOffset: 0,
    arrowTopOffset: -10,
    arrowHeadWidth: 7,
    arrowHeadHeight: 7,
    color: '#233133',
    darkModeColor: '#D5D5D5',
    textFontSize: 12,
    textVerticalOffset: 18, // to account for text height
    textHorizontalOffset: 21, // from arrow
    helperTextFontSize: 10,
    helperTextVerticalOffset: 10, // to account for helper-text height
    helperTextHorizontalOffset: 30,
};

export default params;