import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";

import helpers from '../helpers';

const Title = styled(DialogTitle)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const Description = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    textAlign: 'justify',
    fontSize: 13,
    fontWeight: 700,
    marginBottom: 0,
}));

const Label = styled('span')(({ theme }) => ({
    fontSize: 13,
    display: 'inline',
    textTransform: 'none',
}));

const CloseButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    marginRight: 15,
    fontSize: 14,
    fontWeight: 700,
    padding: '3px 12px',
    color: theme.palette.text.title,
    backgroundColor: theme.palette.background.lighter,
    '&:hover': {
        opacity: 0.8,
    },
}));

const ContactLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.title,
    textDecoration: 'underline'
}));

function HelperInfo(props) {
    const { CustomIconButton } = props;
    const [open, setOpen] = React.useState(false);

    const _onOpen = () => {
        setOpen(true);
    };

    const _onClose = () => {
        setOpen(false);
    };

    const titleStyles = Title.props;
    return (
        <Fragment>
            <CustomIconButton onMouseDown={_onOpen}>
                <HelpOutlineIcon style={{ color: '#fff' }} fontSize="small" />
            </CustomIconButton>
            <Dialog open={open} fullWidth maxWidth="xs">
                <Title style={titleStyles}>
                    Have a question about {helpers.logoText(0.3, titleStyles)}?
                </Title>
                <DialogContent>
                    <Description>
                        If you have any questions, or just want to leave a comment about your experience, please go ahead to <ContactLink to={`/contactUs`} target="_blank" rel="noopener noreferrer">ContactUs</ContactLink>.
                    </Description>
                </DialogContent>
                <DialogActions style={{ paddingBottom: 16 }}>
                    <CloseButton disableRipple onClick={_onClose}>
                        <Label>
                            Close
                        </Label>
                    </CloseButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default HelperInfo;
