import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import ShortTextIcon from '@mui/icons-material/ShortText';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Checkbox from '@mui/material/Checkbox';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';

import allActions from '../../../../redux';

const TreeViewStyled = styled(TreeView)(({ theme }) => ({
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
        display: 'none'
    },
    scrollbarWidth: 'none',
    flexGrow: 1,
    border: `1px solid ${theme.palette.background.main}`,
    borderRadius: 2,
    marginTop: 7,
    marginBottom: 10,
}));

const TreeItemLabel = styled('div')(({ theme, selected, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    backgroundColor: selected ? '#d2d6d6' : 'inherit',
    color: disabled ? "#C5C5C5" : "inherit",
}));

const TreeItemLabelText = styled(Typography)(({ theme }) => ({
    fontWeight: 'inherit',
    fontSize: 11,
    textAlign: "left",
    marginRight: 10,
    maxWidth: 210,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
}));

const TreeItemInfoText = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: 11,
    textAlign: "left",
    marginRight: 0,
    flexGrow: 1,
}));

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
    height: 0,
    width: 0,
    margin: 0,
    marginLeft: -3,
    marginRight: 8,
    color: theme.palette.background.main,
    '&.Mui-checked': {
        color: theme.palette.background.main,
    },
}));

const CustomIndeterminateIcon = styled(IndeterminateCheckBox)(({ theme }) => ({
    color: '#fff',
}));

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
        backgroundColor: `${theme.palette.grey[400]}`,
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
    }
}));

function StyledTreeItem(props) {
    const { labelText, labelIcon: LabelIcon, labelInfo, obj, checked, indeterminate, selected, disabled, ...other } = props;
    const { onLabelClick, clickHandler } = props;

    const _onClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        clickHandler(obj);
    }    

    return (
        <CustomTreeItem
            label={
                <TreeItemLabel selected={selected} disabled={disabled}>
                    <LabelIcon color="inherit" style={{ marginLeft: 5, marginRight: 10, fontSize: 20 }} />
                    <TreeItemLabelText variant="body2">
                        {labelText}
                    </TreeItemLabelText>
                    <TreeItemInfoText variant="caption">
                        {labelInfo}
                    </TreeItemInfoText>
                    {
                        checked !== null ?
                        <StyledCheckBox
                            color='primary'
                            size='small'
                            disableRipple={true}
                            checked={checked}
                            disabled={disabled}
                            indeterminate={indeterminate}
                            indeterminateIcon={<CustomIndeterminateIcon />}
                            onClick={disabled ? null : event => _onClick(event)}
                        />
                        :
                        null
                    }
                </TreeItemLabel>
            }
            // classes={{
            //     root: classes.root,
            //     content: classes.content,
            //     expanded: classes.expanded,
            //     selected: classes.selected,
            //     label: classes.label,
            // }}
            onLabelClick={disabled ? null : onLabelClick}
            {...other}
        />
    );
}

function PlacementsTreeView(props) {
    const dispatch = useDispatch();

    const { queryPassNum, queries } = props;

    const staticMeta = useSelector(state => state.staticReducer.meta);
    const { tiparsPlacementSelected, tiparsAmbiguousQueriesVisible, tiparsUnambiguousQueriesVisible } = useSelector(state => state.tiparsReducer);
        
    const _onPlacementSelect = (placement, query) =>{
        dispatch(allActions.tiparsActions.setTiparsPlacementSelection(
            (tiparsPlacementSelected === null) || (tiparsPlacementSelected && tiparsPlacementSelected.id !== placement.id) ? { ...placement, query: { ...query } } : null));
    };

    const _onPlacementCheckboxSelect = (placementID) => {
        dispatch(allActions.tiparsActions.toggleTiparsPlacementSelection(placementID));
    };

    const _onQueryCheckboxSelect = (query) => {
        const isActive = !query.placements.every(placement => placement.selected);
        dispatch(allActions.tiparsActions.toggleTiparsQuerySelection(query.id, isActive));
    };

    const _onAllPlacementsCheckboxSelect = () => {
        const isActive = !queries.filter(query => (tiparsAmbiguousQueriesVisible && query.placements.length > 1) || (tiparsUnambiguousQueriesVisible && query.placements.length === 1)).every(query => query.placements.every(placement => placement.selected));
        dispatch(allActions.tiparsActions.toggleAllTiparsPlacementsSelection(isActive));
    };

    return (
        <TreeViewStyled
            defaultExpanded={['1', '0']}
            defaultCollapseIcon={<ArrowDropDownIcon style={{ marginLeft: 15 }} />}
            defaultExpandIcon={<ArrowRightIcon style={{ marginLeft: 15 }} />}
            defaultEndIcon={<div style={{ width: 24 }} />}
        >
            <StyledTreeItem
                nodeId="1"
                labelText={`PASSED QUERIES (n=${queryPassNum})`}
                labelIcon={CheckCircleIcon}
                obj={null}
                checked={queries.filter(query => (tiparsAmbiguousQueriesVisible && query.placements.length > 1) || (tiparsUnambiguousQueriesVisible && query.placements.length === 1)).every(query => query.placements.every(placement => placement.selected))}
                indeterminate={!queries.filter(query => (tiparsAmbiguousQueriesVisible && query.placements.length > 1) || (tiparsUnambiguousQueriesVisible && query.placements.length === 1)).every(query => query.placements.every(placement => !placement.selected)) && !queries.filter(query => (tiparsAmbiguousQueriesVisible && query.placements.length > 1) || (tiparsUnambiguousQueriesVisible && query.placements.length === 1)).every(query => query.placements.every(placement => placement.selected))}
                selected={false}
                disabled={!tiparsAmbiguousQueriesVisible && !tiparsUnambiguousQueriesVisible}
                clickHandler={_onAllPlacementsCheckboxSelect}
            >
                {
                    queries.filter(query => query.placements.length > 0).map(query => {
                        var disabled = !((tiparsAmbiguousQueriesVisible && query.placements.length > 1) || (tiparsUnambiguousQueriesVisible && query.placements.length === 1))
                        return (
                            <StyledTreeItem
                                key={query.id}
                                nodeId={`q${query.id}`}
                                labelText={query.name}
                                labelIcon={ShortTextIcon}
                                labelInfo={`(${query.placements.length} BEST HIT${query.placements.length > 1 ? "S" : ""})`}
                                obj={query}
                                checked={query.placements.every(placement => placement.selected)}
                                indeterminate={!query.placements.every(placement => !placement.selected) && !query.placements.every(placement => placement.selected)}
                                disabled={disabled}
                                clickHandler={() => _onQueryCheckboxSelect(query)}
                            >
                                {
                                    query.placements.map(placement => {
                                        return (
                                            <StyledTreeItem
                                                key={placement.id}
                                                labelText={`${placement.aName} - ${placement.bName}`}
                                                labelIcon={LinearScaleIcon}
                                                labelInfo={`(${staticMeta.maps['subtree'][placement.subtree].label})`}
                                                clickIcon={null}
                                                obj={placement}
                                                checked={placement.selected}
                                                indeterminate={null}
                                                selected={tiparsPlacementSelected !== null && placement.id === tiparsPlacementSelected.id}
                                                disabled={disabled}
                                                onClick={disabled ? null : () => _onPlacementSelect(placement, query)}
                                                clickHandler={() => _onPlacementCheckboxSelect(placement.id)}
                                            />
                                        )
                                    })
                                }
                            </StyledTreeItem>
                        )
                    })
                }
            </StyledTreeItem>
            <StyledTreeItem
                nodeId="0"
                labelText={`FAILED QUERIES (n=${queries.length - queryPassNum})`}
                labelIcon={CancelIcon}
                obj={null}
                checked={null}
                clickIcon={null}
            >
                {
                    queries.filter(query => query.placements.length === 0).map(query => {
                        return (
                            <StyledTreeItem
                                key={query.id}
                                labelText={query.name}
                                labelIcon={ShortTextIcon}
                                // labelInfo={query.info}
                                obj={query}
                                checked={null}
                                clickIcon={null}
                            />
                        )
                    })
                }
            </StyledTreeItem>
        </TreeViewStyled>
    );
}

export default PlacementsTreeView;