import React from "react";
import HeaderWhite from "../landing/home/HeaderWhite";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import sarsGreen from "../../images/sars-cov2_green.png";
import sarsOrange from "../../images/sars-cov2_orange.png";
import dengueBlue from '../../images/dengue_blue.png';

const TryNow = () => {
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item md={2} sm={2} xs></Grid>
          <Grid item md={8} sm={8} xs={12}>
            <div className="external-pages">
              <div className="text-container-m">
                <h1
                  style={{
                    fontSize: "5rem",
                  }}
                >
                  <span
                    style={{
                      color: "#ED6A5A",
                    }}
                  >
                    TRY
                  </span>{" "}
                  NOW!
                </h1>
                <div className="text-button-container">
                  <p>
                    Our aim is to provide continually updated reference
                    phylogenies and processed epidemiological data to enable
                    real-time tracking of pathogen evolution and spread.
                    Implementation is currently only available for SARS-CoV-2.
                    Please keep an eye on{" "}
                    <a href="/recentUpdate">RECENT UPDATES</a> for our latest
                    work and upcoming developments!
                  </p>
                </div>
              </div>
              <div className="external-page-divider" />
              <div className="try-now-entry-container">
                <Link
                  to="/sars-cov-2-100k"
                  className="try-now-entry"
                  style={{
                    position: 'relative',
                    backgroundImage: `url(${sarsGreen})`,
                    boxShadow: "0 0 15px rgba(0,0,0,0.5)",
                    borderRadius: 15,
                    opacity: 0.97,
                    backgroundSize: "cover",
                    textDecoration: 'none'
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontSize: 30,
                        fontWeight: 700,
                        position: 'absolute',
                        bottom: 45,
                        marginLeft: 20,
                        opacity: 0.8,
                        color: "white"
                      }}
                    >
                      SARS-CoV-2 [100k]
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 25,
                        fontWeight: 700,
                        position: 'absolute',
                        color: '#00801e',
                        bottom: 195,
                        marginLeft: 330,
                        opacity: 0.8,
                        backgroundColor: "white",
                        lineHeight: 1.4
                      }}
                    >
                      &nbsp;<span style={{ color: '#484848', fontSize: 15 }}>TIPars: </span>ENABLED&nbsp;
                    </Typography>
                    <Typography
                      style={{
                        position: 'absolute',
                        bottom: 15,
                        marginLeft: 20,
                        color: "white",
                        fontSize: 15,
                        fontWeight: 700,
                        opacity: 0.8
                      }}
                    >
                      (LAST UPDATED: 2024-03-10)
                    </Typography>
                  </div>
                </Link>
                <Link
                  to="/sars-cov-2-7m"
                  className="try-now-entry"
                  style={{
                    position: 'relative',
                    backgroundImage: `url(${sarsOrange})`,
                    boxShadow: "0 0 15px rgba(0,0,0,0.5)",
                    borderRadius: 15,
                    opacity: 0.97,
                    backgroundSize: "cover",
                    textDecoration: 'none'
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontSize: 30,
                        fontWeight: 700,
                        position: 'absolute',
                        bottom: 45,
                        marginLeft: 20,
                        opacity: 0.8,
                        color: "white"
                      }}
                    >
                      SARS-CoV-2 [7M]
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 25,
                        fontWeight: 700,
                        position: 'absolute',
                        color: '#b00000',
                        bottom: 195,
                        marginLeft: 330,
                        opacity: 0.8,
                        backgroundColor: "white",
                        lineHeight: 1.4,
                      }}
                    >
                      &nbsp;<span style={{ color: '#484848', fontSize: 15 }}>TIPars: </span>DISABLED&nbsp;
                    </Typography>
                    <Typography
                      style={{
                        position: 'absolute',
                        bottom: 15,
                        marginLeft: 20,
                        color: "white",
                        fontSize: 15,
                        fontWeight: 700,
                        opacity: 0.8
                      }}
                    >
                      (LAST UPDATED: 2024-07-19)
                    </Typography>
                  </div>
                </Link>
              </div>
              <div className="try-now-entry-container" style={{ marginBottom: 50 }}>
                {/* <div
                  className="try-now-entry"
                  style={{
                    backgroundImage: `url(${more})`,
                    boxShadow: "0 0 15px rgba(0,0,0,0.5)",
                    borderRadius: 15,
                    opacity: 1,
                    backgroundSize: "cover",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      position: 'relative',
                      top: "48%",
                      textAlign: "center",
                      color: 'white',
                      opacity: 0.3,
                      fontWeight: 700
                    }}
                  >
                    COMING SOON
                  </Typography>
                </div> */}
                <div
                  className="try-now-entry"
                  style={{
                    position: 'relative',
                    backgroundImage: `url(${dengueBlue})`,
                    boxShadow: "0 0 15px rgba(0,0,0,0.5)",
                    borderRadius: 15,
                    opacity: 0.6,
                    backgroundSize: "cover",
                    textDecoration: 'none'
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontSize: 30,
                        fontWeight: 700,
                        position: 'absolute',
                        bottom: 45,
                        marginLeft: 20,
                        opacity: 0.8,
                        color: "white"
                      }}
                    >
                      DENV [TBD]
                    </Typography>
                    <Typography
                      style={{
                        position: 'absolute',
                        bottom: 15,
                        marginLeft: 20,
                        color: "white",
                        fontSize: 15,
                        fontWeight: 700,
                        opacity: 0.8
                      }}
                    >
                      (COMING SOON)
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};
export default TryNow;
