const params = {
    width: 445, // changing the width does not change the actual width of the chart, but only the apparent sizes of the objects in the chart
    height: 250, // changing the height does change the actual height of the chart
    padding: 15,
    paddingBottom: 20,
    margin: {
        top: 5, // top-margin needed to accommodate max value on y-axis
        right: 5, // right-margin needed to accommodate last tick on x-axis
        bottom: 18, // bottom-margin needed to accommodate x-axis text
    },
    leftMargins: [16, 22, 29, 36, 45], // left-margins for when the maximum value on the y-axis has number of digits: 1, 2, 3, 4, and above
    yAxisValueBreakpoint: 10000, // the number of digits beyond which values on the y-axis switch to scientific notation
    axisColor: '#484848',
    opacity: 0.8,
    hoverOpacity: 0.3,
    xAxisFontSize: 12,
    yAxisFontSize: 11,
    fontWeight: 700,
    fadeInOutDuration: 100,
};

export default params;