import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';

import GlobalTree from './globalTree';
import RectTree from './rectTree';
import ColorLegend from './colorLegend';
import LoadingErrorScreen from '../../../../loadingError';

const Root = styled('div')(({ theme, darkModeActive }) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: darkModeActive ? '#4d4d4d' : theme.palette.background.light, // legacy (#cfd4d4)
    zIndex: 8,
}));

function MainBody(props) {
    const mainBodyRef = React.useRef(null);

    const { fatalError, filteringError, initialLoading, filtersLoading } = props;
    const { staticSubtreeGraph } = useSelector(state => state.filtersReducer);
    const { visualMode, darkModeActive } = useSelector(state => state.visualReducer);

    const [mainBodyDims, setMainBodyDims] = React.useState({
        width: 1400, // initialize width to arbitrary value
        height: 1400 // initialize height to arbitrary value
    });

    const handleOnResize = () => {
        if (mainBodyRef && mainBodyRef.current) {
            setMainBodyDims({
                width: mainBodyRef.current.getBoundingClientRect().width,
                height: mainBodyRef.current.getBoundingClientRect().height,
            });
        }
    };

    useEffect(() => {
        // add event listener on mount
        window.addEventListener('resize', handleOnResize);
        // take width and height measurement on mount
        handleOnResize();

        // remove event listener on cleanup
        return (() => {
            window.removeEventListener('resize', handleOnResize);
        });
    }, []);

    return (
        <Root darkModeActive={darkModeActive} ref={mainBodyRef}>
            <ColorLegend
                filtersLoading={filtersLoading || initialLoading}
                error={fatalError || filteringError}
            />
            {
                !(fatalError || filteringError || initialLoading) &&
                <GlobalTree
                    active={visualMode === 'global'}
                    loading={filtersLoading}
                    width={mainBodyDims.width}
                    height={mainBodyDims.height}
                />
            }
            {
                !(fatalError || filteringError || initialLoading) && staticSubtreeGraph &&
                // !(fatalError || filteringError || initialLoading) &&
                <RectTree
                    active={visualMode === 'rect'}
                    loading={filtersLoading}
                    width={mainBodyDims.width}
                    height={mainBodyDims.height}
                />
            }
            {
                (initialLoading || filtersLoading || fatalError || filteringError) &&
                <LoadingErrorScreen
                    fatalError={fatalError}
                    error={filteringError}
                    imgWidth={120}
                    opacity={filtersLoading ? 0.9 : 1}
                    darkModeActive={darkModeActive}
                />
            }
        </Root>
    );
}

export default MainBody;
