import React from "react";

function OurTeamContent(props) {
  let info = props.info;
  return (
    <div className="member-card">
      <div className="member-img-container">
        <img alt={info.memberName} src={require("" + info.memberImg)} />
      </div>
      <div className="member-info-container">
        <div className="member-text-container">
          <h3>{info.memberName}</h3>
          <span>{info.teamRole}</span>
          <p>{info.memberIntro}</p>
        </div>
        <div className="member-contact-container">
          <p
            style={{
              color: "#64A6A6",
            }}
          >
            {info.memberEmail1}
            <br />
            {info.memberEmail2}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurTeamContent;
