import React from "react";
import "./usecase.css";
import HeaderWhite from "../home/HeaderWhite";
import { Grid, Divider } from "@mui/material";
import Footer from "../Footer";
import step3 from "./imgB/3/3a.png";
import step4 from "./imgB/4/4a.png";
import step5 from "./imgB/5/5a.png";

export default function Usecase2() {
  const context1 = require.context("./imgB/1", true);
  const imagesList1 = context1.keys().map((image) => context1(image));

  const context2 = require.context("./imgB/2", true);
  const imagesList2 = context2.keys().map((image) => context2(image));

  const context6 = require.context("./imgB/6", true);
  const imagesList6 = context6.keys().map((image) => context6(image));
  
  const textList1 = [
    "Since Hong Kong is not listed as an independent country, it cannot be selected through the country-filter. To filter for genome samples from Hong Kong, we have to use the access/name-filter (or keyword-filter) instead. Click the button labelled “accession/name”.",
    "To look up samples with “Hong Kong” in their accessions/names, type “Hong Kong” (case-insensitive) in the search-bar. A list of matching accessions/names should appear in the table below. If these matches meet your selection criteria, press “Enter” to add “Hong Kong” as a keyword. A chip labelled “Hong Kong” should appear in the box at the bottom (you can add a maximum of 20 keywords). Click “Save” to save the current settings and exit.",
  ];
  const textList2 = [
    "Now to filter samples by PANGO lineage assignment, click the button labelled “PANGO_lineage”.",
    "To find only samples belonging to B.1.1.63, we have to first deselect all PANGO lineages (all selected by default) by clicking the white checkbox in the top row.",
    "Once we have done that, we can look up B.1.1.63 using the search-bar (case-insensitive). To select B.1.1.63, simply click the row containing the label. Click “Save” to save the current settings and exit.",
  ];
  const textList3 =
    "Follow same steps as detailed in Example-A Step 2. Check both boxes at the top to ensure that we only include samples with complete collection dates. We also need to adjust the time-window selection to span from 01-07-2020 to 31-10-2020. Once completed, click “Save” to save the current settings and exit.";

  const textList4 =
    "As before, a button labelled “apply pending” at the top of the top-right panel should illuminate, indicating pending filtering options to be applied. Clicking this button will bring up a loading screen, which should only last a few seconds.";

  const textList5 =
    "As in Example-A Step 4, first make sure that you are in bubble-view by clicking the globe icon button in the action-bar above the main view-box. You should notice an illuminated bubble-node in both the main view-box and the mini-map. Click this bubble-node - a loading screen should appear for a few seconds while summary statistics for the bubble-tree are downloaded. To view epidemiological statics, go to the “EPI” panel in the information display dashboard on the right-side of the interface.";
  const textList6 = [
    "To look at the corresponding phylogenetic tree, go to the “PHYLO” panel and click the button labelled “VIEW TREE” at the top. A loading screen should appear briefly in the main view-box.",
    "After a few seconds, a phylogenetic tree should appear in the main view-box. Nodes with sequences matching the filtering criteria (B.1.1.63 outbreak in Hong Kong, July to October 2020) are illuminated and highlighted in red in the mini-map. You can shift + click any illuminated nodes for more details on specific matching samples.",
  ];
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="external-pages">
              <h2 style={{ marginBottom: 10 }}>USE CASE 2</h2>
              <h1>
                Locating genome samples collected during a specific outbreak
              </h1>
              <div className="text-button-container">
                <p>
                  In this example, you will learn how to locate SARS-CoV-2
                  sequences from the third-wave of COVID-19 in Hong Kong, which
                  occurred between July and October 2020. It was found in one
                  study by{" "}
                  <a
                    href="https://www.thelancet.com/journals/lanwpc/article/PIIS2666-6065(21)00039-0/fulltext"
                    target="blank"
                  >
                    Chan et al. (2021)
                  </a>{" "}
                  that the wave was primarily driven by the B.1.1.63 lineage,
                  accounting for 96.6% of the genomes collected at the time.
                </p>
              </div>

              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
              <div className="usecase-content">
                <h3>1. Adding an accession/name-filter</h3>
                {imagesList1.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div className="img-container">
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {textList1[index]}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
              <div className="usecase-content">
                <h3>2. Adding an PANGO-lineage-filter</h3>
                {imagesList2.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div className="img-container">
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {textList2[index]}
                      </div>
                    </p>
                  </div>
                ))}
              </div>

              <div className="usecase-content">
                <h3>3. Adding a collection-date-filter</h3>
                <div className="img-text-container">
                  <p>
                    <div className="img-container">
                      <img key={0} src={step3} alt="0" id="image-0" />
                    </div>
                    <div className="text-container">
                      <span>(1) </span>
                      {textList3}
                    </div>
                  </p>
                </div>
              </div>
              <div className="usecase-content">
                <h3>4. Applying filters</h3>
                <div className="img-text-container">
                  <p>
                    <div className="img-container">
                      <img key={0} src={step4} alt="0" id="image-0" />
                    </div>
                    <div className="text-container">
                      <span>(1) </span>
                      {textList4}
                    </div>
                  </p>
                </div>
              </div>
              <div className="usecase-content">
                <h3>5. Interpreting filtering output (bubble-view)</h3>
                <div className="img-text-container">
                  <p>
                    <div className="img-container big-image">
                      <img key={0} src={step5} alt="0" id="image-0" />
                    </div>
                    <div className="text-container">
                      <span>(1) </span>
                      {textList5}
                    </div>
                  </p>
                </div>
              </div>
              <div className="usecase-content">
                <h3>6. Interpreting filtering output (tree-view)</h3>
                {imagesList6.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div className="img-container">
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {textList6[index]}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
