// export const submissionID_example = "#3wMakdDUZprgFzds4nj422Jd2CKj9J40ukAAxj"; //this should be replaced with an example ID from API
export const submissionID_length = 38; //this should be replaced with an example ID from API
export const textfield_limit = 31000;
export function InputValidate(input) {
    var trimmed = input.trim(); //remove leading and trailing whitespaces and newlines
    var regexSubIDIllegal = new RegExp("[^a-zA-Z0-9-]+", "g");
    var regexQID = new RegExp("^>([a-zA-Z0-9!#$%&'/.+=^_`{}~|-]+)[\\s\\n]+");
    var regexSeqIllegal = new RegExp("[^actguwsmrybdhvnACTGUWSMKRYBDHVN-]+", "g");

    if (!trimmed.length) { //no input
        return 1
    } else if (trimmed.length > textfield_limit) { //input length exceeds maximum limit
        return 2
    }

    if (trimmed.startsWith("#")) { //monitor or retrieve previous submission
        if (trimmed.includes("\n") || trimmed.includes(" ")) { //submission ID contains empty space(s) or newline(s)
            return 4
        } else if (trimmed.slice(1).match(regexSubIDIllegal) !== null) { //submission ID contains illegal character(s)
            return 5
        } else if (trimmed.length < submissionID_length+1) { //submission ID is shorter than expected
            return 6
        } else if (trimmed.length > submissionID_length+1) { //submission ID is longer than expected
            return 7
        }
    } else if (trimmed.startsWith(">")) { //new submission
        if (!trimmed.includes("\n")) { //definition line and sequence have to be separated by at least one newline
            return 8
        }

        var q_id = trimmed.match(regexQID); //no correct query ID found
        if (q_id === null) {
            return 9
        }

        //extract sequence by taking everything after the first newline and removing all spaces and newlines
        var seq = trimmed.slice(trimmed.indexOf("\n") + 1).replace(/\n/ig, "").replace(" ", "");
        if (seq.match(regexSeqIllegal) !== null) { //seq contains illegal character(s)
            return 10
        }

    } else { //input starting with illegal character
        return 3
    }

    return 0
}
export const inputStatusCode = {
    "0": "SUBMIT",
    "1": "Input required.",
    "2": "Maximum length of input has been exceeded.",
    "3": "Illegal input prefix.",
    "4": "Submission ID cannot contain empty space(s).",
    "5": "Submission ID provided contains illegal character(s).",
    "6": `Submission ID provided is shorter than ${submissionID_length} characters.`,
    "7": `Submission ID provided is longer than ${submissionID_length} characters.`,
    "8": "Query ID must be followed by a newline and the corresponding sequence.",
    "9": "Query ID provided contains illegal character(s).",
    "10": "Sequence provided contains illegal character(s)."
}
//ERROR CODE
//0 PASS
//1 NO INPUT
//2 INPUT LENGTH EXCEEDS LIMIT
//3 INPUT STARTS WITH ILLEGAL CHARACTER
//4 SUBMISSION ID CONTAINS EMPTY SPACE(S) OR NEWLINE(S)
//5 SUBMISSION ID CONTAINS ILLEGAL CHARACTER(S)
//6 SUBMISSION ID IS SHORTER THAN EXPECTED
//7 SUBMISSION ID IS LONGER THAN EXPECTED
//8 SEQUENCE MISSING
//9 INCORRECT QUERY ID FORMAT
//10 SEQUENCE CONTAINS ILLEGAL CHARACTER(S)

export function InputType(input) {
    if (input.startsWith('#')) {
        return 'id';
    } else if (input.startsWith('>')) {
        return 'seq';
    }
}