import filtersActions from './filters/actions';
import visualActions from './visual/actions';
import staticActions from './static/actions';
import tiparsActions from './tipars/actions';

const allActions = {
    filtersActions,
    visualActions,
    staticActions,
    tiparsActions
};

export default allActions;