import React from 'react';
import { styled } from '@mui/system';

import Header from './header';
import ActionBar from './actionBar';
import MainBody from './mainBody';

// Define styled components
const Root = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 38px 1fr',
  overflowX: 'hidden',
}));

function LeftPanel(props) {
  return (
    <Root>
        <div style={{ display: 'contents' }}>
            <Header {...props} />
            <ActionBar {...props} />
            <MainBody {...props} />
        </div>
    </Root>
  );
}

export default LeftPanel;
