import React from 'react';
import { styled } from '@mui/system';

import FilteringPanel from './filteringPanel';
import Dashboard from './dashboard';

const Root = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '170px 1fr',
  borderLeft: '2px solid #C6D0CF',
  backgroundColor: theme.palette.background.lighter,
  zIndex: 9,
  boxShadow: "-2px 0px 10px -4px rgb(0, 0, 0, 0.3)",
}));

function RightPanel(props) {
  return (
    <Root>
      <FilteringPanel {...props} />
      <Dashboard {...props} />
    </Root>
  );
}

export default RightPanel;
