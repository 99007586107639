const initialState = {
    activeSubmissions: [],
    tiparsOutputSubmissionID: null,
    // Display of Tipars output
    tiparsVisible: false,
    tiparsAmbiguousQueriesVisible: true,
    tiparsUnambiguousQueriesVisible: true,
    tiparsPlacementSelected: null,
    // Retrieving Tipars output
    retrieveTiparsOutputLoading: false,
    retrieveTiparsOutputError: '',
    retrieveTiparsOutputData: null,
    // Create new Tipars submission through textField input
    submitTiparsTextLoading: false,
    submitTiparsTextError: '',
    // Create new Tipars submission through file upload
    submitTiparsFileLoading: false,
    submitTiparsFileError: ''
}

const tiparsReducer = (state=initialState, action) => {
    switch(action.type) {
        // Submissions check
        case 'START_SUBMISSION_CHECK':
            return {
                ...state,
                activeSubmissions: [
                    {
                        id: action.payload,
                        rank: 0,
                        checkStatus: 1,
                        runStatus: 'n/a',
                        submitTime: 'n/a',
                        expireTime: 'n/a',
                        endTime: 'n/a',
                        runDuration: null,
                        info: 'retrieving...'
                    },
                    ...state.activeSubmissions.filter(submission => submission.id !== action.payload && submission.checkStatus !== 6).map(submission => (
                        { 
                            ...submission,
                            rank: 1
                        }))
                ]
            }
        case 'END_SUBMISSION_CHECK':
            return {
                ...state,
                activeSubmissions: state.activeSubmissions.map(submission => (
                    submission.id === action.payload.id ?
                    {
                        ...action.payload.data,
                        checkStatus: 0,
                        rank: 0
                    }
                    :
                    {
                        ...submission,
                        rank: 1
                    }
                )).sort((submissionA, submissionB) => submissionA.rank - submissionB.rank)
            }
        case 'SUBMISSION_CHECK_ERROR':
            return {
                ...state,
                activeSubmissions: state.activeSubmissions.map(submission => (
                    submission.id === action.payload.id ?
                    {
                        checkStatus: 2,
                        rank: 0,
                        id: action.payload.id,
                        runStatus: 'n/a',
                        submitTime: 'n/a',
                        expireTime: 'n/a',
                        endTime: 'n/a',
                        runDuration: null,
                        info: action.payload.data
                    }
                    :
                    {
                        ...submission,
                        rank: 1
                    }
                )).sort((submissionA, submissionB) => submissionA.rank - submissionB.rank)
            }
        // Remove all active submissions
        case 'REMOVE_ALL_ACTIVE_SUBMISSIONS':
            return {
                ...state,
                activeSubmissions: []
            }
        // Remove specific active submission matching provided submission ID
        case 'REMOVE_ACTIVE_SUBMISSION':
            return {
                ...state,
                activeSubmissions: state.activeSubmissions.filter(submission => submission.id !== action.payload)
            }
        // Move an active submission to top of the list
        case 'MOVE_ACTIVE_SUBMISSION_TOP':
            return {
                ...state,
                activeSubmissions: state.activeSubmissions.map(submission => (
                    {
                        ...submission,
                        rank: submission.id === action.payload ? 0 : 1,
                    }
                )).sort((submissionA, submissionB) => submissionA.rank - submissionB.rank)
            }
        // Set selected submission for output display
        case 'SET_TIPARS_OUTPUT_SUBMISSION_ID':
            return {
                ...state,
                tiparsOutputSubmissionID: action.payload
            }
        // Toggle visibility of Tipars output
        case 'TOGGLE_TIPARS_VISIBILITY':
            return {
                ...state,
                tiparsVisible: action.payload ?? !state.tiparsVisible
            }
        // Toggle visibility of queries with ambiguous placements
        case 'TOGGLE_TIPARS_AMBIGUOUS_QUERIES':
            const newTiparsAmbiguousQueriesVisible = action.payload ?? !state.tiparsAmbiguousQueriesVisible;
            return {
                ...state,
                tiparsAmbiguousQueriesVisible: newTiparsAmbiguousQueriesVisible,
                retrieveTiparsOutputData: {
                    ...state.retrieveTiparsOutputData,
                    results: state.retrieveTiparsOutputData.results.map(query => (
                        {
                            ...query,
                            placements: query.placements.map(placement => (
                                {
                                    ...placement,
                                    selected: query.placements.length > 1 ? (newTiparsAmbiguousQueriesVisible ? true : false) : placement.selected
                                }
                            ))
                        }
                    ))
                }
            }
        // Toggle visibility of queries with unambiguous placements
        case 'TOGGLE_TIPARS_UNAMBIGUOUS_QUERIES':
            const newTiparsUnambiguousQueriesVisible = action.payload ?? !state.tiparsUnambiguousQueriesVisible;
            return {
                ...state,
                tiparsUnambiguousQueriesVisible: newTiparsUnambiguousQueriesVisible,
                retrieveTiparsOutputData: {
                    ...state.retrieveTiparsOutputData,
                    results: state.retrieveTiparsOutputData.results.map(query => (
                        {
                            ...query,
                            placements: query.placements.map(placement => (
                                {
                                    ...placement,
                                    selected: query.placements.length === 1 ? (newTiparsUnambiguousQueriesVisible ? true : false) : placement.selected
                                }
                            ))
                        }
                    ))
                }
            }
        // Set single Tipars placement selection for schematic display
        case 'SET_TIPARS_PLACEMENT_SELECTION':
            return {
                ...state,
                tiparsPlacementSelected: action.payload && state.tiparsPlacementSelected && action.payload.id === state.tiparsPlacementSelected.id ? null : action.payload,
            }
        // Toggle selection of Tipars placement
        case 'TOGGLE_TIPARS_PLACEMENT_SELECTION':
            return {
                ...state,
                retrieveTiparsOutputData: {
                    ...state.retrieveTiparsOutputData,
                    results: state.retrieveTiparsOutputData.results.map(query => (
                        {
                            ...query,
                            placements: query.placements.map(placement => (
                                {
                                    ...placement,
                                    selected: action.payload.placementID === placement.id ? (action.payload.isActive ?? !placement.selected) : placement.selected
                                }
                            ))
                        }
                    ))
                }
            }
        // Toggle selection of all Tipars placements of a given query
        case 'TOGGLE_TIPARS_QUERY_SELECTION':
            return {
                ...state,
                retrieveTiparsOutputData: {
                    ...state.retrieveTiparsOutputData,
                    results: state.retrieveTiparsOutputData.results.map(query => (
                        {
                            ...query,
                            placements: query.placements.map(placement => (
                                {
                                    ...placement,
                                    selected: action.payload.queryID === query.id ? action.payload.isActive : placement.selected
                                }
                            ))
                        }
                    ))
                }
            }
        // Toggle selection of all Tipars placements
        case 'TOGGLE_ALL_TIPARS_PLACEMENTS_SELECTION':
        return {
            ...state,
            retrieveTiparsOutputData: {
                ...state.retrieveTiparsOutputData,
                results: state.retrieveTiparsOutputData.results.map(query => (
                    {
                        ...query,
                        placements: query.placements.map(placement => (
                            {
                                ...placement,
                                selected: query.placements.length === 1 ? (state.tiparsUnambiguousQueriesVisible ? action.payload : placement.selected) : (state.tiparsAmbiguousQueriesVisible ? action.payload : placement.selected)
                            }
                        ))
                    }
                ))
            }
        }
        // Retrieving Tipars output
        case 'START_LOAD_TIPARS_SUBMISSION_OUTPUT':
            return {
                ...state,
                tiparsVisible: false,
                retrieveTiparsOutputLoading: true,
                retrieveTiparsOutputError: '',
                retrieveTiparsOutputData: null,
            }
        case 'END_LOAD_TIPARS_SUBMISSION_OUTPUT':
            return {
                ...state,
                retrieveTiparsOutputLoading: false,
                retrieveTiparsOutputError: '',
                retrieveTiparsOutputData: {
                    ...action.payload,
                    results: action.payload.results.map(query => (
                        {
                            ...query,
                            placements: query.placements.map(placement => (
                                {
                                    ...placement,
                                    selected: true
                                }
                            ))
                        }
                    ))
                },
            }
        case 'LOAD_TIPARS_SUBMISSION_OUTPUT_ERROR':
            return {
                ...state,
                retrieveTiparsOutputLoading: false,
                retrieveTiparsOutputError: action.payload,
                retrieveTiparsOutputData: null,
            }
        // Reset Tipars output
        case 'RESET_TIPARS_SUBMISSION_OUTPUT':
            return {
                ...state,
                tiparsVisible: false,
                retrieveTiparsOutputLoading: false,
                retrieveTiparsOutputError: '',
                retrieveTiparsOutputData: null,
            }
        // Create new TIPars submission through textField input
        case 'START_SUBMIT_TIPARS_TEXT':
            return {
                ...state,
                submitTiparsTextLoading: true,
                submitTiparsTextError: '',
                activeSubmissions: [
                    {
                        id: 'n/a',
                        rank: 0,
                        checkStatus: 5,
                        runStatus: 'n/a',
                        submitTime: 'n/a',
                        expireTime: 'n/a',
                        endTime: 'n/a',
                        runDuration: null,
                        info: 'submitting...'
                    },
                    ...state.activeSubmissions.filter(submission => submission.checkStatus !== 6).map(submission => (
                        { 
                            ...submission,
                            rank: 1
                        }))
                ]
            }
        case 'END_SUBMIT_TIPARS_TEXT':
            return {
                ...state,
                submitTiparsTextLoading: false,
                submitTiparsTextError: '',
                activeSubmissions: [
                    {
                        ...action.payload.data,
                        checkStatus: 0,
                        rank: 0
                    },
                    ...state.activeSubmissions.slice(1)
                ]
            }
        case 'SUBMIT_TIPARS_TEXT_ERROR':
            return {
                ...state,
                submitTiparsTextLoading: false,
                submitTiparsTextError: action.payload,
                activeSubmissions: [
                    {
                        id: 'n/a',
                        rank: 0,
                        checkStatus: 6,
                        runStatus: 'n/a',
                        submitTime: 'n/a',
                        expireTime: 'n/a',
                        endTime: 'n/a',
                        runDuration: null,
                        info: action.payload
                    },
                    ...state.activeSubmissions.slice(1)
                ]
            }
        // Create new TIPars submission through file upload
        case 'START_SUBMIT_TIPARS_FILE':
            return {
                ...state,
                submitTiparsFileLoading: true,
                submitTiparsFileError: '',
                activeSubmissions: [
                    {
                        id: 'n/a',
                        rank: 0,
                        checkStatus: 5,
                        runStatus: 'n/a',
                        submitTime: 'n/a',
                        expireTime: 'n/a',
                        endTime: 'n/a',
                        runDuration: null,
                        info: 'submitting...'
                    },
                    ...state.activeSubmissions.filter(submission => submission.checkStatus !== 6).map(submission => (
                        { 
                            ...submission,
                            rank: 1
                        }))
                ]
            }
        case 'END_SUBMIT_TIPARS_FILE':
            return {
                ...state,
                submitTiparsFileLoading: false,
                submitTiparsFileError: '',
                activeSubmissions: [
                    {
                        ...action.payload.data,
                        checkStatus: 0,
                        rank: 0
                    },
                    ...state.activeSubmissions.slice(1)
                ]
            }
        case 'SUBMIT_TIPARS_FILE_ERROR':
            return {
                ...state,
                submitTiparsFileLoading: false,
                submitTiparsFileError: action.payload,
                activeSubmissions: [
                    {
                        id: 'n/a',
                        rank: 0,
                        checkStatus: 6,
                        runStatus: 'n/a',
                        submitTime: 'n/a',
                        expireTime: 'n/a',
                        endTime: 'n/a',
                        runDuration: null,
                        info: action.payload
                    },
                    ...state.activeSubmissions.slice(1)
                ]
            }
        // Reset to default
        case 'RESET_DEFAULT':
            return initialState;
        default: return state
    }
};

export default tiparsReducer;