import React from "react";
import './usecase.css';
import HeaderWhite from "../home/HeaderWhite";
import { Grid, Divider } from "@mui/material";
import Footer from "../Footer";
import polytomyCollapse from "./imgVisual/polytomy-collapse.png";
import polytomyCollapse2 from "./imgVisual/polytomy-collapse-2.png";
import treeBubbling from "./imgVisual/tree-bubbling.png";
import treeBubbling2 from "./imgVisual/tree-bubbling-2.png";
import nodeNotation from "./imgVisual/node-notation.png";
import nodeNotation2 from "./imgVisual/node-notation-2.png";

export default function TreeVisualisation() {
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="external-pages">
              <h2 style={{ marginBottom: 15 }}>LEARN MORE</h2>
              <h1>Tree Visualisation</h1>
              <div className="text-button-container">
                <p>
                  The unprecedented surge in genomic sequencing efforts since
                  the COVID-19 pandemic has led to an explosion of interest in
                  large-scale phylogenetic analyses, with the potential to
                  capture the complex evolutionary and transmission patterns of
                  rapidly evolving pathogens across multiple spatial scales. To
                  date, there are over 16 million SARS-CoV-2 genomes which have
                  been submitted to the{" "}
                  <a href="https://gisaid.org/" target="blank">
                    GISAID repository
                  </a>{" "}
                  since 2020. With genomic epidemiology playing an ever more
                  central role public health, the volume of genomic data
                  available for pathogens beyond SARS-CoV-2 is expected to
                  increase considerably in the coming decades. <br />
                  <br />
                  Despite growing interest in large-scale phylogenetic analysis,
                  the task of visualising and exploring trees with up to
                  millions of sequences remains a difficult one.
                  State-of-the-art visualisation tools and software often
                  struggle to handle trees with more than a few thousand nodes
                  at a time, particularly on personal computers with limited
                  memory and processing power. The need to perform efficient
                  searches of sequences by metadata with real-time visualisation
                  of the associated phylogenies also presents a unique challenge
                  given the scale of the data. <br />
                  <br />
                  With EiGENO, our goal is to provide researchers and public
                  health workers with the necessary tools to overcome these
                  challenges. Our proposed solution is create a scalable
                  hierarchical tree-structure that allows for robust and
                  intuitive user navigation, while preserving any
                  epidemiologically and evolutionarily meaningful information in
                  the phylogeny. With this in mind, each of our reference
                  phylogenetic trees have to go through a two-step collapsing
                  procedure as detailed below.
                </p>
              </div>

              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
              <div className="usecase-content">
                <h3>1. Collapse ultra-short branches</h3>
                <p>
                  The first step is to collapse any ultra-short branches in the
                  tree, defined as those that are shorter than a threshold of
                  1e-6 substitutions per site. We consider sequences that are
                  connected to a common ancestor by an ultra-short branch to be
                  identical, and that these branches are only present as a
                  result of sequencing errors or other artefacts. This issue is
                  especially prevalent in SARS-CoV-2 trees due to the presence
                  of polytomies, which arise from very dense genomic sampling
                  and targeted sequencing efforts as part of public health
                  responses. Since these ultra-short branches are typically not
                  biologically meaningful or informative, collapsing them can
                  help to reduce the complexity and scale of the tree, making it
                  easier to visualise.
                </p>
                <div className="img-flex">
                  <img src={polytomyCollapse} alt="polytomyCollapse" />
                  <img src={polytomyCollapse2} alt="polytomyCollapse2" />
                </div>
              </div>
              <div className="usecase-content">
                <h3>
                  2. Collapse subtrees involving closely-related sequences
                  (bubbling)
                </h3>
                <p>
                  The second step is referred to as tree-bubbling, where the
                  tree is collapsed into multiple clusters or subtrees according
                  to ancestral lineage annotations. Since the beginning of the
                  COVID-19 pandemic, a number of different lineage labels have
                  been devised to aid in the tracking and classification of the
                  evolution of SARSCoV- 2. One of the most popular and widely
                  used systems is the Phylogenetic Assignment of Named Global
                  Outbreak Lineages nomenclature, also known as PANGO, developed
                  by{" "}
                  <a
                    href="https://www.nature.com/articles/s41564-020-0770-5"
                    target="blank"
                  >
                    Rambaut et al.
                  </a>{" "}
                  in 2020. Compared to other lineage labels, such as the one
                  developed by{" "}
                  <a
                    href="https://nextstrain.org/blog/2020-06-02-SARSCoV2-clade-naming"
                    target="blank"
                  >
                    Nextstrain
                  </a>{" "}
                  or the{" "}
                  <a href="https://www.who.int" target="blank">
                    World Health Organization (WHO)
                  </a>{" "}
                  which are designed primarily to be accessible and
                  interpretatble for the public, the PANGO system provides a
                  more granular picture of the relationships between different
                  lineages in the context of both genetic differences and the
                  epidemiological context of their emergence. For these reasons,
                  the PANGO nomenclature was chosen as our lineage annotation
                  system of choice. <br />
                  <br />
                  Given the PANGO lineage label for each sequence in the tree,
                  the first step is to reconstruct the ancestral lineage
                  annotations for all the internal node using{" "}
                  <a href="https://pastml.pasteur.fr/" target="blank">
                    PastML
                  </a>
                  . The annotated tree is then stratified into multiple clusters
                  or subtrees using a custom algorithm known as TreeBFS, which
                  uses a breadth-first approach to group as many nodes of the
                  same PANGO lineage as possible (up to ~5,000) while ensuring
                  that they form a homophyletic group (see figure below for a
                  visual illustration of the process). The design of this
                  algorithm means that it is possible for there to be multiple
                  clusters or subtrees of the same PANGO lineage (e.g.,
                  B.1.1.7). Note that these collapsed subtrees would remain
                  connected with the same topological structure at the
                  subtree-level.
                </p>
                <div className="img-flex">
                  <img src={nodeNotation} alt="nodeNotation" />
                  <img src={nodeNotation2} alt="nodeNotation2" />
                </div>
              </div>
              <div className="usecase-content">
                <h3>3. Navigating between tree-view and bubble-tree</h3>
                <p>
                  Having performed the above procedure, the reference phylogeny
                  can then be visualised in two view-modes: tree-view and
                  bubble-view. In bubble-view, the user can explore the entire
                  tree at the subtree-level, navigating between different PANGO
                  lineages quickly and efficiently without having to visualise
                  every single sequence in the tree. Bubble-view is particularly
                  useful for examining the broad patterns of epidemiological
                  attributes in the phylogeny, such as the distribution of
                  lineages across different countries or regions, and the
                  relative frequency of different lineages over time. We also
                  recommend using bubble-view for visualising the output of a
                  TIPars analysis as a preliminary step where the user can
                  quickly locate where query sequences have been placed in the
                  tree at the subtree-level.
                  <br />
                  <br />
                  Once the user has identify a cluster of interest, they can
                  switch to tree-view where the relevant subtree is shown at the
                  sequence-level (with collapsed ultra-short branches). In
                  tree-view the user can explore the relationships between
                  individual sequences and their relevant metadata, such as
                  collection date, location, and any other associated
                  epidemiological information. To allow the user to navigate
                  between different clusters in tree-view without losing their
                  place in the tree, we have implemented a feature known as
                  "ghost-nodes". Ghost-nodes are placeholder nodes that are
                  inserted to maintain the topological structure of the tree and
                  to ensure that branches between two nodes that are in two
                  different subtrees are correctly represented. Users can jump
                  between different subtrees in tree-view by clicking on these
                  ghost-nodes, which will automatically expand to show the
                  relevant tree.
                </p>
                <div className="img-flex">
                  <img src={treeBubbling} alt="treeBubbling" />
                  <img src={treeBubbling2} alt="treeBubbling2" />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
