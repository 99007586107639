import React from "react";
import './usecase.css';
import HeaderWhite from "../home/HeaderWhite";
import { Grid, Divider } from "@mui/material";
import Footer from "../Footer";
import partitionNaming from './imgPartitioning/partition-naming.png';

export default function TreePartitioning() {
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12} style={{ marginBottom: 50 }}>
            <div className="external-pages">
              <h2 style={{ marginBottom: 15 }}>LEARN MORE</h2>
              <h1>Tree Partitioning (for SARS-CoV-2 only)</h1>
              <div className="text-button-container">
                <p>
                  To divide a phylogeny into smaller subtrees (or partitions), here we use an approach that clusters sequences belonging to the same&nbsp;
                  <a href="https://cov-lineages.org/">
                    PANGO lineage
                  </a> in the same partition while maintaining the overall tree topology. This ensures that the partitions are evolutionary and epidemiologically meaningful, and that the number of sequences in each partition is of a manageable size for further downstream analysis and visualisations.
                </p>
              </div>
              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
              <div className="usecase-content">
                <h3>How does the clustering algorithm work?</h3>
                <p>
                  <span style={{ fontWeight: 700 }}>1)</span> &nbsp; First, the sequence with the maximum depth (i.e. furthest away from the root node in units of genetic distance) in the tree is identified and selected. We take the PANGO lineage of this sequence is as the majority lineage of the candidate partition currently being considered.
                  <br />
                  <span style={{ fontWeight: 700 }}>2)</span> &nbsp; We then traverse a path from the selected sequence to the root node (of the whole phylogeny) and assign any descendant sequences to the candidate partition. At each iteration, we check <span>(i) if the number of sequences in the partition exceeds a pre-defined threshold</span>, and <span>(ii) if the proportion of sequences in the partition that are of the majority lineage is above a pre-defined threshold</span>.
                  <br />
                  <span style={{ fontWeight: 700 }}>3)</span> &nbsp; If both conditions (i) and (ii) are met, the most-recent common ancestor (MRCA) of the sequences in the partition is identified and taken as the root node of the new partition. This root node, together with all its descendants, are then removed from the tree.
                  <br />
                  <span style={{ fontWeight: 700 }}>4)</span> &nbsp; The sequence with the maximum depth in the remaining tree is selected as the starting point for the next candidate partition.
                  <br />
                  <span style={{ fontWeight: 700 }}>5)</span> &nbsp; If only condition (i) is met at any iteration (but not (ii)), the "foreign" lineage (any lineage that is not the majority lineage in the candidate partition) with the most sequences in the partition is identified. The sequence of this "majority-foreign" lineage with the maximum depth in the tree is then selected, and the algorithm repeats from step 1 with this sequence as the starting sequence.
                  <br /><br />
                  This process is continues until all internal nodes and sequences in the phylogeny are assigned to a partition. There is however an important caveat: in the case where the tree is not fully resolved (i.e. there are polytomies, as is the case of SARS-CoV-2 due to intense sequencing effort), this approach might lead to a large number of partitions, with some partitions containing only a few or small number of sequences. To address this, or at least to minimise the number of partitions with very few sequences, we perform a post-processing step where partitions of the same majority lineage are merged where possible (provided that one partition is a direct descendant of the other).
                </p>
              </div>
              <div className="usecase-content">
                <h3>Partition-naming</h3>
                <p>
                  Each partition is assigned a unique name based on the distribution of PANGO lineages in the partition, following a naming system as summarised in the figure below.
                </p>
                <div className="img-flex-full">
                  <img src={partitionNaming} alt="partitionNaming" />
                </div>
              </div>
              <p>
                Note that partitions with the same name are given a unique identifier (e.g., "(1)", "(2)", etc.) to differentiate them, with the integer in parentheses in no particular order.<br />
                <br />
                As an example (taken from&nbsp;
                <a href="https://www.eigeno.com/sars-cov-2-7m">
                  SARS-CoV-2[7M]
                </a>)
                the partition name <span style={{ fontWeight: 700 }}>BA.2.3.6*[BA.2.3|BA.2.3.18*|BA.5.2.28...(+2)](2)</span> can be interpreted as follows:<br />
                <ul className="li-partition-naming">
                  <li>The majority of the sequences (more than 90% in this case) in the partition belong to the PANGO lineage BA.2.3.6</li>
                  <li>Sequences from the (majority) PANGO lineage BA.2.3.6 account for more than 90% of the sequences of this lineage in the entire global phylogeny</li>
                  <li>There are also sequences from PANGO lineages BA.2.3.18*, BA.5.2.28, BA.5.2.28, and two other lineages in the partition, with decreasing number of sequences going from left to right</li>
                  <li>Sequences from (foreign) PANGO lineage BA.2.3.18 account for more than 90% of the sequences of this lineage in the entire global phylogeny</li>
                  <li>There is <span>at least</span> one other partition with the same name</li>
                </ul>
              </p>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
