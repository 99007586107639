import axios from 'axios';
import visualActions from '../visual/actions';
import filtersActions from '../filters/actions';

const SUBMISSION_CHECK_URL = '/tipars/submission-status/';
const SUBMISSION_OUTPUT_RETRIEVAL_URL = '/tipars/submission-output/';
const TIPARS_SUBMIT_JOB_URL = '/tipars/submit-tipars-text/';

// Check status of previous submission
const checkSubmission = (submissionID, newCancelToken, apiURL) => async (dispatch) => {
    try {
        dispatch({
            type: 'START_SUBMISSION_CHECK',
            payload: submissionID
        });
        const response = await axios.get(apiURL + `${SUBMISSION_CHECK_URL}${submissionID}`, { cancelToken: newCancelToken() });
        dispatch({
            type: 'END_SUBMISSION_CHECK',
            payload: {
                id: submissionID,
                data: response.data
            }
        });
    } catch(error) {
        dispatch({
            type: 'SUBMISSION_CHECK_ERROR',
            payload: {
                id: submissionID,
                data: 'response' in error && 'data' in error.response ? error.response.data.message : error.message
            }
        });
    }
};

// Remove all active submissions
const removeAllActiveSubmissions = () => {
    return {
        type: 'REMOVE_ALL_ACTIVE_SUBMISSIONS'
    }
};

// Remove specific active submission matching provided submission ID
const removeActiveSubmission = (submissionID) => {
    return {
        type: 'REMOVE_ACTIVE_SUBMISSION',
        payload: submissionID
    }
};

// Move an active submission to top of list
const moveActiveSubmissionTop = (submissionID) => {
    return {
        type: 'MOVE_ACTIVE_SUBMISSION_TOP',
        payload: submissionID
    }
};

// Create new submission

// Set ID of submission whose output is to be loaded
const setTiparsOutputSubmissionID = (submissionID) => {
    return {
        type: 'SET_TIPARS_OUTPUT_SUBMISSION_ID',
        payload: submissionID
    }
};

// Toggle visibility of Tipars output
const toggleTiparsVisibility = (isActive=null) => (dispatch) => {
    dispatch(filtersActions.activateFilters(false));
    dispatch({
        type: 'TOGGLE_TIPARS_VISIBILITY',
        payload: isActive
    });
};

// Toggle visibility of queries with ambiguous placements
const toggleTiparsAmbiguousQueries = (isActive=null) => {
    return {
        type: 'TOGGLE_TIPARS_AMBIGUOUS_QUERIES',
        payload: isActive
    }
};

// Toggle visibility of queries with unambiguous placement
const toggleTiparsUnambiguousQueries = (isActive=null) => {
    return {
        type: 'TOGGLE_TIPARS_UNAMBIGUOUS_QUERIES',
        payload: isActive
    }
};

// Toggle selection of Tipars placement
const toggleTiparsPlacementSelection = (placementID, isActive=null) => {
    return {
        type: 'TOGGLE_TIPARS_PLACEMENT_SELECTION',
        payload: {
            placementID: placementID,
            isActive: isActive
        }
    }
};

// Toggle selection of all Tipars placements of a given query
const toggleTiparsQuerySelection = (queryID, isActive) => {
    return {
        type: 'TOGGLE_TIPARS_QUERY_SELECTION',
        payload: {
            queryID: queryID,
            isActive: isActive
        }
    }
};

// Toggle selection of all Tipars placements
const toggleAllTiparsPlacementsSelection = (isActive) => {
    return {
        type: 'TOGGLE_ALL_TIPARS_PLACEMENTS_SELECTION',
        payload: isActive
    }
};

// Set single Tipars placement selection for schematic display
const setTiparsPlacementSelection = (placement) => (dispatch) => {
    placement && dispatch(visualActions.setTabIndex(2));
    dispatch({
        type: 'SET_TIPARS_PLACEMENT_SELECTION',
        payload: placement
    });
};

// Retrieving Tipars output
const loadTiparsSubmissionOutput = (submissionID, newCancelToken, apiURL) => async(dispatch) => {
    try {
        dispatch({
            type: 'START_LOAD_TIPARS_SUBMISSION_OUTPUT',
        });
        const response = await axios.get(apiURL + `${SUBMISSION_OUTPUT_RETRIEVAL_URL}${submissionID}`, { cancelToken: newCancelToken() });
        dispatch({
            type: 'END_LOAD_TIPARS_SUBMISSION_OUTPUT',
            payload: response.data
        });
    } catch(error) {
        console.log(error)
        dispatch({
            type: 'LOAD_TIPARS_SUBMISSION_OUTPUT_ERROR',
            payload: error.message
        });
    }
}

// Reset Tipars output
const resetTiparsSubmissionOutput = () => {
    return {
        type: 'RESET_TIPARS_SUBMISSION_OUTPUT',
    }
};

// Create new Tipars submission through textField input
const submitTiparsText = (inputText, newCancelToken, apiURL) => async (dispatch) => {
    const requestDataJSON = { query_seq_text: inputText };
    try {
        dispatch({
            type: 'START_SUBMIT_TIPARS_TEXT',
        });
        const response = await axios.post(apiURL + TIPARS_SUBMIT_JOB_URL, requestDataJSON, {
            headers: {
                'Content-Type': 'application/json'
            },
            cancelToken: newCancelToken()
        });
        dispatch({
            type: 'END_SUBMIT_TIPARS_TEXT',
            payload: response
        });
    } catch(error) {
        dispatch({
            type: 'SUBMIT_TIPARS_TEXT_ERROR',
            payload: 'response' in error && 'data' in error.response ? error.response.data.message : error.message
        });
    }
};

// Create new Tipars submission through file upload
const submitTiparsFile = (fileUpload, newCancelToken, apiURL) => async (dispatch) => {
    const requestData = new FormData();
    requestData.append('query_seqs_file', fileUpload);
    try {
        dispatch({
            type: 'START_SUBMIT_TIPARS_FILE',
        });
        const response = await axios.post(apiURL + TIPARS_SUBMIT_JOB_URL, requestData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            cancelToken: newCancelToken()
        });
        dispatch({
            type: 'END_SUBMIT_TIPARS_FILE',
            payload: response
        });
    } catch(error) {
        dispatch({
            type: 'SUBMIT_TIPARS_FILE_ERROR',
            payload: 'response' in error && 'data' in error.response ? error.response.data.message : error.message
        });
    }
}

// reset to default
const resetDefault = () => {
    return {
        type: 'RESET_DEFAULT'
    }
}

//--------------------------------------------------------//

const allActions = {
    checkSubmission,
    removeAllActiveSubmissions,
    removeActiveSubmission,
    moveActiveSubmissionTop,
    setTiparsOutputSubmissionID,
    toggleTiparsVisibility,
    toggleTiparsAmbiguousQueries,
    toggleTiparsUnambiguousQueries,
    toggleTiparsPlacementSelection,
    toggleTiparsQuerySelection,
    toggleAllTiparsPlacementsSelection,
    setTiparsPlacementSelection,
    loadTiparsSubmissionOutput,
    resetTiparsSubmissionOutput,
    submitTiparsText,
    submitTiparsFile,
    resetDefault
};

export default allActions;