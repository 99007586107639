import React from "react";
import "./usecase.css";
import HeaderWhite from "../home/HeaderWhite";
import { Grid, Divider } from "@mui/material";
import Footer from "../Footer";
import step2A from "./imgD/2/2a.png";
import step2B from "./imgD/2/2b.png";

export default function Usecase4() {
  const context1 = require.context("./imgD/1", true);
  const imagesList1 = context1.keys().map((image) => context1(image));
  
  const textList1 = [
    "Let’s start by first exploring the results table, where you will find the TIPars results for each query sequence. This table is organised into two subsections: the first labelled “PASSED QUERIES” lists all query sequences that met the quality control thresholds and with valid results; the second labelled “FAILED QUERIES” includes query sequences that either failed to pass quality control or failed to produce valid results. In the “PASSED QUERIES” subsection, each row is labelled with the name of a query sequence and the number of best hits found by TIPars (number of existing branches to which the query sequence was inserted with the same highest score). Selecting one of the best hits will trigger an update to the TIPars schematic (see below). At the end of each row is a checkbox enabling the selection of either specific best hits or all best hits of a given query sequence for visualisation purposes (see below).\nLocated above the results table are two checkboxes enabling the filtering of results to show either only query sequences with ambiguous placements(queries with multiple best hits), and / or query sequences with unambiguous placements(queries with a single best hit). By default, both checkboxes are selected to show all query sequences.",
    "If you click on one of the best hits in the results table, what the TIPars schematic now shows is a visual representation of where the query sequence was inserted in the reference phylogeny. The blue vertical line represents the existing branch where the insertion occurs, with the circle at the top and bottom denoting the parental and descendant node, respectively (referred to as node “A” and “B” in the original formulation in Yongtao et al.). The green horizontal line represents the new branch introduced by the insertion, with the circle on the left marking the insertion point and the circle on the right the query sequence. Next to each branch is a numeric value indicating the branch length in units of number of substitutions per site. Note that the lines representing the branches are not drawn to scale and therefore do not reflect the actual branch lengths.",
    "At the bottom of the page you will find a pie chart that summarises the distribution of query sequence insertions in the reference phylogeny across different subtrees (please refer to #a(url)#a for more information on the definition of a subtree). When you select/deselect any of the checkboxes in the results table, the pie  chart will refresh dynamically to reflect the selected data.",
    'Finally, at the top of the page next to the section where you will find general information about the submission, is a button labelled "VISUALISE OUTPUT". Clicking this button triggers the visualisation of TIPars results in the main view-box (both in bubble-view and tree-view, see below).',
  ];
  const textList2 =
    "First, make sure that you are in bubble-view by clicking the globe icon button in the action-bar above the main view-box. Also open the mini-map by clicking button labelled “explore map”. If you now click the “VISUALISE OUTPUT” button in the TIPars tab, you will observe certain nodes becoming highlighted in both the main view-bow and the mini-map. The highlighted nodes represent the subtrees (or bubble-trees) where the selected query sequences have been inserted in the reference phylogeny according to TIPars.";
  
    return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="external-pages">
              <h2 style={{ marginBottom: 10 }}>USE CASE 4</h2>
              <h1>Interpreting TIPars results</h1>
              <div className="text-button-container">
                <p>
                  In this example, you will learn how to interpret the results
                  of a TIPars submission. For more technical details on how
                  TIPars works, please refer to{" "}
                  <a
                    href="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1011871"
                    target="blank"
                  >
                    Robust expansion of phylogeny for fast-growing genome
                    sequence data
                  </a>{" "}
                  (Ye et al., 2024).
                </p>
              </div>

              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
              <div className="usecase-content">
                <h3>1. What will you find in the results?</h3>
                <p>
                  Following from the previous example{" "}
                  <a href="Usecase3">Submitting a TIPars job</a>, clicking the
                  "RETRIEVE" button will open a new page in the same TIPars tab.
                  This page is organised into four sections:
                  <span>(1)</span> general details about the submission,
                  <span>(2)</span> a schematic visual representation of the
                  analysis outcome for a selected query sequence,
                  <span>(3)</span> a table containing the results for all query
                  sequences, and
                  <span> (4)</span> a graphical summary of how the query
                  sequences are distributed among the different subtrees in the
                  reference phylogeny.
                </p>
                {imagesList1.map((image, index) => (
                  <div className="img-text-container">
                    <p>
                      <div className="img-container">
                        <img
                          key={index}
                          src={image}
                          alt={`${index}`}
                          id={`image-${index}`}
                        />
                      </div>
                      <div className="text-container">
                        <span>{"(" + (index + 1) + ") "}</span>
                        {index === 0
                          ? textList1[index].split("\n").slice(0, index + 1)
                          : textList1[index].split("#a").slice(0, 1)}
                        {index === 0 ? <br /> : null}
                        {index === 0 ? <br /> : null}
                        {index === 2 ? (
                          <a href="/TreeVisualisation">Tree Visualisation</a>
                        ) : null}
                        {index === 0
                          ? textList1[index].split("\n").slice(1, index + 2)
                          : textList1[index].split("#a").slice(2, 3)}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
              <div className="usecase-content">
                <h3>5. Interpreting filtering output (bubble-view)</h3>
                <div className="img-text-container">
                  <p>
                    <div className="img-container">
                      <img key={0} src={step2A} alt="0" id="image-0" />
                    </div>
                    <div className="text-container">
                      <span>(1) </span>
                      {textList2}
                    </div>
                  </p>
                </div>
                <div className="img-text-container">
                  <p>
                    <div className="img-container">
                      <img key={1} src={step2B} alt="1" id="image-1" />
                    </div>
                    <div className="text-container">
                      <span>(2) </span>
                      To see more precisely where the insertions occur, we can
                      switch to tree-view by following the same steps as
                      detailed in a <a href="/Usecase2">previous tutorial</a> .
                      Once in treeview, we can quickly locate where the
                      insertion points are using the mini-map where they are
                      marked by a red cross. By clicking on one of the red
                      crosses in the mini-map, the main view-box will zoom in on
                      the specific branch in the phylogeny where the point of
                      insertion is again marked by a red cross, with its
                      position drawn to scale reflecting its exact location on
                      the branch.
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
