import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

import InputBox from './inputBox';
import UploadDialog from './uploadDialog';
import SubmissionHistory from './submissionHistory';
import { submissionID_length } from './inputValidation';

const MAX_SUBMISSION_NUM = 20;

const Root = styled(Paper)(({ theme }) => ({
    borderRadius: 2,
    backgroundColor: theme.palette.background.lighter,
    margin: 10,
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.dark}`,
    textAlign: 'right',
    padding: '8px 15px',
    color: '#fff',
    borderRadius: '2px 2px 0px 0px'
}));

const Title = styled(Typography)({
    display: 'inline',
    float: 'left',
    fontSize: 14,
    fontWeight: 700
});

const InnerMain = styled('div')({
    padding: '8px 15px',
});

const Description = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.text.normal,
    textAlign: 'left',
}));

const ExampleLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 3,
    textDecoration: 'underline'
}));

function TiparsInput(props) {
    const { invisible } = props;

    const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
    const handleUploadDialogOpen = () => {
        setUploadDialogOpen(true);
    };
    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    };

    const [fileUploaded, setFileUploaded] = React.useState(null);
    const handleFileUpload = (event) => {
        setFileUploaded(event.target.files[0]);
    };

    const [fileSubmitted, setFileSubmitted] = React.useState(null);
    const handleFileSubmit = (reset=false) => {
        reset ? setFileSubmitted(null) : setFileSubmitted(fileUploaded);
    };

    const [useExample, setUseExample] = React.useState(null);
    const triggerUseExample = () => {
        setUseExample(!useExample);
        setFileUploaded(null);
        setFileSubmitted(null);
    };

    const boldText = (string) => {
        return <span style={{ fontWeight: 700 }}>{string}</span>;
    };

    return (
        <Root elevation={1} style={{ display: invisible ? 'none': null }}>
            <Grid container spacing={0}>
                <TitleContainer item xs={12}>
                    <Title>
                        submit to TIPars
                    </Title>
                </TitleContainer>
                <InnerMain>
                    <Description>
                        To retrieve results or to monitor progress of a previous submission, enter the corresponding submission ID {boldText(`(# followed by ${submissionID_length} characters)`)}.<br />
                        Try&nbsp;
                        <ExampleLink
                            component="button"
                            onClick={triggerUseExample}
                        >
                            SARS-CoV-2|example|30June2023
                        </ExampleLink>
                        &nbsp;to see how it works.<br />
                        And to submit a new query, either:<br />
                        {boldText("1) paste a single nucleotide sequence into the textbox below, or")}<br />
                        {boldText("2) upload one or more sequences in a file.")}
                        <br />
                    </Description>
                    <InputBox
                        fileSubmitted={fileSubmitted}
                        useExample={useExample}
                        handleUploadDialogOpen={handleUploadDialogOpen}
                        handleFileSubmit={handleFileSubmit}
                        setFileUploaded={setFileUploaded}
                        maxSubmissionsNum={MAX_SUBMISSION_NUM}
                    />
                    <UploadDialog
                        open={uploadDialogOpen}
                        handleUploadDialogClose={handleUploadDialogClose}
                        fileUploaded={fileUploaded}
                        handleFileUpload={handleFileUpload}
                        fileSubmitted={fileSubmitted}
                        handleFileSubmit={handleFileSubmit}
                        setFileUploaded={setFileUploaded}
                    />
                    <SubmissionHistory
                        maxSubmissionsNum={MAX_SUBMISSION_NUM}
                    />
                </InnerMain>
            </Grid>
        </Root>
    )
};

export default TiparsInput;