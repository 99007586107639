import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios, { CancelToken, isCancel } from 'axios';
import debounce from 'lodash/debounce';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';

import allActions from '../../redux';

const SEARCH_SEQS_URL = '/seq/search-seqs/?simple=false';
const TOP_NUM = 40;

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.background.main,
    },
    '& .MuiInput-underline:hover:before': {
        borderBottomColor: theme.palette.background.main,
    },
    '& .MuiInput-underline:hover:after': {
        borderBottomColor: theme.palette.background.main,
    },
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    display: 'inline',
    color: theme.palette.background.main,
}));

const Option = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.text.title,
}));

const HelperText = styled(Typography)(({ theme, active }) => ({
    fontSize: 11,
    color: active ? theme.palette.text.main.normal : '#B6B7B9'
}));

const SnackBarContent = styled('div')(({ theme }) => ({
    fontSize: 13,
    fontWeight: 700,
    background: '#fff',
    minWidth: 0,
    color: theme.palette.text.main.normal,
    padding: '0px 5px',
  }));
  
const CloseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.title,
}));

function SeqSearch(props) {
    const { keywordsNumMax } = props;
    const dispatch = useDispatch();
    const { disabled } = props;
    const inputBox = React.createRef();
    const axiosRef = React.useRef(null);

    const { apiURL } = useSelector(state => state.staticReducer);
    const [loading, setLoading] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [dataFetchSuccess, setDataFetchSuccess] = React.useState(false);
    const [searchOutput, setSearchOutput] = React.useState([]);

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    const _onSeqSearch = async (newInput, apiURL) => {
        // cancel previous axios request if exists
        axiosRef.current && axiosRef.current.cancel();

        // get request
        try {
            const response = await axios.get(apiURL + `${SEARCH_SEQS_URL}&top_num=${TOP_NUM}&ss=${newInput}`, { cancelToken: newCancelToken() });

            setSearchOutput(response.data.results);
            setDataFetchSuccess(true);

            setLoading(false);
        } catch (error) {
            // if get request was cancelled, skip error handling
            if (isCancel(error)) return;

            // handle error here
            setErrorMessage('An error has occured, please try again later.');
            console.error(error);

            // set loadTimer to null if not null
            setLoading(false);
        }
    };

    const debounceSeqSearch = React.useCallback(debounce((searchString) => _onSeqSearch(searchString, apiURL), 800), [apiURL]); // eslint-disable-line react-hooks/exhaustive-deps

    const _onInputChange = (event) => {
        setLoading(true);
        setSearchOutput([]);

        const newInput = event.target.value;
        errorMessage.length && setErrorMessage('');
        setDataFetchSuccess(false);

        // don't debounce if data was fetched successfully on previous input and no results were returned
        if (dataFetchSuccess && !searchOutput.length) {
            _onSeqSearch(newInput, apiURL);
        } else {
            debounceSeqSearch(newInput);
        }
    };

    const _onInputFocus = () => {
        inputBox.current !== null && inputBox.current.select();
    };

    const [seqSelected, setSeqSelected] = React.useState(null);
    const _onSelect = (event, seq) => {
        setSeqSelected(seq);
    };
    const _onSeqAdd = () => {
        setSnackbarOpen(true);
        dispatch(allActions.filtersActions.addKeyword(seqSelected.accession, keywordsNumMax));
        dispatch(allActions.filtersActions.setApiFilteringParams());
    };

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const _snackbarOnClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // const InputProps = {
    //     options: searchOutput,
    //     getOptionLabel: (obj) => `${obj.accession}/${obj.name}`,
    //     classes: {
    //         input: classes.searchTextField,
    //         clearIndicator: classes.searchClearIcon,
    //         popupIndicator: classes.popupIcon,
    //         listbox: classes.text,
    //         noOptions: classes.text,
    //         loading: classes.text,
    //         option: classes.option
    //     },
    // };

    useEffect(() => {
        _onSeqSearch('', apiURL);

        return (() => {
            // cancel axios request if exists
            axiosRef.current && axiosRef.current.cancel();
        });
    }, [apiURL]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (seqSelected === null) {
            _onSeqSearch('', apiURL);
        }
    }, [seqSelected, apiURL]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container style={{ marginTop: 10 }} spacing={0}>
            <Grid item xs={1}>
                <IconButtonStyled
                    disabled={disabled || seqSelected === null}
                    size='small'
                    disableRipple={true}
                    onMouseDown={_onSeqAdd}
                >
                    <AddIcon />
                </IconButtonStyled>
            </Grid>
            <Grid item xs={11}>
                <Autocomplete
                    disabled={disabled}
                    clearOnBlur
                    autoHighlight
                    options={searchOutput}
                    getOptionLabel={(option) => `${option.accession}/${option.name}`}
                    loading={loading}
                    onChange={_onSelect}
                    sx={{
                        display: 'inline',
                        '& input': {
                            fontSize: 13,
                            color: theme => theme.palette.text.main.normal,
                        },
                        "& button.MuiButtonBase-root" : {
                            visibility: "visible",
                            background: 'transparent',
                        }                      
                    }}
                    renderOption={(props, option) => (
                        <Option {...props}>{`${option.accession}/${option.name}`}</Option>
                      )}                    
                    renderInput={(params) => 
                        <CustomTextField
                            {...params}
                            label=''
                            variant='standard'
                            placeholder='e.g. EPI_ISL_000000/hCoV-20/country_name/AAABBB/2020'
                            onChange={_onInputChange}
                            onFocus={_onInputFocus}
                        />
                    }
                />
                <HelperText active={seqSelected !== null}>
                    (only the top {TOP_NUM} search results are shown)
                </HelperText>
            </Grid>
            {
                seqSelected === null ?
                null :
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={_snackbarOnClose}
                    message={<SnackBarContent>{`${seqSelected.accession} added to search keywords`}</SnackBarContent>}
                    action={
                        <CloseButton size="small" onClick={_snackbarOnClose}>
                            <CloseIcon fontSize="small" />
                        </CloseButton>
                    }
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            minWidth: 0,
                            backgroundColor: '#fff'
                        },
                    }}    
                />
            }
        </Grid>
    );
}

export default SeqSearch;