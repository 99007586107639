import React, { useEffect } from "react";
import * as d3 from "d3";

import params from './params';

function Pie(props) {
    const svgRef = React.useRef(null);

    const { name, data, width, selectedRef, selectedItemId } = props;
    const { handleItemOnSelect, setItemInfo } = props;

    const {
        outerRadius,
        innerRadiusProp,
        padding,
        opacity,
        hoverOpacity,
        fadeInOutDuration,
    } = params;

    useEffect(() => {
        if (selectedItemId !== null) {
            d3.selectAll(`.${name}-arc`)
                .each(function(node) {
                    const active = node.data.id === selectedItemId;
                    d3.select(`#${name}-arc-${node.data.id}`)
                        .transition(`${node.data.id}`)
                        .duration(fadeInOutDuration)
                        .style('fill-opacity', active ? 1 : hoverOpacity);
                });
            
            d3.selectAll(`.${name}-arc`)
                .filter(node => node.data.id === selectedItemId)
                .each(function(node) {
                    const selectedPercent = Math.round(node.data.percent*100)/100;
                    const itemInfo = `${node.data.label}, n=${node.data.value} (${selectedPercent}%)`;
                    setItemInfo(itemInfo);
                })
        } else {
            setItemInfo('');
            d3.selectAll(`.${name}-arc`)
                .transition('fade-in')
                .duration(fadeInOutDuration)
                .style('fill-opacity', 1);
        }
    }, [selectedItemId, name, setItemInfo, hoverOpacity, fadeInOutDuration]);

    useEffect(() => {
        drawChart();
        const svg = svgRef.current;

        return (() => {
            d3.select(svg).remove();
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const highlightArc = (id) => {
        d3.selectAll(`.${name}-arc`)
            .each(function(node) {
                const active = node.data.id === id;
                d3.select(`#${name}-arc-${node.data.id}`)
                    .transition(`${node.data.id}`)
                    .duration(fadeInOutDuration)
                    .style('fill-opacity', id === null || active ? 1 : hoverOpacity);
            });
    }

    const drawChart = () => {

        // Prepare data
        const createPie = d3.pie().value(d => d.value);
        const pieData = createPie(data);

        // Create svg
        const svg = d3
            .select(svgRef.current)
            .attr('viewBox', `-${outerRadius} -${outerRadius} ${outerRadius*2} ${outerRadius*2}`)
            .style('width', width)
            .style('height', width)
            .style('background', 'transparent')
            .style('opacity', opacity);

        const unClick = () => {
            handleItemOnSelect(null);
            setItemInfo('');
        };

        const hoverClick = (d) => {
            if (selectedRef.current === null) {
                highlightArc(d && d.data.id);

                if (d === null) {
                    setItemInfo('');
                } else {
                    const selectedPercent = Math.round(((d.endAngle - d.startAngle)*10000)/(2*Math.PI))/100;
                    const itemInfo = `${d.data.label}, n=${d.data.value} (${selectedPercent}%)`;
                    setItemInfo(itemInfo);
                }
            }
        };

        d3.select(`#${name}-pie-container`).on('click', () => {
            unClick();
        });

        const arc = d3
            .arc()
            .innerRadius((outerRadius - padding)*innerRadiusProp)
            .outerRadius(outerRadius - padding);

        svg
            .append('g')
            .selectAll('path')
            .data(pieData)
            .join('path')
            .sort((a, b) => b.value - a.value)
            .attr('fill', d => d.data.color)
            .attr('stroke-width', 0)
            .attr('d', arc)
            .attr('class', `${name}-arc`)
            .attr('id', (d) => `${name}-arc-${d.data.id}`)
            .attr('cursor', 'pointer')
            .attr('display', d => (d.endAngle - d.startAngle)/(2*Math.PI) > 0.0001 ? 'unset' : 'none')
            .on('mouseout', () => hoverClick(null))
            .on('mouseover', (event, d) => hoverClick(d))
            .on('click', function (event, d) {
                handleItemOnSelect(d.data.id);
                event.stopPropagation();
            });
    };

    return (
        <div id={`${name}-pie-container`}>
            <svg style={{ width: width, height: width }} ref={svgRef} />
        </div>
    );
};

export default Pie;