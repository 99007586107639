import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { CancelToken } from 'axios';

import allActions from '../redux';
import HelperInfo from './helperInfo';
import CopiedToClipboard from '../copyToClipboard';
import TakeScreenshot from './takeScreenshot';
import ExitWarning from './exitWarning';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  borderRadius: '0px 0px 2px 2px',
  color: '#fff',
  lineHeight: '200%',
  zIndex: 8,
  boxShadow: "0px -3px 5px 0px rgba(0, 0, 0, 0.3)",
}));

const LeftGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: 10,
  textAlign: 'left',
}));

const RightGrid = styled(Grid)(({ theme }) => ({
  paddingRight: 10,
  textAlign: 'right',
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  size: 'small',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

function Footer(props) {
  const dispatch = useDispatch();
  const axiosRef = React.useRef(null);
  const newCancelToken = () => {
    axiosRef.current = CancelToken.source();
    return axiosRef.current.token;
  };

  const { initialLoading, filtersLoading, globalDataFilteringDisabled, apiURL } = props;
  const reloadDisabled =  initialLoading || filtersLoading;

  const [open, setOpen] = React.useState(false);

  const _onOpen = () => {
      setOpen(true);
  };

  const confirmExit = () => {
      setOpen(false);
      _appReload();
  };

  const rejectExit = () => {
      setOpen(false);
  };

  const _appReload = () => {
    // reset store to default
    dispatch(allActions.filtersActions.resetDefault());
    dispatch(allActions.tiparsActions.resetDefault());
    dispatch(allActions.staticActions.resetDefault());
    dispatch(allActions.visualActions.resetDefault());
      
    dispatch(allActions.staticActions.setApiURL(apiURL));
    dispatch(allActions.filtersActions.toggleGlobalFilteredDataAggregation(!globalDataFilteringDisabled));
    dispatch(allActions.staticActions.loadMeta(newCancelToken, apiURL));
    dispatch(allActions.staticActions.loadGlobalData(newCancelToken, apiURL));
  };

  return (
    <Root>
      <Grid container spacing={0}>
        <LeftGrid item xs={6}>
          <TakeScreenshot />
          <CopiedToClipboard
            CustomIconButton={CustomIconButton}
            message='URL to EiGENO copied to clipboard'
            textToCopy='https://www.eigeno.com'
          >
            <LinkRoundedIcon style={{ color: '#fff' }} fontSize='small' />
          </CopiedToClipboard>
          <CustomIconButton onClick={reloadDisabled ? null :  _onOpen}>
            <ReplayRoundedIcon style={{ color: reloadDisabled ? 'none' : '#fff' }} fontSize='small' />
          </CustomIconButton>
          {
            open &&
            <ExitWarning
                open={open}
                confirmHandler={confirmExit}
                rejectHandler={rejectExit}
            />
          }
        </LeftGrid>
        <RightGrid item xs={6}>
          <CustomIconButton
            href="https://www.d24h.hk/"
            target="_blank" 
            rel="noopener noreferrer"
            style={{ cursor: 'pointer', fontSize: 15, lineHeight: 1.3, color: 'white' }}
            disableRipple={true}
          >
            D<var><sup>2</sup></var>4H
          </CustomIconButton>
          <CustomIconButton
            href="https://www.med.hku.hk/"
            target="_blank" 
            rel="noopener noreferrer"
            style={{ cursor: 'pointer', fontSize: 15, lineHeight: 1.3, color: 'white' }}
            disableRipple={true}
          >
            HKU-Med
          </CustomIconButton>
          <HelperInfo CustomIconButton={CustomIconButton} />
        </RightGrid>
      </Grid>
    </Root>
  );
}

export default Footer;
