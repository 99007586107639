import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

import allActions from '../../../redux';
import MutTable from './mutTable';

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const Description = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 13,
    fontWeight: 700,
    marginBottom: 0,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    marginRight: 15,
    fontSize: 14,
    fontWeight: 700,
    padding: '3px 12px 3px 12px',
    color: theme.palette.text.title,
}));

const ResetButton = styled(StyledButton)(({ theme }) => ({
    borderColor: "#fff",
    "&:hover": {
        backgroundColor: 'transparent',
    },
    padding: 10,
    paddingRight: 0,
}));

const SaveButton = styled(StyledButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.lighter,
    "&:hover": {
        opacity: 0.8
    },
    marginBottom: 7,
    marginTop: 8,
}));

const Label = styled('span')({
    fontSize: 13,
    display: "inline",
    textTransform: 'none',
});

function MutationSelect(props) {
    const dispatch = useDispatch();

    const { active, disabled, mutsDisabled, resetTrigger, inputPlaceholder, pageLimit, mutsNumMax, buttonLabel, dialogTitle, dialogDescription } = props;
    const { ButtonComponent } = props;

    const staticMeta = useSelector(state => state.staticReducer.meta);
    const savedMuts = useSelector(state => state.filtersReducer.muts);

    const [open, setOpen] = React.useState(false);
    const _onOpen = () => {
        setPreSaveMuts(savedMuts);
        setOpen(true);
    };
    const _onClose = () => {
        setOpen(false);
    };

    const [preSaveMuts, setPreSaveMuts] = React.useState();
    const [preSaveMutMode, setPreSaveMutMode] = React.useState('any');
    const handleMutModeOnChange = (event) => {
        setPreSaveMutMode(event.target.value);
    };
    const _onReset = () => {
        setPreSaveMuts([]);
        setPreSaveMutMode('any');

        dispatch(allActions.filtersActions.saveMuts([]));
        dispatch(allActions.filtersActions.setMutMode('any'))
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };
    const _onSave = () => {
        dispatch(allActions.filtersActions.saveMuts(preSaveMuts));
        dispatch(allActions.filtersActions.setMutMode(preSaveMutMode))
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };

    useEffect(() => {
        if (staticMeta !== null && active) {
            dispatch(allActions.filtersActions.saveMuts([]));
            dispatch(allActions.filtersActions.setApiFilteringParams());
        }
    }, [resetTrigger]); // eslint-disable-line react-hooks/exhaustive-deps
  
    return (
        <Fragment>
            <ButtonComponent
                name={buttonLabel}
                openFunc={_onOpen}
                disabled={disabled | mutsDisabled}
                strike={mutsDisabled}
                active={active}
            />
            <Dialog open={open} maxWidth={"xs"} onClose={_onClose}>
                <DialogTitle style={{ paddingBottom: 5 }}>
                    <Title>
                        {dialogTitle}
                    </Title>
                </DialogTitle>
                <DialogContent>
                    <Description>
                        {dialogDescription}
                    </Description>
                    <MutTable
                        muts={preSaveMuts}
                        mutMode={preSaveMutMode}
                        handleMutsOnChange={setPreSaveMuts}
                        handleMutModeOnChange={handleMutModeOnChange}
                        inputPlaceholder={inputPlaceholder}
                        pageLimit={pageLimit}
                        mutsNumMax={mutsNumMax}
                    />
                </DialogContent>
                <DialogActions>
                    <ResetButton
                        disableRipple={true}
                        onClick={_onReset}
                    >
                        <Label>
                            Reset
                        </Label>
                    </ResetButton>
                    <SaveButton
                        disableRipple={true}
                        onClick={_onSave}
                    >
                        <Label>
                            Save
                        </Label>
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default MutationSelect;