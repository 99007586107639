import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, CircularProgress, Divider, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ClearIcon from '@mui/icons-material/Clear';
import { CancelToken } from 'axios';

import allActions from '../../../../../redux';

const ItemText = styled(Typography)(({ theme }) => ({
    textAlign: "left",
    fontSize: 12,
    fontWeight: 700,
}));

const DetailText = styled(Typography)(({ theme }) => ({
    fontSize: 11,
    paddingRight: theme.spacing(2),
}));

const ActionPanel = styled(Typography)(({ theme }) => ({
    display: "inline-block",
    position: "absolute",
    textAlign: "right",
    paddingRight: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    margin: 0,
    fontSize: 12,
    fontWeight: 700,
    border: "transparent",
    color: theme.palette.text.title,
    padding: 0,
    "&:hover": {
        backgroundColor: "transparent",
        opacity: 0.8,
    },
}));

const StatusIcon = styled(Typography)(({ theme }) => ({
    textAlign: "right",
    fontSize: 16,
    height: 0,
}));

const StatusIconSpan = styled('span')({
    display: "inline-block",
    verticalAlign: "middle",
});

const LoadingIcon = styled(CircularProgress)(({ theme }) => ({
    marginRight: 3,
    color: "#484848",
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    display: "inline-block",
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:before': {
        display: 'none',
    },
    '&.MuiAccordion-root': {
        margin: 0
    }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme, status }) => ({
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    minHeight: 15,
    marginBottom: 0,
    backgroundColor: status === 'loading' ? '#ebebeb' : (status === 'error' ? '#f0d1d1' : (status === 'available' ? '#d5ebd6' : '#c1e4e8')),
    '&:hover': {
        backgroundColor: status === 'loading' ? '#e6e6e6' : (status === 'error' ? '#ebcccc' : (status === 'available' ? '#cfe3d0' : '#bee1e6')),
    },
    '.MuiCollapse-root': {
        visibility: 'visible'
    },
    '&.Mui-expanded': {
        minHeight: 15,
    },
    '& .MuiAccordionSummary-content': {
        margin: '12px 0',
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    padding: theme.spacing(2),
    textAlign: "left"
}));

function SubmissionTab(props) {
    const dispatch = useDispatch();
    const axiosRef = React.useRef(null);

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    const { submission, expandedId } = props;
    const { handleTabOnClick } = props;

    const { apiURL } = useSelector(state => state.staticReducer);
    const { retrieveTiparsOutputData } = useSelector(state => state.tiparsReducer);

    const [subsmissionStatus, setSubmissionStatus] = React.useState(null);

    const boldText = (string) => {
        return <span style={{ fontWeight: 700 }}>{string}</span>;
    };

    const _onClick = () => {
        handleTabOnClick(submission.id === expandedId ? null : submission.id);
    };

    const _onRemove = () => {
        dispatch(allActions.tiparsActions.removeActiveSubmission(submission.id));
        if (retrieveTiparsOutputData && retrieveTiparsOutputData.id === submission.id) {
            dispatch(allActions.tiparsActions.resetTiparsSubmissionOutput());
        }
    };

    const _onRetrieve = () => {
        dispatch(allActions.tiparsActions.setTiparsOutputSubmissionID(submission.id));
        if (retrieveTiparsOutputData === null || (retrieveTiparsOutputData && retrieveTiparsOutputData.id !== submission.id)) {
            dispatch(allActions.tiparsActions.loadTiparsSubmissionOutput(submission.id, newCancelToken, apiURL));
        }
    };

    const _onRefresh = () => {
        dispatch(allActions.tiparsActions.checkSubmission(submission.id, newCancelToken, apiURL));
        dispatch(allActions.tiparsActions.moveActiveSubmissionTop(submission.id));
    };

    useEffect(() => {
        setSubmissionStatus(getStatusClass(submission));
    }, [submission]);

    useEffect(() => {
        return (() => {
            // cancel POST request on unmount
            axiosRef.current && axiosRef.current.cancel();
        });
    }, []);

    const getStatusClass = (submission) => {
        if (submission.checkStatus === 1 || submission.checkStatus === 5) return 'loading';
        if (submission.checkStatus === 2 || submission.runStatus === 4 || submission.checkStatus === 6) return 'error';
        if (submission.runStatus === 3) return 'available';
        return null;
    };

    console.log(submission)

    return (
        <StyledAccordion
            square
            expanded={submission.id === expandedId}
            style={{ pointerEvents: submission.checkStatus === 1 ? 'none' : 'auto' }}
            onChange={submission.checkStatus === 1 || submission.checkStatus === 5 ? null : _onClick}
        >
            <StyledAccordionSummary id={submission.id} status={subsmissionStatus}>
                <Grid container spacing={0}>
                    <Grid item xs={11}>
                        <ItemText>
                            {submission.checkStatus === 5 ? 'Creating new submission...' : submission.checkStatus === 6 ? 'Unsuccessful creation of new submission' : `#${submission.id}`}
                        </ItemText>
                    </Grid>
                    <Grid item component={StatusIcon} xs={1}>
                        <StatusIconSpan>
                            {
                                submission.checkStatus === 1 || submission.checkStatus === 5 ?
                                    <LoadingIcon size={16} color='inherit' style={{ marginTop: 1 }} />
                                    :
                                    (
                                        submission.checkStatus === 2 || submission.runStatus === 4 || submission.checkStatus === 6 ?
                                            <ErrorOutlineIcon fontSize='small' />
                                            :
                                            (
                                                submission.runStatus === 3 ?
                                                    <CheckCircleOutlineIcon fontSize='small' />
                                                    :
                                                    <RemoveCircleOutlineIcon fontSize='small' />
                                            )
                                    )
                            }
                        </StatusIconSpan>
                    </Grid>
                </Grid>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <Grid container spacing={0}>
                    <Grid item xs={9}>
                        {
                            submission.id === expandedId ?
                                <DetailText>
                                    {boldText('submission ID:')} {submission.id}<br />
                                    {boldText('submitted at:')} {submission.submitTime}<br />
                                    {boldText('info:')} {submission.info}<br />
                                    {boldText('completed at:')} {submission.endTime}<br />
                                    {boldText('expire at:')} {submission.expireTime}<br />
                                    {boldText('runtime:')} {submission.runDuration ? `${submission.runDuration.timedelta.toFixed(2)} ${submission.runDuration.unit}` : 'n/a'}
                                </DetailText>
                                :
                                null
                        }
                    </Grid>
                    <Grid item xs={3}>
                        {
                            submission.id === expandedId ?
                                <Fragment>
                                    <StyledDivider orientation='vertical' />
                                    <ActionPanel>
                                        <ActionButton
                                            endIcon={<ReplayIcon />}
                                            disabled={submission.runStatus === 3 || submission.checkStatus === 6}
                                            onClick={_onRefresh}
                                        >
                                            REFRESH
                                        </ActionButton>
                                        <ActionButton
                                            endIcon={<SystemUpdateAltIcon />}
                                            disabled={submission.runStatus !== 3} // when submission has been processed with valid results
                                            onClick={_onRetrieve}
                                        >
                                            RETRIEVE
                                        </ActionButton>
                                        <ActionButton
                                            endIcon={<ClearIcon />}
                                            onClick={_onRemove}
                                        >
                                            REMOVE
                                        </ActionButton>
                                    </ActionPanel>
                                </Fragment>
                                :
                                null
                        }
                    </Grid>
                </Grid>
            </StyledAccordionDetails>
        </StyledAccordion>
    )
};

export default SubmissionTab;