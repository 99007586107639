import "../../../App.css";
import React, { Component } from "react";
import explore from "../../../images/Explore.png";
import placement from "../../../images/Placement.png";
import search from "../../../images/Search.png";
import Grid from "@mui/material/Grid";
import feature1 from "../../../images/Group46.png";
import feature2 from "../../../images/Group47.png";
import feature3 from "../../../images/Group45.png";
import feature4 from "../../../images/Group48.png";
import feature5 from "../../../images/Group42.png";

class LandingFeatures extends Component {
  render() {
    return (
      <div className="featureContainer">
        <div className="featureTitle">
          <div className="featureTitleText">
            <h2>What is EiGENO?</h2>
            <p>
              An online platform for tracking the genomic epidemiology of
              emerging pathogens. Our objective is to provide an interactive
              interface that enables rapid integration of large genomic and
              epidemiological data, in order to facilitate near real-time
              tracking of pathogen evolution and spread in outbreak scenarios.
            </p>
          </div>
        </div>
        {/* --------------first page ------------------ */}
        <div
          className="featurePage"
          style={{
            backgroundImage: `url(${explore})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid container>
            <Grid item sm xs></Grid>
            <Grid item sm={8} xs={12}>
              <div className="featurePageContent">
                <div className="titleContainer" >
                  <h2>
                    <span
                      style={{
                        color: "#ED6A5A",
                        fontSize: "4rem",
                      }}
                    >
                      1.
                    </span>
                    &nbsp; EXPLORE
                  </h2>
                </div>
                <div className="feature1">
                  <img alt="Phylogenetics" src={feature1}></img>
                  <div className="featureText">
                    <h1>Ultra-Large Phylogenetic Trees</h1>
                    <p>
                      Our highly-scalable hierarchical visualisation framework
                      is designed to handle phylogenetic trees with up to
                      millions of sequences, while providing an interactive
                      interface for navigation through the phylogeny with ease.
                    </p>
                  </div>
                </div>
                <div className="feature2">
                  <img alt="MetadataSummaries" src={feature2}></img>
                  <div className="featureText">
                    <h1>Epidemiological Data</h1>
                    <p>
                      With the multi-panel information dashboard, you have
                      convenient access to all relevant phylogenetic and
                      epidemiological data associated with the phylogenetic tree
                      on display, allowing rapid visual assessment of viral
                      movements between geographic locations as well as any
                      changes in the demographics of a ffected population.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item sm xs></Grid>
          </Grid>
        </div>
        {/* --------------second page ------------------ */}
        <div
          className="second-page-bkg"
          style={{
            backgroundColor: "#D1E4E4",
          }}
        >
          <div
            className="featurePage"
            style={{
              backgroundImage: `url(${search})`,
              backgroundRepeat: "no-repeat",
              marginTop: "0",
            }}
          >
            <Grid container>
              <Grid item sm xs></Grid>
              <Grid item sm={8} xs={12}>
                <div className="featurePageContent">
                  <div className="titleContainer">
                    <h2>
                      <span
                        style={{
                          color: "#ED6A5A",
                          fontSize: "4rem",
                        }}
                      >
                        2.
                      </span>
                      &nbsp; SEARCH
                    </h2>
                  </div>
                  <div className="feature1">
                    <img alt="Phylogenetics" src={feature3}></img>
                    <div className="featureText">
                      <h1>Genomes Lookup</h1>
                      <p>
                        Locating your own genome sample in a tree with millions
                        of sequences is like finding a needle in a hay stack...
                        With our interface, you can look up specific samples by
                        accessions/IDs or keywords within seconds.
                      </p>
                    </div>
                  </div>
                  <div className="feature2">
                    <img alt="MetadataSummaries" src={feature4}></img>
                    <div className="featureText">
                      <h1>Filtering by Epidemiological Data</h1>
                      <p>
                        Finding lineages and clades in the phylogeny with
                        specific epidemiological traits is straightforward using
                        the highly customisable data-filtering panel. Currently
                        searchable attributes include sampling location,
                        collection date, patient age and gender, and amino-acid
                        mutations.
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm xs></Grid>
            </Grid>
          </div>
        </div>
        {/* --------------third page ------------------ */}
        <div
          className="featurePage"
          style={{
            backgroundImage: `url(${placement})`,
            backgroundRepeat: "no-repeat",
            marginTop: "0",
          }}
        >
          <Grid container>
            <Grid item sm xs></Grid>
            <Grid item sm={8} xs={12}>
              <div className="featurePageContent">
                <div className="titleContainer">
                  <h2>
                    <span
                      style={{
                        color: "#ED6A5A",
                        fontSize: "4rem",
                      }}
                    >
                      3.
                    </span>
                    &nbsp;PLACEMENT
                  </h2>
                </div>
                <div className="feature1">
                  <img alt="Phylogenetics" src={feature5}></img>
                  <div className="featureText">
                    <h1>TIPars</h1>
                    <p>
                      TIPars is an efficient and accurate ancestral lineage
                      annotation method for large phylogenies using F1-score
                      [ref]. Using our integrated TIPars service, you can upload
                      your own genomes samples and place them in the reference
                      phylogeny with high accuracy within minutes.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item sm xs></Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default LandingFeatures;
