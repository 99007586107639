import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import html2canvas from 'html2canvas';

// Define styled components
const SnackBarContent = styled('div')(({ theme }) => ({
    fontSize: 13,
    fontWeight: 700,
    background: '#fff',
    minWidth: 0,
    color: theme.palette.text.main.normal,
    padding: '0px 5px',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.title,
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    size: 'small',
    disableRipple: true,
}));  

function CopiedToClipboard(props) {
    const [copied, setCopied] = React.useState(false);

    const _onCameraClick = () => {
        setCopied(true);
        html2canvas(document.body).then(function(canvas) {
          canvas.toBlob(function(blob) {
            const link = document.createElement('a');
            link.download = 'eigeno.screenshot.png';
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link); // add link to body
            link.click(); // simulate clikc
      
            document.body.removeChild(link); // remove link from body
            // revoke the object URL to free up memory
            URL.revokeObjectURL(link.href);
          }, 'image/png');  
        });
      };
    
    const _snackbarOnClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCopied(false);
    };

    return (
        <Fragment>
            <CustomIconButton onClick={_onCameraClick}>
                <PhotoCameraIcon
                    onCameraClick={_onCameraClick}
                    style={{ color: 'white' }}
                    fontSize='small'
                />
            </CustomIconButton>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={copied}
                autoHideDuration={3000}
                onClose={_snackbarOnClose}
                message={<SnackBarContent>Screenshot taken and downloaded</SnackBarContent>}
                action={
                    <CloseButton size="small" onClick={_snackbarOnClose}>
                        <CloseIcon fontSize="small" />
                    </CloseButton>
                }
                sx={{
                    '& .MuiSnackbarContent-root': {
                        minWidth: 0,
                        backgroundColor: '#fff'
                    },
                }}
            />
        </Fragment>
    );
}

export default CopiedToClipboard;
