import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { Grid, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Checkbox } from '@mui/material';
import { styled } from '@mui/system';

import allActions from '../../redux';
import DistChart from './distChart';

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const Description = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 13,
    fontWeight: 700,
    marginBottom: 10,
}));

const CustomButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    marginRight: 15,
    fontSize: 14,
    fontWeight: 700,
    padding: '3px 12px',
    color: theme.palette.text.title,
}));

const SaveButton = styled(CustomButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.lighter,
    "&:hover": {
        opacity: 0.8,
    },
}));

const ResetButton = styled(CustomButton)(({ theme }) => ({
    borderColor: "#fff",
    "&:hover": {
        backgroundColor: 'transparent',
    },
    padding: 10,
    paddingRight: 0,
}));

const Label = styled('span')(({ theme }) => ({
    fontSize: 13,
    display: "inline",
    textTransform: 'none',
}));

const InputLabelContainer = styled(Grid)({
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: 10,
});

const InputLabel = styled(Typography)(({ theme }) => ({
    fontSize: 13,
    fontWeight: 700,
    textAlign: 'right',
    paddingRight: 10,
    color: theme.palette.text.main.normal,
}));

const HelperText = styled(Typography)(({ theme, error }) => ({
    fontSize: 11,
    fontWeight: 500,
    height: 25,
    lineHeight: '12px',
    color: error ? theme.palette.text.error : theme.palette.text.main.normal,
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.background.main,
    },
    "& .MuiInputBase-input": {
      maxLength: 3,
      textAlign: "right",
      fontSize: 12,
      color: theme.palette.text.main.normal,  
    }
  }));

const ExcludeOption = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.main.normal,
    display: 'inline',
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    height: 0,
    width: 0,
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 10,
    color: theme.palette.background.main,
    '&.Mui-checked': {
        color: theme.palette.background.main,
    },
}));

const rangeFinder = (dist, age) => {
    for (let i = 0; i < dist.length; i++) {
        if (dist[i].label === age) {
            return i;
        }
    }
};

function AgeSelect(props) {
    const dispatch = useDispatch();

    const { active, disabled, resetTrigger, buttonLabel, dialogTitle, dialogDescription } = props;
    const { ButtonComponent } = props;

    const staticMeta = useSelector(state => state.staticReducer.meta);
    const { minAge, maxAge, ageUnknownExcluded } = useSelector(state => state.filtersReducer.age);

    const re = /^[0-9\b]+$/;

    const [errorMessage, setErrorMessage] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const _onOpen = () => {
        setPreSaveMin(minAge);
        setPreSaveMax(maxAge);
        setPreSaveUnknownExcluded(ageUnknownExcluded);
        setOpen(true);
    };
    const _onClose = () => {
        setOpen(false);
    };

    const [preSaveMin, setPreSaveMin] = React.useState();
    const _minOnChange = (event) => {
        const input = event.target.value;
        if (input === '') {
            setPreSaveMin('');
        } else if (re.test(input)) {
            setPreSaveMin(parseInt(input));
        }
    };

    const [preSaveMax, setPreSaveMax] = React.useState();
    const _maxOnChange = (event) => {
        const input = event.target.value;
        if (input === '') {
            setPreSaveMax('');
        } else if (re.test(input)) {
            setPreSaveMax(parseInt(input));
        }
    };

    const [preSaveUnknownExcluded, setPreSaveUnknownExcluded] = React.useState();
    const _onUnknownExcludeChange = () => {
        setPreSaveUnknownExcluded(!preSaveUnknownExcluded);
    };

    const _onReset = () => {
        setPreSaveMin(staticMeta.dists.age.min);
        setPreSaveMax(staticMeta.dists.age.max);
        setPreSaveUnknownExcluded(false);

        dispatch(allActions.filtersActions.saveAge(staticMeta.dists.age.min, staticMeta.dists.age.max, false, false));
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };

    const _onSave = () => {
        const filterActive = !(preSaveMin === staticMeta.dists.age.min
            && preSaveMax === staticMeta.dists.age.max
            && !preSaveUnknownExcluded);
        dispatch(allActions.filtersActions.saveAge(preSaveMin, preSaveMax, preSaveUnknownExcluded, filterActive));
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };

    // validate on update of preSaveMin and preSaveMax
    useEffect(() => {
        if (staticMeta !== null) {
            const preSaveMinInt = parseInt(preSaveMin),
                preSaveMaxInt = parseInt(preSaveMax);
            if (Number.isNaN(preSaveMinInt)) {
                // check if preSaveMin is a valid number
                setErrorMessage('please enter a valid minimum age');
            } else if (Number.isNaN(preSaveMaxInt)) {
                // check if preSaveMax is a valid number
                setErrorMessage('please enter a valid maximum age');
            } else if (preSaveMinInt < staticMeta.dists.age.min) {
                // check if preSaveMin is less than global minimum patient age
                setErrorMessage('minimum age cannot be less than the smallest patient age');
            } else if (preSaveMaxInt > staticMeta.dists.age.max) {
                // check if preSaveMax is greater than global maximum patient age
                setErrorMessage('maximum age cannot be greater than the largest patient age');
            } else if (preSaveMinInt > preSaveMaxInt) {
                // check if preSaveMin is greater than preSaveMax
                setErrorMessage('minimum age cannot be greater than maximum age');
            } else {
                setErrorMessage('');
            }
        }
    }, [preSaveMin, preSaveMax, staticMeta]);

    useEffect(() => {
        if (staticMeta !== null && active) {
            dispatch(allActions.filtersActions.saveAge(staticMeta.dists.age.min, staticMeta.dists.age.max, false, false));
            dispatch(allActions.filtersActions.setApiFilteringParams());
        }
    }, [resetTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <ButtonComponent
                name={buttonLabel}
                openFunc={_onOpen}
                disabled={disabled}
                active={active}
            />
            {
                staticMeta == null ?
                null
                :
                <Dialog open={open} fullWidth maxWidth={"xs"} onClose={_onClose}>
                    <DialogTitle style={{ paddingBottom: 5 }}>
                        <Title>
                            {dialogTitle}
                        </Title>
                    </DialogTitle>
                    <DialogContent>
                        <Description id='description'>
                            {dialogDescription}
                        </Description>
                        <ExcludeOption>
                            Exclude sequences with unknown patient age:
                        </ExcludeOption>
                        <CustomCheckbox
                            color='primary'
                            size='small'
                            disableRipple={true}
                            onMouseDown={_onUnknownExcludeChange}
                            checked={preSaveUnknownExcluded}
                        />
                        <DistChart
                            dist={staticMeta.dists.age.counts}
                            range={[rangeFinder(staticMeta.dists.age.counts, preSaveMin), rangeFinder(staticMeta.dists.age.counts, preSaveMax)]}
                            graphHeight={150}
                        />
                        <HelperText error={errorMessage.length}>
                            <span style={{ fontWeight: 700 }}>HELPER TEXT: </span>
                            {errorMessage.length ? errorMessage : 'n/a'}
                        </HelperText>
                        <Grid container spacing={0} style={{ paddingLeft: 45 }}>
                            <InputLabelContainer item xs={4}>
                                <InputLabel>
                                    Minimum:
                                </InputLabel>
                            </InputLabelContainer>
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <CustomTextField
                                    value={preSaveMin}
                                    onChange={_minOnChange}
                                    variant='standard'
                                />
                            </Grid>
                            <InputLabelContainer item xs={4}>
                                <InputLabel>
                                    Maximum:
                                </InputLabel>
                            </InputLabelContainer>
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <CustomTextField
                                    value={preSaveMax}
                                    onChange={_maxOnChange}
                                    variant='standard'
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ResetButton
                            disableRipple={true}
                            onClick={_onReset}
                        >
                            <Label>
                                Reset
                            </Label>
                        </ResetButton>
                        <SaveButton
                            disabled={errorMessage.length > 0}
                            disableRipple={true}
                            onClick={_onSave}
                        >
                            <Label>
                                Save
                            </Label>
                        </SaveButton>
                    </DialogActions>
                </Dialog>
            }
        </Fragment>
    );
}

export default AgeSelect