import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import PublishIcon from '@mui/icons-material/Publish';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { CancelToken } from 'axios';

import { seqPlaceholder } from './seqPlaceholder';
import helpers from '../../../../helpers';
import allActions from '../../../../redux';
import { InputValidate, inputStatusCode } from './inputValidation';
import sampleData from '../../tipars/sample-data/subid001_id002.json'

const StyledTextField = styled(TextField)(({ theme, focused }) => ({
    marginTop: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    '& .MuiInputBase-input': {
        fontSize: 12,
        fontFamily: "Courier",
        lineHeight: 1.2,
        color: theme.palette.text.main.normal,
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '2px 2px 0px 0px',
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.background.main,
        },
    },  
}));

const ButtonGroupStyled = styled(ButtonGroup)({
    margin: 0,
    marginTop: -1,
    borderRadius: 3,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
});

const UploadButton = styled(Button)(({ theme }) => ({
    display: 'inline-flex',
    color: theme.palette.background.main,
    height: 40,
    borderRadius: 2,
    fontSize: 12,
    borderTopLeftRadius: 0,
    width: 150,
    borderRightColor: `${theme.palette.background.main} !important`,
    '&:hover': {
        backgroundColor: helpers.hexToRgb(theme.palette.background.main, 0.1),
    },
}));

const SubmitButton = styled(Button)(({ theme, submissionsMaxed, status1, status2 }) => ({
    display: "inline-flex",
    width: "100%",
    height: 40,
    borderRadius: 2,
    fontSize: 12,
    borderTopRightRadius: 0,
    backgroundColor: submissionsMaxed ? null : (status1 ? helpers.hexToRgb('#76a679', 0.8) : (status2 ? null : helpers.hexToRgb('#bf7171', 0.8))),
    '&:hover': {
        backgroundColor: submissionsMaxed ? null : (status1 ? '#76a679' : (status2 ? null : '#bf7171')),
    },
}));

const InputStatus = styled('span')({
    textTransform: "none",
    textAlign: "left",
    lineHeight: "13px",
    width: "90%",
    fontSize: 12,
    paddingRight: 12,
});

function InputBox(props) {
    const dispatch = useDispatch();
    const axiosRef = React.useRef(null);

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    const { fileSubmitted, useExample, maxSubmissionsNum } = props;
    const { handleUploadDialogOpen, setFileUploaded, handleFileSubmit } = props;

    const { apiURL } = useSelector(state => state.staticReducer);
    const { activeSubmissions, submitTiparsTextLoading } = useSelector(state => state.tiparsReducer);

    const textfieldRef = React.useRef(null);

    const [focused, setFocused] = React.useState(false);
    const _onFocus = () => {
        setFocused(true);
    };
    const _onBlur = () => {
        setFocused(false);
    };

    const [inputValue, setInputValue] = React.useState('');
    const [inputStatus, setInputStatus] = React.useState(1);
    const _onChange = (event) => {
        setInputValue(event.target.value);
        setInputStatus(fileSubmitted ? 0 : InputValidate(event.target.value));
    };
    const _onSubmit = () => {
        if (fileSubmitted) { // submitting uploaded file
            dispatch(allActions.tiparsActions.submitTiparsFile(fileSubmitted, newCancelToken, apiURL));
            setInputValue('');
            setInputStatus(1);
            setFileUploaded(null);
        } else if (inputValue.startsWith('>')) { // submitting a sequence as a string
            dispatch(allActions.tiparsActions.submitTiparsText(inputValue, newCancelToken, apiURL));
            setInputValue('');
            setInputStatus(1);
        } else if (inputValue.startsWith('#')) { // submitting a submission ID
            const submissionID = inputValue.trim().slice(1);
            if (activeSubmissions.some(submission => submission.id === submissionID)) {
                // check if provided submission ID already exists as an active submission
                // if yes then move to top
                dispatch(allActions.tiparsActions.moveActiveSubmissionTop(submissionID));
            } else {
                dispatch(allActions.tiparsActions.checkSubmission(submissionID, newCancelToken, apiURL));
            }
        }
        // reset file submission
        handleFileSubmit(true);
    };

    useEffect(() => {
        if (useExample !== null) {
            setInputValue(sampleData.example_seq);
            setInputStatus(0);
        }
    }, [useExample]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return (() => {
            // cancel POST request on unmount
            axiosRef.current && axiosRef.current.cancel();
        });
    }, []);

    return (
        <Fragment>
            <StyledTextField
                multiline
                fullWidth
                rows={5} //set default height
                placeholder={seqPlaceholder}
                onFocus={_onFocus}
                onBlur={_onBlur}
                disabled={fileSubmitted || activeSubmissions.length === maxSubmissionsNum}
                onChange={_onChange}
                value={inputValue}
                inputRef={textfieldRef}
                focused={focused}
            />
            <ButtonGroupStyled fullWidth color="inherit">
                <UploadButton
                    onClick={handleUploadDialogOpen}
                    disabled={activeSubmissions.length === maxSubmissionsNum}
                >
                    <InsertDriveFileIcon fontSize='small' style={{ marginRight: 5 }} />&nbsp;UPLOAD
                </UploadButton>
                <SubmitButton
                    disabled={(!fileSubmitted && inputStatus !== 0) || activeSubmissions.length === maxSubmissionsNum || submitTiparsTextLoading}
                    onClick={_onSubmit}
                    submissionsMaxed={activeSubmissions.length === maxSubmissionsNum}
                    status1={fileSubmitted || inputStatus === 0}
                    status2={inputStatus === 1}
                >
                    <InputStatus
                        style={{
                            textAlign: !fileSubmitted && inputStatus === 0 && activeSubmissions.length !== maxSubmissionsNum ? "center" : "left",
                            color: !fileSubmitted && inputStatus > 1 ? '#484848' : null
                        }}
                    >
                        {fileSubmitted ? 'A query file has been submitted. To undo, click UPLOAD and then Reset.' : (activeSubmissions.length === maxSubmissionsNum ? `Maximum number of submissions (${maxSubmissionsNum}) reached, please remove from panel below.` : inputStatusCode[inputStatus])}
                    </InputStatus>
                    <PublishIcon fontSize='small' />
                </SubmitButton>
            </ButtonGroupStyled>
        </Fragment>
    );
};

export default InputBox;