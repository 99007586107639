import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/system';

const Root = styled(Box)(({ theme, xOffset }) => ({
    position: 'absolute',
    bottom: 0,
    height: 45,
    right: xOffset ? xOffset : 0
}));

const Label = styled('span')(({ theme, darkModeActive }) => ({
    position: 'absolute',
    top: -2,
    right: 20.5,
    color: darkModeActive ? '#D5D5D5' : theme.palette.text.title,
    fontWeight: 900,
    fontSize: 14
}));

const UpButton = styled(IconButton)(({ theme, darkModeActive }) => ({
    position: 'absolute',
    top: -30,
    right: 10,
    color: darkModeActive ? '#fff' : theme.palette.background.main
}));

const DownButton = styled(IconButton)(({ theme, darkModeActive }) => ({
    position: 'absolute',
    top: 10,
    right: 10,
    color: darkModeActive ? '#fff' : theme.palette.background.main,
    '&:hover': {
        background: 'transparent'
    }
}));

function Stretcher(props) {
    const { label, xOffset, handleOnChange, darkModeActive } = props;
    const intervalRef = useRef(null);

    const [upButtonDown, setUpButtonDown] = React.useState(false);
    const [downButtonDown, setDownButtonDown] = React.useState(false);

    const _startInterval = (event, direction) => {
        handleOnChange(event, direction);
        intervalRef.current = setInterval(() => {
            handleOnChange(event, direction);
        }, 50); // Adjust the interval time as needed
    };

    const _stopInterval = () => {
        clearInterval(intervalRef.current);
        setUpButtonDown(false);
        setDownButtonDown(false);
    };

    const _upOnMouseDown = (event) => {
        setUpButtonDown(true);
        _startInterval(event, 1);
    };

    const _downOnMouseDown = (event) => {
        setDownButtonDown(true);
        _startInterval(event, -1);
    };

    return (
        <Root xOffset={xOffset}>
            <Label darkModeActive={darkModeActive}>{label}</Label>
            <UpButton
                size="small"
                disableRipple={true}
                darkModeActive={darkModeActive}
                onMouseDown={_upOnMouseDown}
                onMouseUp={_stopInterval}
                onMouseLeave={_stopInterval} // To stop the interval if the mouse leaves the button
            >
                <ArrowCircleUpIcon fontSize="small" style={{ opacity: upButtonDown ? 0.7 : 1 }} />
            </UpButton>
            <DownButton
                size="small"
                disableRipple={true}
                darkModeActive={darkModeActive}
                onMouseDown={_downOnMouseDown}
                onMouseUp={_stopInterval}
                onMouseLeave={_stopInterval} // To stop the interval if the mouse leaves the button
            >
                <ArrowCircleDownIcon fontSize="small" style={{ opacity: downButtonDown ? 0.7 : 1 }} />
            </DownButton>
        </Root>
    );
}

export default Stretcher;