import React from "react";
import HeaderWhite from "../home/HeaderWhite";
import { Grid, Divider } from "@mui/material";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Tutorial = () => {
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12} style={{ marginBottom: 50 }}>
            <div className="external-pages">
              <h2 style={{ marginBottom: 10 }}>TUTORIAL</h2>
              <h1>
                How does E<span>i</span>GENO work?
              </h1>
              <div className="text-button-container">
                <p>
                  Here you will find a visual tour of features currently
                  available on EiGENO and some example use cases. Can’t find
                  what you are looking for? Head over to <a href="/faq">FAQ</a>{" "}
                  to see if someone else has already asked about it.
                </p>
              </div>
              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
            </div>
            <div className="external-pages">
              <Link to="/TreeVisualisation" className="tutorial-container">
                <div
                  className="tutorial-box"
                  style={{
                    marginTop: 0,
                  }}
                >
                  <h5><span>LEARN MORE. </span>Tree visualisation</h5>
                  <p>
                    Visualising and exploring ultra-large phylogenetic trees with up to millions of sequences is difficult. Here you will learn how our scalable hierarchical design attempts to tackle this issue.
                  </p>
                </div>
              </Link>
              <Link to="/TreePartitioning" className="tutorial-container">
                <div
                  className="tutorial-box"
                >
                  <h5><span>LEARN MORE. </span>Tree partitioning (for SARS-CoV-2 only)</h5>
                  <p>
                    One of the key strategies for handling ultra-large phylogenetic trees is to divide them into smaller, more manageable sub-trees (also known as partitions). Here you will learn how our tree partitioning algorithm works, with the SARS-CoV-2[7M] dataset as an example.
                  </p>
                </div>
              </Link>
              <Link to={`/usecase1`} className="tutorial-container">
                <div className="tutorial-box">
                  <h5>
                    <span>USE CASE 1.</span> Locating genome samples collected
                    from a specific country during a specific time-window
                  </h5>
                  <p>
                    In this example, you will learn how to locate all SARS-CoV-2
                    sequences collected in the United Kingdom from January to
                    March 2020. Although it is straightforward to add additional
                    filters for attributes like patient gender or variant, we
                    will keep things simple for now and focus only on country
                    and collection date.
                  </p>
                </div>
              </Link>
              <Link to={`/usecase2`} className="tutorial-container">
                <div className="tutorial-box">
                  <h5>
                    <span>USE CASE 2.</span> Locating genome samples collected
                    during a specific outbreak
                  </h5>
                  <p>
                    In this example, you will learn how to locate SARS-CoV-2
                    sequences from the third-wave of COVID-19 in Hong Kong,
                    which occurred between July and October 2020. It was found
                    in one study by{" "}
                    <a
                      href="https://www.thelancet.com/journals/lanwpc/article/PIIS2666-6065(21)00039-0/fulltext"
                      target="blank"
                    >
                      Chan et al. (2021)
                    </a>{" "}
                    that the wave was primarily driven by the B.1.1.63 lineage,
                    accounting for 96.6% of the genomes collected at the time.
                  </p>
                </div>
              </Link>
              <Link to={`/usecase3`} className="tutorial-container">
                <div className="tutorial-box">
                  <h5>
                    <span>USE CASE 3.</span> Submitting a TIPars job
                  </h5>
                  <p>
                    TIPars is an algorithm/tool that has been developed for fast
                    and memory-efficient sequence insertion in ultra-large
                    phylogenetic trees. In this example, you will learn how to
                    submit your own sequences to be processed by TIPars on our
                    server. For more technical details on how TIPars works,
                    please refer to{" "}
                    <a
                      href="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1011871"
                      target="blank"
                    >
                      Robust expansion of phylogeny for fast-growing genome
                      sequence data
                    </a>{" "}
                    .
                  </p>
                </div>
              </Link>
              <Link to={`/usecase4`} className="tutorial-container">
                <div className="tutorial-box">
                  <h5>
                    <span>USE CASE 4.</span> Interpreting TIPars results
                  </h5>
                  <p>
                    In this example, you will learn how to interpret the results
                    of a TIPars submission. For more technical details on how
                    TIPars works, please refer to{" "}
                    <a
                      href="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1011871"
                      target="blank"
                    >
                      Robust expansion of phylogeny for fast-growing genome
                      sequence data
                    </a>{" "}
                    .
                  </p>
                </div>
              </Link>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Tutorial;
