import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { styled, keyframes } from '@mui/system';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import clsx from 'clsx';

import helpers from '../../../helpers';

const blinker = keyframes`
    50% {
        color: #ED6A5A;
    }
`;

const Root = styled(Paper)(({ theme, disabled }) => ({
    borderRadius: 2,
    backgroundColor: disabled ? helpers.hexToRgb(theme.palette.background.main, 0.7) : theme.palette.background.main,
    margin: 10,
    padding: 10,
    opacity: disabled ? 0.9 : 1,
    cursor: disabled ? 'auto' : 'pointer',
    '&:hover': {
        opacity: 0.9,
    },
}));

const Text = styled(Typography)({
    color: '#fff',
    fontWeight: 700,
    fontSize: 14,
});

const Icon = styled(AccountTreeIcon)({
    verticalAlign: 'bottom',
    marginLeft: 15,
});

const BlinkerAddOn = styled(Icon)({
    animation: `${blinker} 2000ms linear infinite`,
});

function GoToTree(props) {
    const { active, onClickHandler, label } = props;
    const { staticSubtreeGraph, staticSubtreeGraphLoading, filteredSubtreeGraphLoading } = useSelector(state => state.filtersReducer);

    return(
        <Fragment>
            <Root disabled={(staticSubtreeGraphLoading || filteredSubtreeGraphLoading || !active)} elevation={1} onClick={active ? onClickHandler : null}>
                <Text>
                    {label.toUpperCase()}{' '}
                    <Icon className={clsx(staticSubtreeGraph && BlinkerAddOn)} fontSize='small' />
                </Text>
            </Root>
        </Fragment>
    )
}

export default GoToTree;